import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useGetAgencyMutation } from "../../slices/agencyApiSlice";
import { setAgency } from "../../slices/agency";
import {
  useGetAssignedDocsMutation,
  useGetDocumentMutation,
} from "../../slices/documentsApiSlice";
import { setCategory, setName } from "../../slices/docsSlice";
import { setDocument } from "../../slices/documentSlice";
import {
  getForms,
  setFormsForUser,
  setSingleForm,
  setSingleUserForm,
} from "../../slices/formsSlice";
import { setEFandFilledEF } from "../../slices/entryForm";

import Loader from "../../components/Loader";

import Table from "../../utils/Table/Table";
import TableRow from "../../utils/Table/TableRow";
import TableCell from "../../utils/Table/TableCell";
import Dropdown from "../../utils/Dropdown";
import { setListIDS } from "../../slices/internalDependencySlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const { document } = useSelector((state) => state.document);
  const { forms, userForms } = useSelector((state) => state.forms);
  const { listIDS } = useSelector((state) => state.internalDependency);
  const { entryForm, filledEF } = useSelector((state) => state.entryForm);

  const [documents, setDocuments] = useState([]);
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);

  const [getAgency, { isLoading: loadingAgency }] = useGetAgencyMutation();
  const [getAssignedDocs, { isLoading: loadingDocs }] =
    useGetAssignedDocsMutation();
  const [getDocument, {}] = useGetDocumentMutation();
  const { pathname } = useLocation();

  // const getDocumentHandler = async (record) => {
  //   try {
  //     const res = await getDocument({ document: record }).unwrap();
  //     dispatch(
  //       getForms({
  //         userForms: res.userForms,
  //         forms: res.forms,
  //       })
  //     );
  //     dispatch(setDocument(record));
  //   } catch (err) {
  //     toast.error(err?.data?.message || err.error);
  //   }
  // };

  useEffect(() => {
    if (userInfo) {
      const fetchAgency = async () => {
        const agency = await getAgency({
          agencyid: userInfo.agency,
        }).unwrap();
        dispatch(setAgency(agency.agency));
      };

      const fetchDocuments = async () => {
        const docs = await getAssignedDocs({
          assignedDocs: userInfo.assignedDocs,
        }).unwrap();
        console.log(docs);
        setDocuments(docs);
      };

      fetchAgency();
      fetchDocuments();
    }
  }, [userInfo, getAgency, getAssignedDocs, dispatch]);

  // useEffect(() => {
  //   dispatch(resetForms());
  //   dispatch(resetDocument());
  // }, []);

  console.log(isReadyToNavigate);

  useEffect(() => {
    console.log(entryForm, filledEF);
    if (document && entryForm && filledEF && forms && userForms) {
      setIsReadyToNavigate(true);
    }
  }, [document, entryForm, filledEF, forms, userForms]);

  useEffect(() => {
    console.log(document);
    if (
      document &&
      document.internalDependency &&
      Object.keys(document.internalDependency).length > 0
    ) {
      navigate("/InternalDep");
    }
  }, [document]);

  // console.log(document, forms, userForms);

  useEffect(() => {
    if (isReadyToNavigate && document) {
      if (document.name === "NEFT") {
        navigate(`/Forms/NEFT`);
      } else if (document.name === "RTGS") {
        navigate(`/Forms/RTGS`);
      } else if (document.name === "Fund_Transfer") {
        navigate(`/Forms/UBI_Fund_Transfer`);
      } else {
        navigate(`/Forms/EF/${document.category}$${document.name}_EF`);
      }
    } else {
    }
  }, [isReadyToNavigate, document]);

  // console.log(userForms);

  return (
    <div className="psm flex gap-2">
      <div className="wh-100">
        {documents.length === 0 ? (
          <Loader />
        ) : (
          <div className="p-4 morphous bg-color">
            {/* <h2 className="font-mono pb-2">Search Document</h2>
            <div className="flex gap-4">
              <Input
                type="text"
                placeholder="Enter Doc Name"
                value={searchKeyword}
                change={(e) => setSearchKeyword(e.target.value)}
              />
              <Dropdown
                options={categories}
                name="Category"
                value={searchCategory}
                target="option.category"
                click={(value) => {
                  setSearchCategory(value.category);
                }}
                style={
                  searchCategory !== ""
                    ? { backgroundColor: "#035afc", color: "white" }
                    : null
                }
              />
            </div> */}
            <h2 className="font-mono pb-2">Select Document</h2>
            <Table>
              <TableRow isHeader={true}>
                <TableCell isHeader={true}>S.No</TableCell>
                <TableCell isHeader={true}>Name</TableCell>
                <TableCell isHeader={true}>Category</TableCell>
                <TableCell isHeader={true}>Select</TableCell>
              </TableRow>
              {documents?.map((doc, sno) => {
                return (
                  <TableRow
                    key={sno}
                    className={(sno + 1) % 2 === 0 ? "bg-gray-100" : null}
                  >
                    <TableCell>{sno + 1}</TableCell>
                    <TableCell>{doc.name}</TableCell>
                    <TableCell>{doc.category}</TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <button
                        className="bg-cyan-950 text-light-500 h-[48px] px-2 text-center min-w-[100px]"
                        onClick={() => {
                          dispatch(setDocument(doc));
                          dispatch(
                            setListIDS({
                              document: `${doc.category}$${doc.name}`,
                              entryForm: `${doc.category}$${doc.name}_EF`,
                              category: doc.category,
                              name: doc.name,
                            })
                          );
                          if (doc.internalDependency) {
                            dispatch(setFormsForUser(doc));
                            dispatch(setCategory(doc.category));
                            dispatch(setName(doc.name));
                            dispatch(
                              setEFandFilledEF({
                                entryForm: doc.entryForm,
                                filledEF: doc.filledEF,
                              })
                            );
                          } else {
                            console.log(doc);
                            dispatch(setFormsForUser(doc));
                            dispatch(setCategory(doc.category));
                            dispatch(setName(doc.name));
                            dispatch(
                              setEFandFilledEF({
                                entryForm: doc.entryForm,
                                filledEF: doc.filledEF,
                              })
                            );
                            dispatch(setSingleUserForm({ name: "Submit" }));
                            // navigate(
                            //   `/Forms/EF/${doc.category}$${doc.name}_EF`
                            // );
                          }
                        }}
                      >
                        Select
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
