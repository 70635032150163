import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetInternalDependencyMutation } from "../../slices/documentsApiSlice";
import { setInternalDependency } from "../../slices/internalDependencySlice";
// import { setForms } from "../../slices/formsSlice";
import { setCategory, setName } from "../../slices/docsSlice";
import { setEFandFilledEF } from "../../slices/entryForm";

const Forms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userForms, forms } = useSelector((state) => state.forms);

  const { pathname } = useLocation();
  let splitLoc = pathname.split("/");

  let activeStyle = "bg-cyan-950 text-light-500";
  return (
    <div className="psm flex flex-col gap-4">
      <ul className="flex justify-center border-b border-cyan-950 mb-6">
        {userForms?.map((userForm) => {
          const lastIndex = userForm.name.split("_");
          if (userForm.role !== "Manager") {
            return (
              <li className="mr-1">
                <a
                  className={`${
                    splitLoc[splitLoc.length - 1] === userForm.name
                      ? activeStyle
                      : ""
                  } inline-block py-2 px-4 font-semibold cursor-pointer`}
                  onClick={() => {
                    if (lastIndex[lastIndex.length - 1] === "EF") {
                      let splitname = userForm.name.split("$");
                      let docname = splitname[1].slice(0, -3);
                      let newName = lastIndex.slice(0, -1);
                      newName = newName.join("_");
                      console.log(newName);
                      console.log(forms);
                      dispatch(
                        setEFandFilledEF({
                          entryForm: forms[newName],
                          filledEF: forms[userForm.name],
                        })
                      );
                      dispatch(setCategory(splitname[0]));
                      dispatch(setName(docname));
                      setTimeout(() => {
                        navigate(`/Forms/EF/${userForm.name}`);
                      }, 10);
                    } else {
                      navigate(`/Forms/${userForm.name}`);
                    }
                  }}
                >
                  {userForm.name
                    .substring(userForm.name.indexOf("$") + 1)
                    .replaceAll("_", " ")
                    .replaceAll(" EF", "")}
                </a>
              </li>
            );
          }
        })}
      </ul>
      <div className="psm">
        <Outlet />
      </div>
    </div>
  );
};

export default Forms;
