import React, { useState } from "react";
import Input from "../../../utils/Input";

const AccountValue = ({ R, obj, i, j, k }) => {
  const [value, setValue] = useState(R);
  return (
    <Input
      type="text"
      value={value}
      click={() => {
        let val = `Cibil[${i}].${obj}[${j}][${k}]`;
        navigator.clipboard.writeText(val);
      }}
      change={(e) => setValue(e.target.value)}
    />
  );
};

export default AccountValue;
