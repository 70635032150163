import React from "react";
import { setIndexPath } from "../../../../../../slices/docsSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddImage = ({ RRIP_P, SIPHF, SIP, baseURL }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div>
      <h2 className="pb-1">Add Image</h2>
      <button
        className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
        onClick={() => {
          dispatch(setIndexPath(RRIP_P.join("/")));
          if (SIPHF === "0_H" || SIPHF === "0_F") {
            navigate(
              `${baseURL}/${RRIP_P.join("/")}${
                SIP.length > 3 ? "/SP/HFP/TBP/TR/TC/PP/SI" : "/SP/HFP/PP/SI"
              }`
            );
          } else {
            navigate(
              `${baseURL}/${RRIP_P.join("/")}${
                SIP.length > 3 ? "/SP/TBP/TR/TC/PP/SI" : "/SP/PP/SI"
              }`
            );
          }
        }}
      >
        Image
      </button>
    </div>
  );
};

export default AddImage;
