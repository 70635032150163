import React, { useState } from "react";
import Input from "../../../utils/Input";

const AccountType = ({ C, obj, i, j, k, l }) => {
  const [account, setAccount] = useState(C);
  return (
    <Input
      type="text"
      value={account}
      click={() => {
        let val = `Cibil[${i}].${obj}[${j}][${k}][${l}]`;
        navigator.clipboard.writeText(val);
      }}
      change={(e) => setAccount(e.target.value)}
    />
  );
};

export default AccountType;
