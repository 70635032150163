import React, { useEffect, useState } from "react";
import Dropdown from "../../../../../utils/Dropdown";
import Input from "../../../../../utils/Input";
import Button from "../../../../../utils/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAlignment,
  addThematicBreak,
  addPageBreak,
  updateBorder,
  updateShading,
  updateSpacing,
  updateParaIndent,
  addTextToPara,
  setIndexPath,
  setParaNumbering,
} from "../../../../../slices/docsSlice";
import { returnHFEle } from "../../../../../slices/Docs/functions";
import { useGetNumberingQuery } from "../../../../../slices/numberingApiSlice";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { switchShadeType, returnPropEle } from "../CommFunctions";
import JsFunControls from "../Tools/jsFunControls";

import Alignment from "./components/alignment";
import Border from "./components/border";
import PageBreak from "./components/pageBreak";

import AddText from "./components/addText";
import AddImage from "./components/addImage";
import AddSymbol from "./components/addSymbol";
import AddCheckbox from "./components/addCheckbox";

const ParagraphProperties = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    docs,
    indexPath,
    SIP,
    RRIP_P,
    RRIP_TC,
    RRIP_TR,
    RRIP_TB,
    RRIP_S,
    RRIP_DOC,
    NIP_P,
    dev,
    minimal,
  } = useSelector((state) => state.docs);
  const { paraName } = useSelector((state) => state.element);
  // const { productInfo } = useSelector((state) => state.product);
  const { alignments, isTrueOrFalse, shadingTypes } = useSelector(
    (state) => state.options
  );
  const [alignment, setAlignment] = useState();
  const [thematicBreak, setThematicBreak] = useState();
  const [pageBreak, setPageBreak] = useState();
  const [border, setBorder] = useState();
  const [shadeColor, setShadeColor] = useState();
  const [shadeFill, setShadeFill] = useState();
  const [shadeType, setShadeType] = useState();
  const [spacingBefore, setSpacingBefore] = useState();
  const [spacingAfter, setSpacingAfter] = useState();
  const [spacingLine, setSpacingLine] = useState();
  const [leftIndent, setLeftIndent] = useState(); // Example default value
  const [rightIndent, setRightIndent] = useState();
  const [firstLineIndent, setFirstLineIndent] = useState();
  const [hangingIndent, setHangingIndent] = useState();
  const [reference, setReference] = useState();
  const [level, setLevel] = useState();

  const [paraStatus, setParaStatus] = useState(dev ? "jsfun" : "properties");

  const { data: numberingTypes, refetch, isLoading } = useGetNumberingQuery();

  useEffect(() => {
    setParaStatus(dev ? "jsfun" : "properties");
  }, [indexPath, dev]);

  const { pathname } = useLocation();
  let splitLoc = pathname.split("/");
  // const currentPath = splitLoc[3];
  if (splitLoc.length >= 4) {
    let length = splitLoc.length - 3;
    splitLoc.splice(3, length);
  }
  const baseURL = splitLoc.join("/");

  let SIPHF = SIP[2];

  // let dynName = "docs";
  // NIP_P.split("_").forEach((item, i) => {
  //   dynName =
  //     dynName +
  //     `['${item}'].${i === NIP_P.split("_").length - 1 ? "name" : "ele"}`;
  // });

  let dynEle = "docs";
  NIP_P.split("_").forEach((item, i) => {
    dynEle =
      dynEle + `['${item}'].${i === NIP_P.split("_").length - 1 ? "" : "ele"}`;
  });

  dynEle = dynEle.slice(0, -1);

  const docIndex = parseFloat(SIP[0].split("_")[0]);

  useEffect(() => {
    let para;
    let cell;
    let row;
    let table;
    let sec;
    let doc;
    if (SIPHF === "0_H" || SIPHF === "0_F") {
      // para = useSelector((state) => returnHFEle(state, RRIP_P, SIPHF));
      // cell = RRIP_TC
      //   ? useSelector((state) => returnHFEle(state, RRIP_TC, SIPHF))
      //   : undefined;
      // row = RRIP_TR
      //   ? useSelector((state) => returnHFEle(state, RRIP_TR, SIPHF))
      //   : undefined;
      // table = RRIP_TB
      //   ? useSelector((state) => returnHFEle(state, RRIP_TB, SIPHF))
      //   : undefined;
      // sec = useSelector((state) => returnHFEle(state, RRIP_S, SIPHF));
      doc = returnPropEle(docs, RRIP_DOC);
      console.log(para, doc);
    } else {
      para = returnPropEle(docs, RRIP_P);
      cell = RRIP_TC ? returnPropEle(docs, RRIP_TC) : undefined;
      row = RRIP_TR ? returnPropEle(docs, RRIP_TR) : undefined;
      table = RRIP_TB ? returnPropEle(docs, RRIP_TB) : undefined;
      doc = returnPropEle(docs, RRIP_DOC);
    }

    if (para) {
      if (para?.properties?.alignment) {
        setAlignment(para.properties.alignment);
      } else if (table !== undefined && table.properties.alignment) {
        // check table.properties.alignment
        setAlignment(table.properties.para.alignment);
      } else {
        setAlignment(doc.properties.para.alignment);
      }

      if (para?.properties?.thematicBreak) {
        setThematicBreak(para.properties.thematicBreak);
      } else {
        setThematicBreak();
      }
      if (para.properties.pageBreak) {
        setPageBreak(para.properties.pageBreak);
      } else {
        setPageBreak();
      }
      if (para.properties.border) {
        setBorder(para.properties.border);
      } else {
        setBorder();
      }
      if (para.properties.indent) {
        if (para.properties.indent.left) {
          setLeftIndent(para.properties.indent.left);
        } else {
          setLeftIndent();
        }
        if (para.properties.indent.right) {
          setRightIndent(para.properties.indent.right);
        } else {
          setRightIndent();
        }
        if (para.properties.indent.firstLine) {
          setFirstLineIndent(para.properties.indent.firstLine);
        } else {
          setFirstLineIndent();
        }
        if (para.properties.indent.hanging) {
          setHangingIndent(para.properties.indent.hanging);
        } else {
          setHangingIndent();
        }
      }

      if (para.properties.numbering?.reference) {
        setReference(para.properties.numbering?.reference);
      } else {
        setReference();
      }
      if (para.properties.numbering?.level !== undefined) {
        setLevel(para.properties.numbering?.level);
      } else {
        setLevel();
      }

      if (para.properties.shading) {
        setShadeColor(`#${para.properties.shading.color.toLowerCase()}`);
        setShadeFill(`#${para.properties.shading.fill.toLowerCase()}`);
        setShadeType(switchShadeType(para.properties.shading.type));
      }
      if (para.properties.spacing) {
        setSpacingBefore(para.properties.spacing.before);
        setSpacingAfter(para.properties.spacing.after);
        setSpacingLine(para.properties.spacing.line);
      } else if (table === undefined) {
        setSpacingBefore(doc.properties.para.spacing.before);
        setSpacingAfter(doc.properties.para.spacing.after);
      }
    }
  }, [docs, NIP_P]);

  let activeStyle = "bg-cyan-950 text-light-500";

  return (
    <section className="psm morphous bg-color">
      <div className="mb-2">
        <Outlet />
      </div>

      {paraStatus === "properties" ? (
        <>
          {/* Get Properties */}
          {/* <div className="flex gap-4 pb-4 bg-">
            <button className="bg-cyan-950 text-light-500 h-[40px] w-[100px]">
              Para Props
            </button>
            <button className="bg-cyan-950 text-light-500 h-[40px] w-[100px]">
              Text Props
            </button>
          </div> */}
          {/* Add Text or Image */}

          {/* background alignment and line break */}
          <h1 className="font-mono text-center pb-4">
            Paragraph Properties [{paraName}]
          </h1>
          <div className="flex gap-4 pb-4">
            <Alignment alignment={alignment} RRIP_P={RRIP_P} SIPHF={SIPHF} />
            <Border border={border} RRIP_P={RRIP_P} SIPHF={SIPHF} />
            <PageBreak pageBreak={pageBreak} RRIP_P={RRIP_P} SIPHF={SIPHF} />

            <AddText RRIP_P={RRIP_P} docIndex={docIndex} SIPHF={SIPHF} />
            <AddImage
              RRIP_P={RRIP_P}
              SIPHF={SIPHF}
              SIP={SIP}
              baseURL={baseURL}
            />
            <AddCheckbox RRIP_P={RRIP_P} SIPHF={SIPHF} />
          </div>
          <div>
            <AddSymbol RRIP_P={RRIP_P} SIPHF={SIPHF} />
          </div>
          {/* Numbering */}
          <div className="flex gap-4 pb-4">
            <div>
              <h2 className="pb-1">Select Reference</h2>
              <Dropdown
                height={40}
                options={numberingTypes}
                name="Reference"
                value={reference}
                target="option.reference"
                click={(value) => {
                  // setReference(value.reference);
                  dispatch(
                    setParaNumbering({
                      loc: "reference",
                      para: RRIP_P,
                      value: value.reference,
                    })
                  );
                }}
                style={
                  reference !== "" && reference !== undefined
                    ? { backgroundColor: "#035afc", color: "white" }
                    : null
                }
              />
            </div>
            <div>
              <h2 className="pb-1">Level 0 to 9</h2>
              <Dropdown
                width={150}
                height={40}
                name="level"
                options={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
                value={level}
                target="option"
                click={(value) => {
                  // setLevel(value);
                  dispatch(
                    setParaNumbering({
                      loc: "level",
                      para: RRIP_P,
                      value: value,
                    })
                  );
                }}
              />
            </div>
          </div>
          {/* Shading */}
          <div className={`${minimal ? "hidden" : "block"}`}>
            <div className="flex gap-4 pb-4">
              <div>
                <h2 className="pb-1">Para Color</h2>
                <Input
                  className="h-[40px] w-[100px] p-0 shadow-none"
                  value={shadeColor}
                  blur={(e) => {
                    dispatch(
                      updateShading({
                        loc: "color",
                        path: RRIP_P,
                        value: e.target.value,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    );
                  }}
                  change={(e) => setShadeColor(e.target.value)}
                  type="color"
                />
              </div>
              <div>
                <h2 className="pb-1">Text Color</h2>
                <div className="flex items-center gap-2">
                  <Input
                    className="h-[40px] w-[100px] p-0 shadow-none"
                    value={shadeFill}
                    blur={(e) => {
                      dispatch(
                        updateShading({
                          loc: "fill",
                          path: RRIP_P,
                          value: e.target.value,
                          layoutEle:
                            SIPHF === "0_H" || SIPHF === "0_F"
                              ? SIPHF
                              : undefined,
                        })
                      );
                    }}
                    change={(e) => setShadeFill(e.target.value)}
                    type="color"
                  />
                </div>
              </div>
              <div>
                <h2 className="pb-1">Shade Type</h2>
                <Dropdown
                  placeholder="Shade Type"
                  value={shadeType}
                  options={shadingTypes}
                  name="Shade Type"
                  target="option"
                  width={200}
                  height={40}
                  click={(value) => {
                    dispatch(
                      updateShading({
                        loc: "type",
                        path: RRIP_P,
                        value,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    );
                  }}
                />
              </div>

              <div>
                <h2 className="pb-1">Line Break</h2>
                <Button
                  name="LB"
                  value={thematicBreak}
                  click={() => {
                    dispatch(
                      addThematicBreak({
                        para: RRIP_P,
                        value: thematicBreak,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {/* Pagebreak border and spacing */}
          <div className={`${minimal ? "hidden" : "block"}`}>
            <div className="flex flex-wrap gap-4 pb-4">
              <div>
                <h2 className="pb-1">Spacing Before</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={spacingBefore}
                  blur={(e) =>
                    dispatch(
                      updateSpacing({
                        loc: "before",
                        para: RRIP_P,
                        doc: RRIP_DOC,
                        value: e.target.value,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    )
                  }
                  change={(e) => setSpacingBefore(e.target.value)}
                  type="text"
                />
              </div>
              <div>
                <h2 className="pb-1">Spacing After</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={spacingAfter}
                  blur={(e) =>
                    dispatch(
                      updateSpacing({
                        loc: "after",
                        para: RRIP_P,
                        doc: RRIP_DOC,
                        value: e.target.value,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    )
                  }
                  change={(e) => setSpacingAfter(e.target.value)}
                  type="text"
                />
              </div>
              <div>
                <h2 className="pb-1">Line</h2>
                <Input
                  className="h-[40px] w-[100px]"
                  value={spacingLine}
                  blur={(e) =>
                    dispatch(
                      updateSpacing({
                        loc: "line",
                        para: RRIP_P,
                        doc: RRIP_DOC,
                        value: e.target.value,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    )
                  }
                  change={(e) => setSpacingLine(e.target.value)}
                  type="text"
                />
              </div>
            </div>
          </div>
          {/* Indentation */}
          <div className={`${minimal ? "hidden" : "block"}`}>
            <div className="pb-4">
              <h1 className="pb-1">
                * Intentation won't work if left and firstLine are added
                together. *
              </h1>
              <div className="flex flex-wrap gap-4">
                <div>
                  <h2 className="pb-1">Indent Left</h2>
                  <Input
                    className="h-[40px] w-[150px]"
                    type="text"
                    value={leftIndent}
                    blur={(e) =>
                      dispatch(
                        updateParaIndent({
                          loc: "left",
                          value: e.target.value,
                          para: RRIP_P,
                          layoutEle:
                            SIPHF === "0_H" || SIPHF === "0_F"
                              ? SIPHF
                              : undefined,
                        })
                      )
                    }
                    change={(e) => setLeftIndent(e.target.value)}
                  />
                </div>
                <div>
                  <h2 className="pb-1">Indent Right</h2>
                  <Input
                    className="h-[40px] w-[150px]"
                    type="text"
                    value={rightIndent}
                    blur={(e) =>
                      dispatch(
                        updateParaIndent({
                          loc: "right",
                          value: e.target.value,
                          para: RRIP_P,
                          layoutEle:
                            SIPHF === "0_H" || SIPHF === "0_F"
                              ? SIPHF
                              : undefined,
                        })
                      )
                    }
                    change={(e) => setRightIndent(e.target.value)}
                  />
                </div>
                <div>
                  <h2 className="pb-1">Indent FirstLine</h2>
                  <Input
                    className="h-[40px] w-[150px]"
                    type="text"
                    value={firstLineIndent}
                    blur={(e) =>
                      dispatch(
                        updateParaIndent({
                          loc: "firstLine",
                          value: e.target.value,
                          para: RRIP_P,
                          layoutEle:
                            SIPHF === "0_H" || SIPHF === "0_F"
                              ? SIPHF
                              : undefined,
                        })
                      )
                    }
                    change={(e) => setFirstLineIndent(e.target.value)}
                  />
                </div>
                <div>
                  <h2 className="pb-1">Indent Hanging</h2>
                  <Input
                    className="h-[40px] w-[150px]"
                    type="text"
                    value={hangingIndent}
                    blur={(e) =>
                      dispatch(
                        updateParaIndent({
                          loc: "hanging",
                          value: e.target.value,
                          para: RRIP_P,
                          layoutEle:
                            SIPHF === "0_H" || SIPHF === "0_F"
                              ? SIPHF
                              : undefined,
                        })
                      )
                    }
                    change={(e) => setHangingIndent(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : paraStatus === "jsfun" ? (
        <div className="flex flex-col pb-[80px]">
          <JsFunControls path={RRIP_P} dynEle={dynEle} />
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};

export default ParagraphProperties;
