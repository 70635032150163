import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import TextArea from "../../../../utils/TextArea";
import { useDispatch } from "react-redux";
import {
  removeUserInput,
  updateDefaultValue,
} from "../../../../slices/entryForm";

const EFTextArea = ({ opInput, operator }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-wrap items-start gap-4 bg-blue-200 p-2 rounded relative">
      <div
        className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
        onClick={() =>
          dispatch(
            removeUserInput({
              name: opInput.placeholder,
              operator: operator,
            })
          )
        }
      >
        <IoCloseSharp />
      </div>
      <div>
        <h2 className="pb-1">
          {opInput.placeholder} ({opInput.element})
        </h2>
        <TextArea
          type="text"
          placeholder="default value"
          value={opInput.defaultValue}
          change={(e) => {
            dispatch(
              updateDefaultValue({
                name: opInput.placeholder,
                operator: operator,
                value: e.target.value,
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export default EFTextArea;
