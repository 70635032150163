import React from "react";
import Dropdown from "../../../../../../utils/Dropdown";
import { updateAlignment } from "../../../../../../slices/docsSlice";
import { useDispatch, useSelector } from "react-redux";

const Alignment = ({ alignment, RRIP_P, SIPHF }) => {
  const dispatch = useDispatch();
  const { alignments } = useSelector((state) => state.options);

  return (
    <div>
      <h2 className="pb-1">Alignment</h2>
      <Dropdown
        value={alignment}
        options={alignments}
        target="option"
        width={100}
        height={40}
        click={(value) => {
          dispatch(
            updateAlignment({
              para: RRIP_P,
              value,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      />
    </div>
  );
};

export default Alignment;
