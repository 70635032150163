import { returnEle, returnHFEle, returnEI, consoleFun } from "./functions";

const functionProps = {
  addJSFunc: (state, { payload }) => {
    const ele = returnEle(state, payload.path);
    ele.jsFun = payload.value;
  },
  updateJSFunc: (state, { payload }) => {
    const ele = returnEle(state, payload.path);
    ele.jsFun = payload.value;
  },
  removeJSFunc: (state, { payload }) => {
    const ele = returnEle(state, payload.path);
    delete ele.jsFun;
  },
  updateInc: (state, { payload }) => {
    const ele = returnEle(state, payload.path);
    if (ele.inc) {
      delete ele.inc;
    } else {
      ele.inc = true;
    }
  },
};

export default functionProps;
