import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Input from "../../utils/Input";
import TextArea from "../../utils/TextArea";
import Dropdown from "../../utils/Dropdown";
import { Fragment } from "react";
import Button from "../../utils/Button";
import {
  addUserInput,
  updateUserCondition,
  removeUserInput,
} from "../../slices/entryForm";
import { IoCloseSharp } from "react-icons/io5";
import EFButtons from "./entryForm/buttons";
import EFControls from "./entryForm/controls";
import EFFields from "./entryForm/fields";

import produceClone from "../../components/Lodash";
import { toast } from "react-toastify";
import { setSingleID } from "../../slices/internalDependencySlice";
import { setFilledEF } from "../../slices/entryForm";
import { getForms } from "../../slices/formsSlice";

import { useUpdateDocumentMutation } from "../../slices/documentsApiSlice";

const EntryForm = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { forms, userForms } = useSelector((state) => state.forms);
  const { docId, docs, indexPath, dev, category, name, runStatus } =
    useSelector((state) => state.docs);
  const { entryForm, filledEF } = useSelector((state) => state.entryForm);
  const { internalDependency } = useSelector(
    (state) => state.internalDependency
  );

  const [updateDocument, { isLoading: loadingUpdate }] =
    useUpdateDocumentMutation();

  const { pathname } = useLocation();
  const splitPath = pathname.split("/");
  // let lastIndex = splitPath[splitPath.length - 1];
  // let entryForm = lastIndex.slice(0, -3);

  let newForms = produceClone(forms);
  Object.keys(newForms).forEach((form) => {
    window[form] = newForms[form];
  });

  console.log(forms);
  console.log(entryForm, filledEF);

  // let path = "forms[entryForm]";
  // if (userInfo.role === "Manager" || userInfo.role === "Operator") {
  //   path = "forms[entryForm].document";
  // }

  const updateDocumentHandler = async () => {
    const filledEF = {};
    Object.keys(entryForm).forEach((operator) => {
      let op = entryForm[operator];
      filledEF[operator] = {};
      Object.keys(op).forEach((item) => {
        const opInput = op[item];
        if (opInput.cluster) {
          filledEF[operator][item] = [[]];
          opInput.row.forEach((cluValue) => {
            if (cluValue.defaultValue) {
              filledEF[operator][item][0].push(cluValue.defaultValue);
            } else {
              filledEF[operator][item][0].push("");
            }
          });
        } else {
          if (opInput.defaultValue) {
            filledEF[operator][item] = opInput.defaultValue;
          } else {
            filledEF[operator][item] = "";
          }
        }
      });
    });

    try {
      const res = await updateDocument({
        docId,
        docs,
        entryForm,
        filledEF,
        indexPath,
      }).unwrap();
      console.log(res);
      internalDependency.map((doc, i) => {
        if (
          `${res.document.category}$${res.document.name}` ===
          `${doc.category}$${doc.name}`
        ) {
          dispatch(
            setSingleID({
              i,
              doc: res.document,
            })
          );
          dispatch(setFilledEF(res.document.filledEF));
          if (!dev) {
            newForms[`${res.document.category}$${res.document.name}`] =
              res.document;
            newForms[`${res.document.category}$${res.document.name}_EF`] =
              res.document.filledEF;
            dispatch(
              getForms({
                forms: newForms,
                userForms,
              })
            );
          }
        }
      });

      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="psm overflow-auto">
      {!runStatus && userInfo.role === "Manager" ? (
        <div className="flex justify-center mb-2">
          <button
            className="bg-primary-500 text-light-500 px-2 py-1"
            onClick={() => updateDocumentHandler()}
          >
            Update Doc
          </button>
        </div>
      ) : null}

      <div className="flex gap-4">
        {userInfo.role === "Manager" && !dev ? (
          <div className="flex flex-1 flex-col gap-2">
            <EFButtons operator="user" />
            <div className="min-h-[1000px]">
              {Object.keys(entryForm)?.map((operator) => {
                let op = entryForm[operator];
                if (operator === "user") {
                  return <EFControls op={op} operator="user" />;
                }
              })}
            </div>
            <EFButtons operator="manager" />
            <div className="min-h-[1000px]">
              {Object.keys(entryForm)?.map((operator) => {
                let op = entryForm[operator];
                if (operator === "manager") {
                  return <EFControls op={op} operator="manager" />;
                }
              })}
            </div>
          </div>
        ) : null}
        <div className="flex-1 flex flex-col gap-2">
          {Object.keys(entryForm)?.map((operator) => {
            let op = entryForm[operator];
            if (userInfo.role === "User" && operator === "user") {
              return <EFFields op={op} operator={operator} />;
            } else if (userInfo.role === "Manager") {
              return <EFFields op={op} operator={operator} />;
            }
          })}
        </div>
        {/* {userInfo.role === "Manager" ? (
          <div>
            <h2>Controls</h2>
            <div className="flex gap-4 flex-wrap">
              <button
                className="bg-cyan-950 text-light-500 px-4 py-2"
                onClick={() => {
                  const inputKey = prompt("Enter Input Key: ");
                  console.log(inputKey);
                }}
              >
                Add Input
              </button>
              <button className="bg-cyan-950 text-light-500 px-4 py-2">
                Add Text Area
              </button>
              <button className="bg-cyan-950 text-light-500 px-4 py-2">
                Add Drop Down
              </button>
              <button className="bg-cyan-950 text-light-500 px-4 py-2">
                Add Date Picker
              </button>
              <button className="bg-cyan-950 text-light-500 px-4 py-2">
                Add Conditional Input
              </button>
              <button className="bg-cyan-950 text-light-500 px-4 py-2">
                Add Multiple Inputs
              </button>
              <button className="bg-cyan-950 text-light-500 px-4 py-2">
                Add Multi Conditional
              </button>
            </div>
          </div>
        ) : null} */}
      </div>

      {/* {forms && entryForm
        ? eval(path)?.map((doc, i1) => {
            return (
              <Fragment
                key={i1}
                // className="flex flex-col gap-4"
              >
                {userInfo.role === "Manager" || userInfo.role === "Operator" ? (
                  <h2 className="font-mono bg-cyan-950 text-light-500 text-center p-2">
                    Manager
                  </h2>
                ) : null}
                {doc.ele.map((S, i2) => {
                  return (
                    <Fragment
                      key={i2}
                      // className="flex flex-col gap-4"
                    >
                      {S.ele.map((E, i3) => {
                        return (
                          <Fragment
                            key={i3}
                            // className="flex flex-col gap-4"
                          >
                            {userInfo.role === "Manager" ||
                            userInfo.role === "Operator" ? (
                              <>
                                {E.manager?.XT?.length > 0 ? (
                                  <div className="flex flex-wrap gap-4">
                                    {E.manager?.XT?.map((XT, i4) => {
                                      if (typeof XT.name === "string") {
                                        return (
                                          <div>
                                            <h2 className="font-mono pb-2">
                                              {XT.name}
                                            </h2>
                                            <Input
                                              placeholder={XT.name}
                                              type="text"
                                              className="w-[250px] h-[40px]"
                                              value={
                                                forms[lastIndex][i1].ele[i2]
                                                  .ele[i3].manager.XT[i4].name
                                              }
                                              change={(e) =>
                                                dispatch(
                                                  updateXT({
                                                    EF: lastIndex,
                                                    value: e.target.value,
                                                    doc: i1,
                                                    sec: i2,
                                                    ele: i3,
                                                    xt: i4,
                                                    role: userInfo.role.toLowerCase(),
                                                  })
                                                )
                                              }
                                              click={(e) => {
                                                console.log(eval(path));
                                                console.log(path);
                                                console.log(i1, i2, i3, i4);
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (
                                                  userInfo.role === "Manager" &&
                                                  dev
                                                ) {
                                                  let val = `${category}$${name}_EF[${i1}].ele[${i2}].ele[${i3}].manager.XT[${i4}].name`;
                                                  navigator.clipboard.writeText(
                                                    val
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div>
                                            <h2 className="font-mono pb-2">
                                              Drop Down
                                            </h2>
                                            <Dropdown
                                              options={XT.name}
                                              target="option"
                                              value={
                                                forms[lastIndex][i1].ele[i2]
                                                  .ele[i3].manager.XT[i4].name
                                              }
                                              click={(value) => {
                                                if (
                                                  userInfo.role === "Manager" &&
                                                  dev
                                                ) {
                                                  let val = `${category}$${name}_EF[${i1}].ele[${i2}].ele[${i3}].manager.XT[${i4}].name`;
                                                  navigator.clipboard.writeText(
                                                    val
                                                  );
                                                }
                                                dispatch(
                                                  updateXT({
                                                    EF: lastIndex,
                                                    value: value,
                                                    doc: i1,
                                                    sec: i2,
                                                    ele: i3,
                                                    xt: i4,
                                                    role: userInfo.role.toLowerCase(),
                                                  })
                                                );
                                              }}
                                              width={250}
                                              height={40}
                                            />
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                ) : null}
                                {E.manager?.condition ? (
                                  <div className="flex flex-col gap-4 pb-4">
                                    {E.manager?.condition ? (
                                      <>
                                        <div>
                                          <h2 className="pb-2">
                                            No.of {`${E.manager.condition}s`}
                                          </h2>
                                          <Input
                                            type="number"
                                            className="w-[250px] h-[40px]"
                                            value={
                                              forms[lastIndex][i1].ele[i2].ele[
                                                i3
                                              ].manager.condition
                                            }
                                            change={(e) => {
                                              dispatch(
                                                updateCondition({
                                                  EF: lastIndex,
                                                  doc: i1,
                                                  sec: i2,
                                                  ele: i3,
                                                  role: userInfo.role.toLowerCase(),
                                                  value: e.target.value,
                                                })
                                              );
                                            }}
                                          />
                                        </div>

                                        {forms[lastIndex][i1].ele[i2].ele[
                                          i3
                                        ].manager.MXT.map((ROW, i4) => {
                                          return (
                                            <div className="flex flex-wrap gap-4">
                                              {ROW.map((MXT, i5) => {
                                                if (
                                                  typeof E.manager.MXT[i5]
                                                    .name === "string"
                                                ) {
                                                  return (
                                                    <div>
                                                      <h2 className="font-mono pb-2">
                                                        {E.manager.MXT[i5].name}
                                                      </h2>
                                                      <Input
                                                        placeholder={
                                                          E.manager.MXT[i5].name
                                                        }
                                                        type="text"
                                                        className="w-[250px] h-[40px]"
                                                        value={
                                                          forms[lastIndex][i1]
                                                            .ele[i2].ele[i3]
                                                            .manager.MXT[i4][i5]
                                                            .name
                                                        }
                                                        change={(e) =>
                                                          dispatch(
                                                            updateMXT({
                                                              EF: lastIndex,
                                                              value:
                                                                e.target.value,
                                                              doc: i1,
                                                              sec: i2,
                                                              ele: i3,
                                                              row: i4,
                                                              mxt: i5,
                                                              role: userInfo.role.toLowerCase(),
                                                            })
                                                          )
                                                        }
                                                        click={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          if (
                                                            userInfo.role ===
                                                              "Manager" &&
                                                            dev
                                                          ) {
                                                            let val = `${category}$${name}_EF[${i1}].ele[${i2}].ele[${i3}].manager.MXT[${i4}][${i5}].name`;
                                                            navigator.clipboard.writeText(
                                                              val
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div>
                                                      <h2 className="font-mono pb-2">
                                                        Drop Down
                                                      </h2>
                                                      <Dropdown
                                                        options={
                                                          E.manager.MXT[i5].name
                                                        }
                                                        target="option"
                                                        value={
                                                          forms[lastIndex][i1]
                                                            .ele[i2].ele[i3]
                                                            .manager.MXT[i4][i5]
                                                            .name
                                                        }
                                                        click={(value) => {
                                                          if (
                                                            userInfo.role ===
                                                              "Manager" &&
                                                            dev
                                                          ) {
                                                            let val = `${category}$${name}_EF[${i1}].ele[${i2}].ele[${i3}].manager.MXT[${i4}][${i5}].name`;
                                                            navigator.clipboard.writeText(
                                                              val
                                                            );
                                                          }
                                                          dispatch(
                                                            updateMXT({
                                                              EF: lastIndex,
                                                              value: value,
                                                              doc: i1,
                                                              sec: i2,
                                                              ele: i3,
                                                              row: i4,
                                                              mxt: i5,
                                                              role: userInfo.role.toLowerCase(),
                                                            })
                                                          );
                                                        }}
                                                        width={250}
                                                        height={40}
                                                      />
                                                    </div>
                                                  );
                                                }
                                              })}
                                            </div>
                                          );
                                        })}
                                      </>
                                    ) : null}
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })
        : null}
      {forms && entryForm
        ? eval(path)?.map((doc, i1) => {
            return (
              <Fragment
                key={i1}
                // className="flex flex-col gap-4 border-4 border-red-500"
              >
                {userInfo.role === "Manager" || userInfo.role === "Operator" ? (
                  <h2 className="font-mono bg-cyan-950 text-light-500 text-center p-2">
                    User
                  </h2>
                ) : null}
                {doc.ele.map((S, i2) => {
                  return (
                    <Fragment
                      key={i2}
                      // className="flex flex-col gap-4"
                    >
                      {S.ele.map((E, i3) => {
                        return (
                          <Fragment
                            key={i3}
                            // className="flex flex-col gap-4"
                          >
                            {userInfo.role === "User" ||
                            userInfo.role === "Manager" ||
                            userInfo.role === "Operator" ? (
                              <>
                                {E.user?.XT?.length > 0 ? (
                                  <div className="flex flex-wrap gap-4 pb-2">
                                    {E.user?.XT?.map((XT, i4) => {
                                      if (typeof XT.name === "string") {
                                        return (
                                          <div>
                                            <h2 className="font-mono pb-2">
                                              {XT.name}
                                            </h2>
                                            <Input
                                              placeholder={XT.name}
                                              type="text"
                                              className="w-[250px] h-[40px]"
                                              value={
                                                forms[lastIndex][i1].ele[i2]
                                                  .ele[i3].user.XT[i4].name
                                              }
                                              change={(e) =>
                                                dispatch(
                                                  updateXT({
                                                    EF: lastIndex,
                                                    value: e.target.value,
                                                    doc: i1,
                                                    sec: i2,
                                                    ele: i3,
                                                    xt: i4,
                                                    role: "user",
                                                  })
                                                )
                                              }
                                              click={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (
                                                  userInfo.role === "Manager" &&
                                                  dev
                                                ) {
                                                  let val = `${category}$${name}_EF[${i1}].ele[${i2}].ele[${i3}].user.XT[${i4}].name`;
                                                  navigator.clipboard.writeText(
                                                    val
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div>
                                            <h2 className="font-mono pb-2">
                                              Drop Down
                                            </h2>
                                            <Dropdown
                                              options={XT.name}
                                              target="option"
                                              value={
                                                forms[lastIndex][i1].ele[i2]
                                                  .ele[i3].user.XT[i4].name
                                              }
                                              click={(value) => {
                                                if (
                                                  userInfo.role === "Manager" &&
                                                  dev
                                                ) {
                                                  let val = `${category}$${name}_EF[${i1}].ele[${i2}].ele[${i3}].user.XT[${i4}].name`;
                                                  navigator.clipboard.writeText(
                                                    val
                                                  );
                                                }
                                                dispatch(
                                                  updateXT({
                                                    EF: lastIndex,
                                                    value: value,
                                                    doc: i1,
                                                    sec: i2,
                                                    ele: i3,
                                                    xt: i4,
                                                    role: "user",
                                                  })
                                                );
                                              }}
                                              width={250}
                                              height={40}
                                            />
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                ) : null}

                                {E.user?.condition ? (
                                  <div className="flex flex-col gap-4 pb-4">
                                    {E.user?.condition ? (
                                      <>
                                        <div>
                                          <h2 className="pb-2">
                                            No.of {`${E.user.condition}s`}
                                          </h2>
                                          <Input
                                            type="number"
                                            className="w-[250px] h-[40px]"
                                            value={
                                              forms[lastIndex][i1].ele[i2].ele[
                                                i3
                                              ].user.condition
                                            }
                                            change={(e) => {
                                              dispatch(
                                                updateCondition({
                                                  EF: lastIndex,
                                                  doc: i1,
                                                  sec: i2,
                                                  ele: i3,
                                                  role: "user",
                                                  value: e.target.value,
                                                })
                                              );
                                            }}
                                          />
                                        </div>

                                        {forms[lastIndex][i1].ele[i2].ele[
                                          i3
                                        ].user?.MXT?.map((ROW, i4) => {
                                          return (
                                            <div className="flex flex-wrap gap-4">
                                              {ROW?.map((MXT, i5) => {
                                                if (
                                                  typeof E.user.MXT[i5].name ===
                                                  "string"
                                                ) {
                                                  return (
                                                    <div>
                                                      <h2 className="font-mono pb-2">
                                                        {E.user.MXT[i5].name}
                                                      </h2>
                                                      <Input
                                                        placeholder={
                                                          E.user.MXT[i5].name
                                                        }
                                                        type="text"
                                                        className="w-[250px] h-[40px]"
                                                        value={
                                                          forms[lastIndex][i1]
                                                            .ele[i2].ele[i3]
                                                            .user.MXT[i4][i5]
                                                            .name
                                                        }
                                                        change={(e) =>
                                                          dispatch(
                                                            updateMXT({
                                                              EF: lastIndex,
                                                              value:
                                                                e.target.value,
                                                              doc: i1,
                                                              sec: i2,
                                                              ele: i3,
                                                              row: i4,
                                                              mxt: i5,
                                                              role: "user",
                                                            })
                                                          )
                                                        }
                                                        click={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          if (
                                                            userInfo.role ===
                                                              "Manager" &&
                                                            dev
                                                          ) {
                                                            let val = `${category}$${name}_EF[${i1}].ele[${i2}].ele[${i3}].user.MXT[${i4}][${i5}].name`;
                                                            navigator.clipboard.writeText(
                                                              val
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div>
                                                      <h2 className="font-mono pb-2">
                                                        Drop Down
                                                      </h2>
                                                      <Dropdown
                                                        options={
                                                          E.user.MXT[i5].name
                                                        }
                                                        target="option"
                                                        value={
                                                          forms[lastIndex][i1]
                                                            .ele[i2].ele[i3]
                                                            .user.MXT[i4][i5]
                                                            .name
                                                        }
                                                        click={(value) => {
                                                          if (
                                                            userInfo.role ===
                                                              "Manager" &&
                                                            dev
                                                          ) {
                                                            let val = `${category}$${name}_EF[${i1}].ele[${i2}].ele[${i3}].user.MXT[${i4}][${i5}].name`;
                                                            navigator.clipboard.writeText(
                                                              val
                                                            );
                                                          }

                                                          dispatch(
                                                            updateMXT({
                                                              EF: lastIndex,
                                                              value: value,
                                                              doc: i1,
                                                              sec: i2,
                                                              ele: i3,
                                                              row: i4,
                                                              mxt: i5,
                                                              role: "user",
                                                            })
                                                          );
                                                        }}
                                                        width={250}
                                                        height={40}
                                                      />
                                                    </div>
                                                  );
                                                }
                                              })}
                                            </div>
                                          );
                                        })}
                                      </>
                                    ) : null}
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })
        : null} */}
    </div>
  );
};

export default EntryForm;
