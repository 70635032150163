import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddLevelMutation,
  useGetLevelsQuery,
  useDeleteLevelMutation,
} from "./slices/levelApiSlice";
import { updateLevel, updateName } from "./slices/level";
import { setSingleForm, updateLvlInput } from "../../slices/formsSlice";
import { toast } from "react-toastify";
import Input2 from "../../utils/Input2";
import Loader from "../../components/Loader";
import Table from "../../utils/Table/Table";
import TableRow from "../../utils/Table/TableRow";
import TableCell from "../../utils/Table/TableCell";
import { IoCloseSharp } from "react-icons/io5";
import Input from "../../utils/Input";

const Level = () => {
  const { data: levels, refetch, isLoading } = useGetLevelsQuery();
  const [addLevel, {}] = useAddLevelMutation();
  const [deleteLevel, {}] = useDeleteLevelMutation();
  const { name } = useSelector((state) => state.level);
  const { userForms, forms } = useSelector((state) => state.forms);
  const dispatch = useDispatch();

  const addLevelHandler = async () => {
    console.log("Hello world");
    try {
      const res = await addLevel({
        name,
        level: forms.Level,
      }).unwrap();
      console.log(res);
      dispatch(updateName(""));
      refetch();
      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const deleteLevelHandler = async (levelId) => {
    if (levels.length === 1) {
      toast.error("Atleast one level is mandatory.");
    } else {
      try {
        const res = await deleteLevel(levelId).unwrap();
        toast.success(res.message);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <div className="psm flex gap-4 bg2">
      <div className="flex flex-col gap-2 w-[200px]">
        {isLoading ? (
          <Loader />
        ) : (
          levels.map((item, index) => {
            return (
              <div
                className={
                  JSON.stringify(item.level) === JSON.stringify(forms.Level)
                    ? "bg-cyan-950 text-light-500 cursor-pointer w-[200px] py-1 pl-2 relative"
                    : "bg-gray-300 cursor-pointer w-[200px] py-1 pl-2 relative"
                }
                key={item._id}
                onClick={() => {
                  dispatch(updateLevel(item.level));
                  dispatch(
                    setSingleForm({
                      name: "Level",
                      value: item.level,
                    })
                  );
                }}
              >
                <div
                  className={`${
                    JSON.stringify(item.level) === JSON.stringify(forms.Level)
                      ? "flex"
                      : "hidden"
                  } absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
                  onClick={() => deleteLevelHandler(item._id)}
                >
                  <IoCloseSharp />
                </div>
                <h2 className="font-mono">
                  {item.name ? item.name : `Level ${index + 1}`}
                </h2>
              </div>
            );
          })
        )}
      </div>
      <div className="flex gap-4 flex-col">
        <div>
          <Input
            type="text"
            className="w-[250px] h-[40px]"
            placeholder="Level Name"
            value={name}
            change={(e) => dispatch(updateName(e.target.value))}
          />
        </div>
        <Table>
          {forms.Level?.map((row, index) => {
            return (
              <TableRow
                key={index}
                className={(index + 1) % 2 === 0 ? "bg-gray-100" : null}
              >
                {index === 0 ? (
                  <TableCell className="border w-[200px]">
                    <div>Domestic</div>
                  </TableCell>
                ) : index === 1 ? (
                  <TableCell className="border">
                    <div>Indigenous</div>
                  </TableCell>
                ) : index === 2 ? (
                  <TableCell className="border">
                    <div>Power and Fuel</div>
                  </TableCell>
                ) : index === 3 ? (
                  <TableCell className="border">
                    <div>Direct Labour</div>
                  </TableCell>
                ) : index === 4 ? (
                  <TableCell className="border">
                    <div>Repairs and Maintanance</div>
                  </TableCell>
                ) : index === 5 ? (
                  <TableCell className="border">
                    <div>Other Manufacturing</div>
                  </TableCell>
                ) : index === 6 ? (
                  <TableCell className="border">
                    <div>Depreciation</div>
                  </TableCell>
                ) : index === 7 ? (
                  <TableCell className="border">
                    <div>Closing Stock</div>
                  </TableCell>
                ) : index === 8 ? (
                  <TableCell className="border">
                    <div>Selling and General</div>
                  </TableCell>
                ) : index === 9 ? (
                  <TableCell className="border">
                    <div>Sundry Creditor</div>
                  </TableCell>
                ) : index === 10 ? (
                  <TableCell className="border">
                    <div>Other Current Liabilities</div>
                  </TableCell>
                ) : index === 11 ? (
                  <TableCell className="border">
                    <div>Partners capital</div>
                  </TableCell>
                ) : index === 12 ? (
                  <TableCell className="border">
                    <div>Cash & Bank Balances</div>
                  </TableCell>
                ) : (
                  <TableCell className="border">
                    <div>Investment in others</div>
                  </TableCell>
                )}

                {row.map((cell, index1) => {
                  return (
                    <TableCell className="border" style={{ padding: "0px" }}>
                      <Input2
                        type="number"
                        value={cell}
                        change={(e) => {
                          dispatch(
                            updateLvlInput({
                              i: index,
                              j: index1,
                              value: parseFloat(e.target.value),
                            })
                          );
                        }}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </Table>
        <div className="flex justify-center">
          <button
            className="bg-cyan-950 w-[200px] h-[40px] text-light-500"
            onClick={addLevelHandler}
          >
            Add Level
          </button>
        </div>
      </div>
    </div>
  );
};

export default Level;
