import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import produceClone from "../../../../components/Lodash"; // DO NOT REMOVE
import { setDocs, setRunStatus } from "../../../../slices/docsSlice";
import {
  setFormsRunStatus,
  setFormDocument,
  setSingleForm,
} from "../../../../slices/formsSlice";
import _ from "lodash";
import { useGetBenchmarksQuery } from "../../../../externalDependency/LanceTechServices/slices/benchmarkApiSlice";
import { setBenchmark } from "../../../../externalDependency/LanceTechServices/slices/benchmark";
import { TABLE, ROW, CELL, PARA, TEXT } from "../../../../slices/Docs/elements";

const RunFunctions = () => {
  const dispatch = useDispatch();
  const { docs, name, category, runStatus, SIP } = useSelector(
    (state) => state.docs
  );
  const { filledEF } = useSelector((state) => state.entryForm);
  const { userForms, forms } = useSelector((state) => state.forms);
  const { internalDependency } = useSelector(
    (state) => state.internalDependency
  );

  const { data: benchmarkData, refetch, isLoading } = useGetBenchmarksQuery();

  useEffect(() => {
    if (benchmarkData) {
      dispatch(setBenchmark(benchmarkData[0]?.benchmark));
      dispatch(
        setSingleForm({
          name: "Benchmark",
          value: benchmarkData[0]?.benchmark,
        })
      );
    }
  }, [benchmarkData]);

  window["num"] = (val) => {
    const num = parseFloat(val);
    if (isNaN(num) || !isFinite(num)) {
      return 0;
    }
    return num;
  };

  window["dn"] = (val) => {
    return parseFloat(val).toFixed(2);
  };

  window["tc"] = (val) => {
    if (typeof val !== "number") {
      return 0;
    } else if (isNaN(val)) {
      return 0;
    } else if (val === Infinity) {
      return 0;
    }
    return val;
  };

  window["RE"] = ({ E, V }) => {
    E["ele"] = V.ele;
    E["properties"] = V.properties;
  };

  window["CD"] = () => {
    let d = new Date();
    let day = d.getDate().toString().padStart(2, "0");
    let month = (d.getMonth() + 1).toString().padStart(2, "0");
    let year = d.getFullYear();

    let CD = `${day}-${month}-${year}`;
    return CD;
  };

  const textStructure = ({ V, TP }) => {
    let text = structuredClone(TEXT);
    if (TP) {
      text.properties = TP;
    }
    text.t = V;
    return text;
  };

  const paraStructure = ({ V, PP, TP }) => {
    let para = structuredClone(PARA);
    if (PP) {
      para.properties = PP;
    }
    switch (typeof V) {
      case "string":
        para.ele.push(textStructure({ V, TP }));
        break;
      case "number":
        para.ele.push(textStructure({ V, TP }));
        break;
      case "object":
        para.ele = V.map((R) => {
          return textStructure({ V: R.V, TP: R.TP });
        });
        break;
    }
    return para;
  };

  const cellStructure = ({ V, CP, PP, TP }) => {
    let cell = structuredClone(CELL);
    if (CP) {
      cell.properties = CP;
    }
    switch (typeof V) {
      case "string":
        cell.ele.push(paraStructure({ V, PP, TP }));
        break;
      case "number":
        cell.ele.push(paraStructure({ V, PP, TP }));
        break;
      case "object":
        cell.ele = V.map((R) => {
          return paraStructure({ V: R.V, PP: R.PP, TP: R.TP });
        });
        break;
    }
    return cell;
  };

  window["CT"] = ({ V, TP }) => {
    return textStructure({ V, TP });
  };

  window["CP"] = ({ V, PP, TP }) => {
    return paraStructure({ V, PP, TP });
  };

  window["CC"] = ({ V, CP, PP, TP }) => {
    // let cell = structuredClone(CELL);
    // let para = structuredClone(PARA);
    // let text = structuredClone(TEXT);
    // if (CP) {
    //   cell.properties = CP;
    // }
    // if (PP) {
    //   para.properties = PP;
    // }
    // if (TP) {
    //   text.properties = TP;
    // }
    // text.t = V;
    // para.ele.push(text);
    // cell.ele.push(para);
    // return cell;
    return cellStructure({ V, CP, PP, TP });
  };

  window["CR"] = (cells) => {
    try {
      let row = {
        ele: [],
        properties: {},
        name: "",
        type: "row",
      };
      cells.map(({ V, CP, PP, TP }, i) => {
        row.ele.push(cellStructure({ V, CP, PP, TP }));
        // switch (typeof V) {
        //   case "string":
        //     row.ele.push(cellStructure({ V, CP, PP, TP }));
        //     break;
        //   case "object":
        //     console.log(V);
        //     row.ele = V.map((R) => {
        //       return cellStructure({ V: R.V, CP: R.CP, PP: R.PP, TP: R.TP });
        //     });
        //     break;
        // }

        // let cell = {
        //   ele: [],
        //   properties: CP || {},
        //   name: "",
        //   type: "cell",
        // };
        // let para = {
        //   ele: [],
        //   properties: PP || {},
        //   name: 0,
        //   type: "para",
        // };
        // let text = {
        //   t: V,
        //   properties: TP || {},
        //   type: "text",
        // };
        // para.ele.push(text);
        // cell.ele.push(para);
        // row.ele.push(cell);
      });
      return row;
    } catch (error) {
      console.error("Error in CR function:", error);
    }
  };

  window["CTB"] = (rows) => {
    let table = structuredClone(TABLE);
    rows.map(({ V, RP, CP, PP, TP }, i) => {
      table.ele.push(cellStructure({ V, CP, PP, TP }));
    });
    return table;
  };

  let newForms = produceClone(forms);
  Object.keys(newForms).forEach((form) => {
    window[form] = newForms[form];
  });

  const generateReport = (SIP) => {
    let NIP;
    if (SIP && SIP.length === 3) {
      NIP = SIP.map((index) => index.split("_")[0]).join("_");
    }

    let CES = produceClone(docs);

    outerLoop: for (let i1 = 0; i1 < CES.length; i1++) {
      let doc = CES[i1];
      if (!doc.bulkFiles) {
        for (let i2 = 0; i2 < doc.ele.length; i2++) {
          let sec = doc.ele[i2];
          for (let i3 = 0; i3 < sec.ele.length; i3++) {
            let E = sec.ele[i3];
            let USER = filledEF.user;
            let MANAGER = filledEF.manager;

            if (E.jsFun) {
              // Don't remove this console.log
              console.log(`${i1}_${i2}_${i3}`);
              let func = eval("(" + E.jsFun + ")");
              func(E, E.ele, USER, MANAGER, {
                path: `CES[${i1}].ele[${i2}].ele[${i3}]`,
              });
            }

            if (NIP === `${i1}_${i2}_${i3}`) {
              break outerLoop;
            }
          }
        }
      } else {
        let func = eval("(" + doc.jsFun + ")");
        func(doc);
      }
    }

    dispatch(setDocs(CES));
    dispatch(
      setFormDocument({
        name: `${category}$${name}`,
        value: CES,
      })
    );
  };

  console.log(forms);

  return (
    <div className="flex items-center gap-4">
      <button
        className="bg-cyan-950 text-light-500 px-2 py-1"
        onClick={() => {
          internalDependency.map((doc, i) => {
            if (category === doc.category && name === doc.name) {
              dispatch(
                setFormsRunStatus({
                  name: `${category}$${name}`,
                  value: true,
                })
              );
              dispatch(setRunStatus(true));
            }
          });
          generateReport(SIP);
        }}
      >
        Run Index
      </button>
      <button
        className="bg-cyan-950 text-light-500 px-2 py-1"
        onClick={() => {
          internalDependency.map((doc, i) => {
            if (category === doc.category && name === doc.name) {
              dispatch(
                setFormsRunStatus({
                  name: `${category}$${name}`,
                  value: true,
                })
              );
              dispatch(setRunStatus(true));
            }
          });
          generateReport();
        }}
      >
        Run
      </button>

      {runStatus ? (
        <button
          className="bg-warning-500 text-black px-2 py-1"
          onClick={() => {
            internalDependency.map((doc, i) => {
              if (category === doc.category && name === doc.name) {
                dispatch(
                  setFormsRunStatus({
                    name: `${category}$${name}`,
                    value: false,
                  })
                );
                dispatch(setRunStatus(false));
                dispatch(setDocs(doc.document));
                dispatch(
                  setFormDocument({
                    name: `${category}$${name}`,
                    value: doc.document,
                  })
                );
              }
            });
          }}
        >
          Clear
        </button>
      ) : null}
    </div>
  );
};

export default RunFunctions;
