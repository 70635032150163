import React from "react";
import { addTextToPara } from "../../../../../../slices/docsSlice";
import { useDispatch } from "react-redux";

const AddText = ({ RRIP_P, docIndex, SIPHF }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <h2 className="pb-1">Add Text</h2>
      <button
        className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
        onClick={() => {
          dispatch(
            addTextToPara({
              para: RRIP_P,
              docIndex,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      >
        Text
      </button>
    </div>
  );
};

export default AddText;
