import { convertToPx } from "../../UnitConversion";
export const getCheckboxStyle = ({
  checkboxProps,
  paraProps,
  cellProps,
  rowProps,
  tableProps,
  docProps,
}) => {
  const style = {};
  return style;
};
