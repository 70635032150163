import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  addUserInput,
  updateInputType,
  updateInputLength,
  updateDefaultValue,
  removeUserInput,
} from "../../../../slices/entryForm";
import Input from "../../../../utils/Input";
import Dropdown from "../../../../utils/Dropdown";
import TextArea from "../../../../utils/TextArea";

const EFMulti = ({ opInput, operator }) => {
  // console.log(opInput);
  const dispatch = useDispatch();

  return (
    <div className="bg-purple-200 p-2 rounded flex flex-col gap-2 relative">
      <div
        className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
        onClick={() =>
          dispatch(
            removeUserInput({
              name: opInput.cluster,
              operator: operator,
            })
          )
        }
      >
        <IoCloseSharp />
      </div>
      <h2 className="pb-1">
        {opInput.cluster} ({opInput.element})
      </h2>
      <div className="flex gap-4 flex-wrap">
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Input Key: ");
            dispatch(
              addUserInput({
                cluster: opInput.cluster,
                name: inputKey,
                operator: operator,
                element: "input",
              })
            );
          }}
        >
          Add Input
        </button>
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Input Key: ");
            dispatch(
              addUserInput({
                cluster: opInput.cluster,
                name: inputKey,
                operator: operator,
                element: "textarea",
              })
            );
          }}
        >
          Add Text Area
        </button>
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Input Key: ");
            let noOfVal = prompt("No. of Inputs");
            let valArr = [];
            for (let i = 0; i < noOfVal; i++) {
              let val = prompt(`Input ${i + 1}`);
              valArr.push(val);
            }
            dispatch(
              addUserInput({
                cluster: opInput.cluster,
                name: inputKey,
                operator: operator,
                element: "dropdown",
                options: valArr,
              })
            );
          }}
        >
          Add Drop Down
        </button>
        <button
          className="bg-cyan-950 text-light-500 px-4 py-2"
          onClick={() => {
            const inputKey = prompt("Enter Input Key: ");
            dispatch(
              addUserInput({
                cluster: opInput.cluster,
                name: inputKey,
                operator: operator,
                element: "date",
              })
            );
          }}
        >
          Add Date Picker
        </button>
      </div>
      <div className="flex flex-wrap gap-4">
        {opInput.row.map((item, i) => {
          let opMulInput = item;
          switch (opMulInput.element) {
            case "input":
              return (
                <div className="flex flex-wrap items-center gap-4 bg-red-200 p-2 rounded relative">
                  <div
                    className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
                    onClick={() =>
                      dispatch(
                        removeUserInput({
                          index: i,
                          cluster: opInput.cluster,
                          operator: operator,
                        })
                      )
                    }
                  >
                    <IoCloseSharp />
                  </div>
                  <div>
                    <h2 className="pb-1">
                      {opMulInput.placeholder} ({opMulInput.element})
                    </h2>
                    <Input
                      type="text"
                      placeholder="default value"
                      value={opMulInput.defaultValue}
                      change={(e) => {
                        dispatch(
                          updateDefaultValue({
                            index: i,
                            cluster: opInput.cluster,
                            operator: operator,
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                  </div>
                  <div>
                    <h2 className="pb-1">Input Type</h2>
                    <Dropdown
                      name="Input Type"
                      width={200}
                      height={40}
                      value={opMulInput.type}
                      options={["text", "number"]}
                      target="option"
                      click={(value) => {
                        dispatch(
                          updateInputType({
                            index: i,
                            cluster: opInput.cluster,
                            operator,
                            value: value,
                          })
                        );
                      }}
                    />
                  </div>
                  <div>
                    <h2 className="pb-1">Input Length</h2>
                    <Input
                      className="w-[100px]"
                      type="number"
                      value={opMulInput.maxLangth ? opMulInput.maxLangth : ""}
                      change={(e) => {
                        dispatch(
                          updateInputLength({
                            index: i,
                            cluster: opInput.cluster,
                            operator,
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              );
            case "textarea":
              return (
                <div className="flex flex-wrap items-start gap-4 bg-blue-200 p-2 rounded relative">
                  <div
                    className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
                    onClick={() =>
                      dispatch(
                        removeUserInput({
                          index: i,
                          cluster: opInput.cluster,
                          operator: operator,
                        })
                      )
                    }
                  >
                    <IoCloseSharp />
                  </div>
                  <div>
                    <h2 className="pb-1">
                      {opMulInput.placeholder} ({opMulInput.element})
                    </h2>
                    <TextArea
                      type="text"
                      placeholder="default value"
                      value={opMulInput.defaultValue}
                      change={(e) => {
                        dispatch(
                          updateDefaultValue({
                            index: i,
                            cluster: opInput.cluster,
                            operator: operator,
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              );
            case "dropdown":
              return (
                <div className="flex flex-wrap items-center gap-4 bg-yellow-200 p-2 rounded relative">
                  <div
                    className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
                    onClick={() =>
                      dispatch(
                        removeUserInput({
                          index: i,
                          cluster: opInput.cluster,
                          operator: operator,
                        })
                      )
                    }
                  >
                    <IoCloseSharp />
                  </div>
                  <div>
                    <h2 className="pb-1">
                      {opMulInput.placeholder} ({opMulInput.element})
                    </h2>
                    <Input
                      type="text"
                      placeholder="default value"
                      value={opMulInput.defaultValue}
                      disabled
                    />
                  </div>
                  <div>
                    <h2 className="pb-1">Change Default Value</h2>
                    <Dropdown
                      name="Default Value"
                      width={200}
                      height={40}
                      value={opMulInput.defaultValue}
                      options={opMulInput.options}
                      target="option"
                      click={(value) => {
                        dispatch(
                          updateDefaultValue({
                            index: i,
                            cluster: opInput.cluster,
                            operator: operator,
                            value: value,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              );
            case "date":
              const [dd, mm, yy] = opMulInput.defaultValue.split("-");
              let inputDate = `${yy}-${mm}-${dd}`;
              return (
                <div className="flex flex-wrap items-center gap-4 bg-green-200 p-2 rounded relative">
                  <div
                    className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
                    onClick={() =>
                      dispatch(
                        removeUserInput({
                          index: i,
                          cluster: opInput.cluster,
                          operator: operator,
                        })
                      )
                    }
                  >
                    <IoCloseSharp />
                  </div>
                  <div>
                    <h2 className="pb-1">
                      {opMulInput.placeholder} ({opMulInput.element})
                    </h2>
                    <Input
                      type="date"
                      className="h-[40px]"
                      placeholder="default value"
                      value={inputDate}
                      change={(e) => {
                        const [year, month, day] = e.target.value.split("-");
                        let date = `${day}-${month}-${year}`;
                        dispatch(
                          updateDefaultValue({
                            index: i,
                            cluster: opInput.cluster,
                            operator: operator,
                            value: date,
                          })
                        );
                      }}
                    />
                  </div>
                  <div>
                    <h2 className="pb-1">Reset Date</h2>
                    <button
                      className="w-[100px] h-[40px] bg-cyan-950 text-light-500"
                      onClick={() => {
                        dispatch(
                          updateDefaultValue({
                            index: i,
                            cluster: opInput.cluster,
                            operator: operator,
                            value: "",
                          })
                        );
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              );
          }
        })}
      </div>
    </div>
  );
};

export default EFMulti;
