import { returnEle, returnHFEle, returnEI, consoleFun } from "./functions";
import { TEXT, SYMBOL, CHECKBOX } from "./elements";

const paraProps = {
  updateAlignment: (state, { payload }) => {
    let para;
    if (payload.layoutEle) {
      para = returnHFEle(state, payload.para, payload.layoutEle);
    } else {
      para = returnEle(state, payload.para);
    }
    para.properties.alignment = payload.value;
  },
  addThematicBreak: (state, { payload }) => {
    let para;
    if (payload.layoutEle) {
      para = returnHFEle(state, payload.para, payload.layoutEle);
    } else {
      para = returnEle(state, payload.para);
    }
    if (!payload.value) {
      para.properties.thematicBreak = true;
    } else {
      delete para.properties.thematicBreak;
    }
  },
  addPageBreak: (state, { payload }) => {
    let para;
    if (payload.layoutEle) {
      para = returnHFEle(state, payload.para, payload.layoutEle);
    } else {
      para = returnEle(state, payload.para);
    }
    if (!payload.value) {
      para.properties.pageBreak = true;
    } else {
      delete para.properties.pageBreak;
    }
  },
  updateBorder: (state, { payload }) => {
    let para;
    if (payload.layoutEle) {
      para = returnHFEle(state, payload.para, payload.layoutEle);
    } else {
      para = returnEle(state, payload.para);
    }
    if (!payload.value) {
      para.properties.border = true;
    } else {
      delete para.properties.border;
    }
  },
  updateSpacing: (state, { payload }) => {
    let para;
    if (payload.layoutEle) {
      para = returnHFEle(state, payload.para, payload.layoutEle);
    } else {
      para = returnEle(state, payload.para);
    }
    if (para.properties.spacing) {
      para.properties.spacing[payload.loc] = payload.value;
    } else {
      para.properties.spacing = {
        [payload.loc]: payload.value,
      };
    }
  },
  // Para Numbering
  setParaNumbering: (state, { payload }) => {
    let para;
    if (payload.layoutEle) {
      para = returnHFEle(state, payload.para, payload.layoutEle);
    } else {
      para = returnEle(state, payload.para);
    }
    if (para.properties.numbering) {
      para.properties.numbering[payload.loc] = payload.value;
    } else {
      para.properties.numbering = {
        [payload.loc]: payload.value,
      };
    }
  },
  // Para Indentation
  updateParaIndent: (state, { payload }) => {
    let para;
    if (payload.layoutEle) {
      para = returnHFEle(state, payload.para, payload.layoutEle);
    } else {
      para = returnEle(state, payload.para);
    }
    if (para.properties.indent) {
      para.properties.indent[payload.loc] = payload.value;
    } else {
      para.properties.indent = {
        [payload.loc]: payload.value,
      };
    }
  },
  addTextToPara: (state, { payload }) => {
    let para;
    if (payload.layoutEle) {
      para = returnHFEle(state, payload.para, payload.layoutEle);
    } else {
      para = returnEle(state, payload.para);
    }
    let text = structuredClone(TEXT);
    text.t = "R";
    para.ele.push(text);
  },
  addImageToPara: (state, { payload }) => {
    let para;
    if (payload.layoutEle) {
      para = returnHFEle(state, payload.para, payload.layoutEle);
    } else {
      para = returnEle(state, payload.para);
    }
    let image = { ...payload.image };
    delete image.agency;
    image.type = "image";
    image.properties = {
      transformation: image.transformation,
    };
    delete image._id;
    delete image.transformation;
    para.ele.push(image);
  },
  addSymbolToPara: (state, { payload }) => {
    let para;
    if (payload.layoutEle) {
      para = returnHFEle(state, payload.para, payload.layoutEle);
    } else {
      para = returnEle(state, payload.para);
    }
    let symbol = structuredClone(SYMBOL);
    symbol.char = payload.char;
    symbol.properties.fontSize = "15pt";
    switch (payload.char) {
      case "20DE":
        symbol.properties.font = "Arial MT";
        break;
      default:
        symbol.properties.font = "Trebuchet MS";
        break;
    }
    para.ele.push(symbol);
  },
  addCheckboxToPara: (state, { payload }) => {
    let para;
    if (payload.layoutEle) {
      para = returnHFEle(state, payload.para, payload.layoutEle);
    } else {
      para = returnEle(state, payload.para);
    }
    let checkbox = structuredClone(CHECKBOX);
    para.ele.push(checkbox);
  },
};

export default paraProps;
