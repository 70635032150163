import { returnEle, returnHFEle, returnEI, consoleFun } from "./functions";

const rowProps = {
  setRowHeight: (state, { payload }) => {
    let row;
    if (payload.layoutEle) {
      row = returnHFEle(state, payload.row, payload.layoutEle);
    } else {
      row = returnEle(state, payload.row);
    }
    if (row.properties.height) {
      row.properties.height[payload.loc] = payload.value;
    } else {
      row.properties.height = {
        [payload.loc]: payload.value,
      };
    }
  },
  // RowCell properties
  setRowCellWidth: (state, { payload }) => {
    let row;
    if (payload.layoutEle) {
      row = returnHFEle(state, payload.row, payload.layoutEle);
    } else {
      row = returnEle(state, payload.row);
    }
    if (row.properties.cell.width) {
      row.properties.cell.width[payload.loc] = payload.value;
    } else {
      row.properties.cell.width = {
        [payload.loc]: payload.value,
      };
    }
  },
};

export default rowProps;
