import React from "react";
import TextArea from "../../../../../utils/TextArea";
import { useSelector, useDispatch } from "react-redux";
import { updateFEF } from "../../../../../slices/entryForm";
import { updateFFEF } from "../../../../../slices/formsSlice";

const MTEXTAREA = ({ cluster, ele, operator, i, j }) => {
  const dispatch = useDispatch();
  const { filledEF } = useSelector((state) => state.entryForm);
  const { userInfo } = useSelector((state) => state.auth);
  const { dev, category, name } = useSelector((state) => state.docs);

  console.log(i, j);

  return (
    <div>
      <h2 className="pb-1">{ele.placeholder}</h2>
      <TextArea
        className="h-[100px]"
        placeholder={ele.placeholder}
        value={
          filledEF[operator] && filledEF[operator][cluster]
            ? filledEF[operator][cluster][i][j]
            : ""
        }
        change={(e) => {
          if (filledEF[operator] && filledEF[operator][cluster]) {
            dispatch(
              updateFEF({
                cluster: cluster,
                i,
                j,
                operator: operator,
                name: ele.placeholder,
                value: e.target.value,
              })
            );

            dispatch(
              updateFFEF({
                cluster: cluster,
                i,
                j,
                EF: `${category}$${name}_EF`,
                name: ele.placeholder,
                value: e.target.value,
                operator,
              })
            );
          }
        }}
        click={() => {
          if (dev) {
            let text = `${category}$${name}_EF['${operator}']['${ele.placeholder}']`;
            navigator.clipboard.writeText(text);
          }
        }}
      />
    </div>
  );
};

export default MTEXTAREA;
