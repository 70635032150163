import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../utils/Input";
import { toast } from "react-toastify";
import {
  setDocs,
  addDoc,
  pushDoc,
  addPara,
  addTable,
  setIndexPath,
} from "../../../../slices/docsSlice";
import {
  SECTION,
  TABLE,
  ROW,
  CELL,
  PARA,
  TEXT,
} from "../../../../slices/Docs/elements";
import { useGetNumberingQuery } from "../../../../slices/numberingApiSlice";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { DOMParser } from "@xmldom/xmldom";
import { v4 as uuidv4 } from "uuid";
import { cloneDeepWith } from "lodash";

const DocumentControls = () => {
  const dispatch = useDispatch();
  // const { productInfo } = useSelector((state) => state.product);
  const { docs } = useSelector((state) => state.docs);
  const { agency } = useSelector((state) => state.agency);
  const [docName, setDocName] = useState();
  const [paraName, setParaName] = useState();
  const [tableName, setTableName] = useState();
  const [rows, setRows] = useState();
  const [cols, setCols] = useState();

  const { data: numberingTypes, refetch, isLoading } = useGetNumberingQuery();

  let DOC = {
    XTS: [],
    ele: [],
    properties: {
      creator: agency,
      numbering: {
        config: numberingTypes,
      },
      sec: {},
      table: {
        width: {
          size: "100%",
        },
        margins: {
          left: "0.08in",
          right: "0.08in",
          top: "0.05in",
          bottom: "0.05in",
        },
        borders: {
          left: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
          right: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
          top: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
          bottom: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
        },
      },
      row: {},
      cell: {
        verticalAlign: "center",
        borders: {
          left: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
          right: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
          top: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
          bottom: {
            style: "single",
            color: "000000",
            size: 3,
            space: 0,
          },
        },
      },
      para: {
        alignment: "left",
        spacing: {
          before: "0pt",
          after: "8pt",
        },
      },
      text: {
        font: "Trebuchet MS",
        fontSize: "9.5pt",
      },
    },
    name: "Document",
    type: "doc",
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const content = e.target.result;
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip);
      const xmlContent = zip.files["word/document.xml"].asText();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlContent, "application/xml");

      let newDoc = structuredClone(DOC);
      let newSec = structuredClone(SECTION);

      const setTableWidth = (payload) => {
        if (payload.tableProps.width) {
          payload.tableProps.width[payload.loc] = payload.value;
        } else {
          payload.tableProps.width = {
            [payload.loc]: payload.value,
          };
        }
      };

      const setParaSpacing = (payload) => {
        if (payload.paraProps.spacing) {
          payload.paraProps.spacing[payload.loc] = payload.value;
        } else {
          payload.paraProps.spacing = {
            [payload.loc]: payload.value,
          };
        }
      };

      const createText = (element) => {
        return element.childNodes[0].data;
      };

      const createRun = ({ text, para, cell, row, table }) => {
        let newText = structuredClone(TEXT);
        let runChildren = text.childNodes;
        let child = Object.keys(runChildren)
          .map((E) => {
            if (runChildren[E].localName === "t") {
              return createText(runChildren[E]);
            } else if (runChildren[E].localName === "r") {
              return createRun({
                text: runChildren[E],
                para,
                cell,
                row,
                table,
              });
            }
            return null;
          })
          .filter(Boolean);

        newText.t = child[0];

        let attributes = text.childNodes[0].attributes._ownerElement.childNodes;
        Object.keys(attributes).map((attr) => {
          switch (attributes[attr].nodeName) {
            case "w:rFonts":
              newText.properties.font =
                attributes[attr].attributes[0].nodeValue;
              break;
            case "w:b":
              newText.properties.bold = true;
              break;
            // case "w:sz":
            //   if (attributes[attr].attributes[0].nodeValue > "10") {
            //     newText.properties.fontSize = `${
            //       parseFloat(attributes[attr].attributes[0].nodeValue) / 2
            //     }pt`;
            //   } else {
            //     newText.properties.fontSize = `${attributes[attr].attributes[0].nodeValue}pt`;
            //   }
            //   break;
          }
        });
        if (
          runChildren["0"].localName === "rPr" ||
          runChildren["0"].localName === "t"
        ) {
          if (typeof newText.t === "object") {
            newText = newText.t;
          }
          return newText;
        }
      };

      const createPara = ({ para, cell, row, table, mainEle }) => {
        let newPara = structuredClone(PARA);
        if (mainEle) {
          newPara.user = {};
          newPara.manager = {};
        }
        let paraChildren = para.childNodes;
        const child = Object.keys(paraChildren)
          .map((E) => {
            if (paraChildren[E].localName === "r") {
              return createRun({
                text: paraChildren[E],
                para,
                cell,
                row,
                table,
              });
            }
            return null;
          })
          .filter(Boolean);
        newPara.ele = child;
        let attributes =
          para?.childNodes[0]?.attributes?._ownerElement?.childNodes;
        attributes &&
          Object.keys(attributes)?.map((attr) => {
            switch (attributes[attr].nodeName) {
              case "w:pStyle":
                newPara.properties.style =
                  attributes[attr].attributes[0].nodeValue;
                break;
              case "w:spacing":
                Object.keys(attributes[attr].attributes).map((att) => {
                  switch (attributes[attr].attributes[att].nodeName) {
                    case "w:before":
                      setParaSpacing({
                        loc: "before",
                        paraProps: newPara.properties,
                        value: `${
                          parseFloat(
                            attributes[attr].attributes[att].nodeValue
                          ) / 20
                        }pt`,
                      });
                      break;
                    case "w:after":
                      setParaSpacing({
                        loc: "after",
                        paraProps: newPara.properties,
                        value: `${
                          parseFloat(
                            attributes[attr].attributes[att].nodeValue
                          ) / 20
                        }pt`,
                      });
                      break;
                    case "w:line":
                      setParaSpacing({
                        loc: "line",
                        paraProps: newPara.properties,
                        value: `${
                          parseFloat(
                            attributes[attr].attributes[att].nodeValue
                          ) / 20
                        }pt`,
                      });
                      break;
                    case "w:lineRule":
                      setParaSpacing({
                        loc: "lineRule",
                        paraProps: newPara.properties,
                        value: attributes[attr].attributes[att].nodeValue,
                      });
                      break;
                  }
                });

                break;
              case "w:jc":
                newPara.properties.alignment =
                  attributes[attr].attributes[0].nodeValue;
                break;
            }
          });
        return newPara;
      };

      const calculateRowSpan = ({ RI, CI, table }) => {
        let rowspan = 1;
        let rows = Object.keys(table.childNodes)
          .map((row) => {
            if (table.childNodes[row].nodeName === "w:tr") {
              return table.childNodes[row].childNodes;
            }
            return null;
          })
          .filter(Boolean);

        for (let i = RI + 1; i < rows.length; i++) {
          let cell = rows[i][CI];
          let vMerge = Object.keys(cell.childNodes[0].childNodes)
            .map((node) => {
              if (cell.childNodes[0].childNodes[node].nodeName === "w:vMerge") {
                return cell.childNodes[0].childNodes[node];
              }
              return null;
            })
            .filter(Boolean);
          if (!vMerge) {
            break;
          } else {
            if (vMerge[0]?.attributes[0]?.nodeValue === "continue") {
              rowspan++;
            } else {
              break;
            }
          }
        }
        return rowspan;
      };

      const createCell = ({ cell, CI, row, RI, table }) => {
        let newCell = structuredClone(CELL);
        let cellChildren = cell.childNodes;
        const child = Object.keys(cellChildren)
          .map((E) => {
            if (cellChildren[E].localName === "tbl") {
              return createTable({ table: cellChildren[E] });
            } else if (cellChildren[E].localName === "p") {
              return createPara({ para: cellChildren[E], cell, row, table });
            }
            return null;
          })
          .filter(Boolean);
        let attributes = cell.childNodes[0].attributes._ownerElement.childNodes;
        Object.keys(attributes).map((attr) => {
          switch (attributes[attr].nodeName) {
            case "w:gridSpan":
              Object.keys(attributes[attr].attributes).map((att) => {
                if (attributes[attr].attributes[att].nodeName === "w:val") {
                  newCell.properties.columnSpan = parseFloat(
                    attributes[attr].attributes[att].nodeValue
                  );
                }
              });
              break;
            case "w:vMerge":
              let rowspan;
              if (attributes[attr]?.attributes[0]?.nodeValue === "restart") {
                rowspan = calculateRowSpan({ RI, CI, table });
              }
              Object.keys(attributes[attr].attributes).map((att) => {
                if (attributes[attr].attributes[att].nodeName === "w:val") {
                  newCell.properties.rowSpan = rowspan;
                }
              });
              break;
          }
        });
        newCell.ele = child;
        return newCell;
      };

      const createRow = ({ row, RI, table }) => {
        let newRow = structuredClone(ROW);
        let rowChildren = row.childNodes;
        let cells = Object.keys(rowChildren)
          .map((cell) => {
            if (row.childNodes[cell].nodeName === "w:tc") {
              return row.childNodes[cell];
            }
            return null;
          })
          .filter(Boolean);
        const child = cells
          .map((E, i) => {
            let isMergedCell = Object.keys(E.childNodes)
              .map((cellIndex) => {
                if (E.childNodes[cellIndex].nodeName === "w:tcPr") {
                  return Object.keys(E.childNodes[cellIndex].childNodes)
                    .map((attrIndex) => {
                      if (
                        E.childNodes[cellIndex].childNodes[attrIndex]
                          .nodeName === "w:vMerge"
                      ) {
                        if (
                          E.childNodes[cellIndex]?.childNodes[attrIndex]
                            ?.attributes[0]?.nodeValue === "continue"
                        ) {
                          return "mergedCell";
                        }
                      }
                      return null;
                    })
                    .filter(Boolean);
                }
              })
              .filter(Boolean);
            if (E.localName === "tc" && isMergedCell[0][0] !== "mergedCell") {
              return createCell({
                cell: E,
                CI: i,
                row,
                RI,
                table,
              });
            }
            return null;
          })
          .filter(Boolean);
        newRow.ele = child;
        return newRow;
      };

      const createTable = ({ table, mainEle }) => {
        let newTable = structuredClone(TABLE);
        if (mainEle) {
          newTable.user = {};
          newTable.manager = {};
        }
        let tableChildren = table.childNodes;
        let rows = Object.keys(tableChildren)
          .map((row) => {
            if (table.childNodes[row].nodeName === "w:tr") {
              return table.childNodes[row];
            }
            return null;
          })
          .filter(Boolean);
        const child = rows
          .map((E, i) => {
            if (E.localName === "tr") {
              return createRow({ row: E, RI: i, table });
            }
            return null;
          })
          .filter(Boolean);
        newTable.name = "Table";
        let attributes =
          table.childNodes[0].attributes._ownerElement.childNodes;

        attributes &&
          Object.keys(attributes)?.map((attr) => {
            switch (attributes[attr].nodeName) {
              case "w:tblW":
                Object.keys(attributes[attr].attributes).map((att) => {
                  switch (attributes[attr].attributes[att].name) {
                    case "w:type":
                      setTableWidth({
                        loc: "type",
                        tableProps: newTable.properties,
                        value: attributes[attr].attributes[att].nodeValue,
                      });
                      break;
                    case "w:w":
                      setTableWidth({
                        loc: "size",
                        tableProps: newTable.properties,
                        value: attributes[attr].attributes[att].nodeValue,
                      });
                  }
                });
                break;
              case "w:tblCellMar":
                // console.log();
                break;
            }
          });

        newTable.ele = child;
        return newTable;
      };

      let children = [];
      let sections = [];
      let elements = xmlDoc.documentElement.childNodes[0].childNodes;
      Object.keys(elements).map((element) => {
        if (
          elements[element]?.nextSibling?.localName === "sectPr" ||
          elements[element]?.localName === "sectPr"
        ) {
          newSec.name = "Section";
          newSec.ele = children;
          sections.push(newSec);
          children = [];
          newSec = structuredClone(SECTION);
        } else {
          switch (elements[element].localName) {
            case "p":
              let para = createPara({ para: elements[element], mainEle: true });
              children.push(para);
              break;
            case "tbl":
              let table = createTable({
                table: elements[element],
                mainEle: true,
              });
              children.push(table);
              break;
          }
        }
      });
      newDoc.name = "Document";
      newDoc.ele = sections;
      dispatch(pushDoc(newDoc));
      dispatch(setIndexPath(`${docs.length}_Doc`));
    };

    reader.readAsBinaryString(file);
  };

  return (
    <section className="psm">
      <h1 className="font-mono text-center pb-4">Document Controls</h1>

      <h2 className="font-mono pb-2">Document Name</h2>
      <div className="flex gap-4 pb-6 relative">
        {/* Hidden file input */}
        <input
          type="file"
          accept=".docx"
          onChange={handleFileUpload}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        />
        {/* Custom button */}
        <button className="w-[110px] h-[40px] bg-cyan-950 text-white focus:outline-none">
          Upload DOCX
        </button>
      </div>
      <div className="flex gap-4 pb-6">
        <Input
          className="h-[40px] w-[250px]"
          value={docName}
          change={(e) => setDocName(e.target.value)}
          placeholder="Enter Document Name"
        />
        <button
          className="px-3 py-1 bg-cyan-950 text-light-500"
          onClick={() => {
            if (docName !== "") {
              if (numberingTypes) {
                dispatch(
                  addDoc({
                    name: docName,
                    value: agency,
                    numbering: numberingTypes,
                  })
                );
                dispatch(setIndexPath(`${docs.length}_Doc`));
              } else {
                toast.error("Failed to fetch Numbering Types");
              }
            } else {
              toast.error("Document name cannot be empty");
            }
          }}
        >
          Add Doc
        </button>
      </div>
      {/* <h2 className="font-mono pb-2">Paragraph Name</h2>
      <div className="flex gap-4 pb-6">
        <Input
          className="h-[40px] w-[250px]"
          value={paraName}
          change={(e) => setParaName(e.target.value)}
          placeholder="Enter Paragraph Name"
        />
        <button
          className="px-3 py-1 bg-cyan-950 text-light-500"
          onClick={() => {
            if (paraName !== undefined && docs !== undefined) {
              dispatch(
                addPara({
                  path: RRIP_DOC,
                  name: paraName,
                })
              );
            } else {
              toast.error("Para Name Cannot be empty");
            }
          }}
        >
          Add Para
        </button>
      </div>
      <h2 className="font-mono pb-2">Table Name, Rows and Cols</h2>
      <div className="flex flex-wrap gap-4 pb-6">
        <Input
          className="h-[40px] w-[250px]"
          value={tableName}
          change={(e) => setTableName(e.target.value)}
          placeholder="Enter Table Name"
        />
        <Input
          className="h-[40px] w-[150px]"
          value={rows}
          change={(e) => setRows(e.target.value)}
          placeholder="Enter no.of Rows"
          type="number"
        />
        <Input
          className="h-[40px] w-[150px]"
          value={cols}
          change={(e) => setCols(e.target.value)}
          placeholder="Enter no.of Cols"
          type="number"
        />

        <button
          className="px-3 py-1 bg-cyan-950 text-light-500"
          onClick={() => {
            if (tableName !== undefined && docs !== undefined) {
              dispatch(
                addTable({
                  path: RRIP_DOC,
                  name: tableName,
                  rows: parseFloat(rows),
                  cols: parseFloat(cols),
                })
              );
            } else {
              toast.error("Table Name Cannot be empty");
            }
          }}
        >
          Add Table
        </button>
      </div> */}
    </section>
  );
};

export default DocumentControls;
