import { returnEle, returnHFEle, returnEI, consoleFun } from "./functions";

const tableProps = {
  setTableWidth: (state, { payload }) => {
    let table;
    if (payload.layoutEle) {
      table = returnHFEle(state, payload.table, payload.layoutEle);
    } else {
      table = returnEle(state, payload.table);
    }
    if (table.properties.width) {
      table.properties.width[payload.loc] = payload.value;
    } else {
      table.properties.width = {
        [payload.loc]: payload.value,
      };
    }
  },
  setTableCellMargin: (state, { payload }) => {
    let table;
    if (payload.layoutEle) {
      table = returnHFEle(state, payload.table, payload.layoutEle);
    } else {
      table = returnEle(state, payload.table);
    }
    const doc = returnEle(state, payload.doc);
    if (table.properties.margins) {
      table.properties.margins[payload.loc] = payload.value;
    } else {
      switch (payload.loc) {
        case "left":
          table.properties.margins = {
            marginUnitType: doc.properties.cellMargin.marginUnitType,
            left: payload.value,
            right: doc.properties.cellMargin.right,
            top: doc.properties.cellMargin.top,
            bottom: doc.properties.cellMargin.bottom,
          };
          break;
        case "right":
          table.properties.margins = {
            marginUnitType: doc.properties.cellMargin.marginUnitType,
            left: doc.properties.cellMargin.left,
            right: payload.value,
            top: doc.properties.cellMargin.top,
            bottom: doc.properties.cellMargin.bottom,
          };
          break;
        case "top":
          table.properties.margins = {
            marginUnitType: doc.properties.cellMargin.marginUnitType,
            left: doc.properties.cellMargin.left,
            right: doc.properties.cellMargin.right,
            top: payload.value,
            bottom: doc.properties.cellMargin.bottom,
          };
          break;
        case "bottom":
          table.properties.margins = {
            marginUnitType: doc.properties.cellMargin.marginUnitType,
            left: doc.properties.cellMargin.left,
            right: doc.properties.cellMargin.right,
            top: doc.properties.cellMargin.top,
            bottom: payload.value,
          };
          break;
      }
    }
  },
  updateTableBorder: (state, { payload }) => {
    let table;
    if (payload.layoutEle) {
      table = returnHFEle(state, payload.table, payload.layoutEle);
    } else {
      table = returnEle(state, payload.table);
    }
    table.properties.borders = payload.value === "false" ? false : true;
  },
  setTableAlignment: (state, { payload }) => {
    let table;
    if (payload.layoutEle) {
      table = returnHFEle(state, payload.table, payload.layoutEle);
    } else {
      table = returnEle(state, payload.table);
    }
    table.properties.alignment = payload.value;
  },

  addColumnToTable: (state, { payload }) => {
    let table;
    if (payload.layoutEle) {
      table = returnHFEle(state, payload.table, payload.layoutEle);
    } else {
      table = returnEle(state, payload.table);
    }
    table.ele.map((R) => {
      let c = structuredClone(CELL);
      // Check this and make a work around
      // c.name = table.noc;
      R.ele.push(c);
    });
    // table.noc = table.noc + 1;
  },
  addRowToTable: (state, { payload }) => {
    let table;
    if (payload.layoutEle) {
      table = returnHFEle(state, payload.table, payload.layoutEle);
    } else {
      table = returnEle(state, payload.table);
    }
    let arr = [];
    // Look for a work Around and fix this
    for (let i = 0; i < table.noc; i++) {
      let text = structuredClone(TEXT);
      let para = structuredClone(PARA);
      let c = structuredClone(CELL);
      para.name = c.ele.length;
      para.ele.push(text);
      c.ele.push(para);
      c.name = i;
      arr.push(c);
    }
    let row = structuredClone(ROW);
    row.ele = arr;
    row.name = table.nor;
    table.ele.push(row);
    table.nor = table.nor + 1;
  },
  // TableCell properties
  setTableCellAlignment: (state, { payload }) => {
    let table;
    if (payload.layoutEle) {
      table = returnHFEle(state, payload.table, payload.layoutEle);
    } else {
      table = returnEle(state, payload.table);
    }
    table.properties.cell.verticalAlign = payload.value;
  },
  // TablePara properties
  setTableParaAlignment: (state, { payload }) => {
    let table;
    if (payload.layoutEle) {
      table = returnHFEle(state, payload.table, payload.layoutEle);
    } else {
      table = returnEle(state, payload.table);
    }
    table.properties.para.alignment = payload.value;
  },
  // TableText properties
  setTableTextFont: (state, { payload }) => {
    let table;
    if (payload.layoutEle) {
      table = returnHFEle(state, payload.table, payload.layoutEle);
    } else {
      table = returnEle(state, payload.table);
    }
    table.properties.text.font = payload.value;
  },
  setTableTextFontSize: (state, { payload }) => {
    let table;
    if (payload.layoutEle) {
      table = returnHFEle(state, payload.table, payload.layoutEle);
    } else {
      table = returnEle(state, payload.table);
    }
    table.properties.text.fontSize = payload.value;
  },
};

export default tableProps;
