import { apiSlice } from "../../../slices/apiSlice";
import { BENCHMARK_URL } from "../../../constants";

export const agencyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addBenchmark: builder.mutation({
      query: (data) => ({
        url: `${BENCHMARK_URL}`,
        method: "POST",
        body: {
          benchmark: data,
        },
      }),
    }),
    updateBenchmark: builder.mutation({
      query: (data) => ({
        url: `${BENCHMARK_URL}/updateBenchmark`,
        method: "PUT",
        body: {
          benchmark: data,
        },
      }),
    }),
    getBenchmarks: builder.query({
      query: () => ({
        url: BENCHMARK_URL,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddBenchmarkMutation,
  useGetBenchmarksQuery,
  useUpdateBenchmarkMutation,
} = agencyApiSlice;
