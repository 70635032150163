import { returnEle, returnHFEle, returnEI, consoleFun } from "./functions";

const symbolProps = {
  updateSymbolFont: (state, { payload }) => {
    let symbol;
    if (payload.layoutEle) {
      symbol = returnHFEle(state, payload.symbol, payload.layoutEle);
    } else {
      symbol = returnEle(state, payload.symbol);
    }
    symbol.properties.font = payload.value;
  },
  updateSymbolSize: (state, { payload }) => {
    let symbol;
    if (payload.layoutEle) {
      symbol = returnHFEle(state, payload.symbol, payload.layoutEle);
    } else {
      symbol = returnEle(state, payload.symbol);
    }
    symbol.properties.fontSize = payload.value;
  },
  updateSymbolBold: (state, { payload }) => {
    let symbol;
    if (payload.layoutEle) {
      symbol = returnHFEle(state, payload.symbol, payload.layoutEle);
    } else {
      symbol = returnEle(state, payload.symbol);
    }
    if (!payload.value) {
      symbol.properties.bold = true;
    } else {
      delete symbol.properties.bold;
    }
  },
  updateSymbolItalics: (state, { payload }) => {
    let symbol;
    if (payload.layoutEle) {
      symbol = returnHFEle(state, payload.symbol, payload.layoutEle);
    } else {
      symbol = returnEle(state, payload.symbol);
    }
    if (!payload.value) {
      symbol.properties.italics = true;
    } else {
      delete symbol.properties.italics;
    }
  },
};

export default symbolProps;
