import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  entryForm: undefined,
  filledEF: undefined,
};

const entryFormSlice = createSlice({
  name: "entryForm",
  initialState,
  reducers: {
    setEFandFilledEF: (state, { payload }) => {
      state.entryForm = payload.entryForm;
      state.filledEF = payload.filledEF;
    },
    setEntryForm: (state, { payload }) => {
      state.entryForm = payload;
    },
    setFilledEF: (state, { payload }) => {
      state.filledEF = payload;
    },
    addUserInput: (state, { payload }) => {
      switch (payload.element) {
        case "input":
          if (payload.cluster) {
            state.entryForm[payload.operator][payload.cluster].row.push({
              type: "text",
              placeholder: payload.name,
              defaultValue: "",
              element: payload.element,
            });
            // state.filledEF[payload.operator][payload.cluster][0][0].push("");
          } else {
            state.entryForm[payload.operator][payload.name] = {
              type: "text",
              placeholder: payload.name,
              defaultValue: "",
              element: payload.element,
            };
            // state.filledEF[payload.operator][payload.name] = "";
          }
          break;
        case "textarea":
          if (payload.cluster) {
            state.entryForm[payload.operator][payload.cluster].row.push({
              placeholder: payload.name,
              defaultValue: "",
              element: payload.element,
            });
            // state.filledEF[payload.operator][payload.cluster][
            //   payload.cluster
            // ].push("");
          } else {
            state.entryForm[payload.operator][payload.name] = {
              placeholder: payload.name,
              defaultValue: "",
              element: payload.element,
            };
            // state.filledEF[payload.operator][payload.name] = "";
          }
          break;
        case "dropdown":
          if (payload.cluster) {
            state.entryForm[payload.operator][payload.cluster].row.push({
              placeholder: payload.name,
              options: payload.options,
              defaultValue: payload.options[0],
              element: payload.element,
            });
            // state.filledEF[payload.operator][payload.cluster][
            //   payload.cluster
            // ].push("");
          } else {
            state.entryForm[payload.operator][payload.name] = {
              placeholder: payload.name,
              options: payload.options,
              defaultValue: payload.options[0],
              element: payload.element,
            };
            // state.filledEF[payload.operator][payload.name] = payload.options[0];
          }
          break;
        case "date":
          if (payload.cluster) {
            state.entryForm[payload.operator][payload.cluster].row.push({
              placeholder: payload.name,
              defaultValue: "",
              element: payload.element,
            });
            // state.filledEF[payload.operator][payload.cluster][
            //   payload.cluster
            // ].push("");
          } else {
            state.entryForm[payload.operator][payload.name] = {
              placeholder: payload.name,
              defaultValue: "",
              element: payload.element,
            };
            // state.filledEF[payload.operator][payload.name] = "";
          }
          break;
        case "conditional_input":
          state.entryForm[payload.operator][payload.name] = {
            type: "text",
            placeholder: payload.name,
            defaultValue: "",
            element: payload.element,
            condition: "",
          };
          // state.filledEF[payload.operator][payload.name] = "";
          break;
        case "conditional_textarea":
          state.entryForm[payload.operator][payload.name] = {
            placeholder: payload.name,
            defaultValue: "",
            element: payload.element,
            condition: "",
          };
          // state.filledEF[payload.operator][payload.name] = "";
          break;
        case "conditional_dropdown":
          state.entryForm[payload.operator][payload.name] = {
            placeholder: payload.name,
            options: payload.options,
            defaultValue: payload.options[0],
            element: payload.element,
            condition: "",
          };
          // state.filledEF[payload.operator][payload.name] = payload.options[0];
          break;
        case "conditional_date":
          state.entryForm[payload.operator][payload.name] = {
            placeholder: payload.name,
            element: payload.element,
            condition: "",
          };
          // state.filledEF[payload.operator][payload.name] = "";
          break;
        case "multi":
          state.entryForm[payload.operator][payload.name] = {
            row: [],
            cluster: payload.name,
            element: payload.element,
          };
          // state.filledEF[payload.operator][payload.name] = [[]];
          break;
      }
    },
    updateDefaultValue: (state, { payload }) => {
      const { operator, index, cluster, name, value } = payload;
      if (cluster) {
        state.entryForm[operator][cluster].row[index].defaultValue = value;
      } else {
        state.entryForm[operator][name].defaultValue = value;
      }
    },
    updateInputType: (state, { payload }) => {
      const { operator, index, cluster, name, value } = payload;
      if (cluster) {
        state.entryForm[operator][cluster].row[index].type = value;
      } else {
        state.entryForm[operator][name].type = value;
      }
    },
    updateInputLength: (state, { payload }) => {
      const { operator, index, cluster, name, value } = payload;
      if (cluster) {
        if (state.entryForm[operator][cluster].row[index].maxLength) {
          state.entryForm[operator][cluster].row[index].maxLength = value;
        } else {
          state.entryForm[operator][cluster].row[index].maxLength = value;
        }
      } else {
        if (state.entryForm[operator][name].maxLength) {
          state.entryForm[operator][name].maxLength = value;
        } else {
          state.entryForm[operator][name].maxLength = value;
        }
      }
    },
    updateUserCondition: (state, { payload }) => {
      state.entryForm[payload.operator][payload.name].condition =
        payload.condition;
    },
    removeUserInput: (state, { payload }) => {
      const { cluster, index, operator, name } = payload;
      if (cluster) {
        state.entryForm[operator][cluster].row.splice(index, 1);
      } else {
        delete state.entryForm[operator][name];
      }
    },
    updateNoOfRows: (state, { payload }) => {
      const { operator, cluster, rows } = payload;
      let arr = [];
      let row = state.filledEF[operator][cluster][0];
      for (let i = 0; i < rows; i++) {
        arr.push(row);
      }
      state.filledEF[operator][cluster] = arr;
    },
    updateFEF: (state, { payload }) => {
      const { operator, cluster, name, value, i, j } = payload;
      if (cluster) {
        console.log(state.filledEF[operator][cluster]);
        state.filledEF[operator][cluster][i][j] = value;
      } else {
        state.filledEF[operator][name] = value;
      }
    },
  },
});

export const {
  setEFandFilledEF,
  setEntryForm,
  setFilledEF,
  addUserInput,
  updateDefaultValue,
  updateInputType,
  updateInputLength,
  updateUserCondition,
  removeUserInput,
  updateNoOfRows,
  updateFEF,
  // XT Properties
  addXTInput,
  addXTDropDown,
  updateXTName,
  updateXTDv,
  removeXT,
  // MXT Properties
  addCondition,
  addMXTInput,
  addMXTDropDown,
  updateMXTName,
  updateMXTDv,
  removeMXT,
} = entryFormSlice.actions;

export default entryFormSlice.reducer;
