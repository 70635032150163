import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import Input from "../../../../utils/Input";
import { useDispatch } from "react-redux";
import {
  removeUserInput,
  updateDefaultValue,
} from "../../../../slices/entryForm";

const EFDate = ({ opInput, operator }) => {
  const dispatch = useDispatch();

  const [dd, mm, yy] = opInput.defaultValue.split("-");
  let inputDate = `${yy}-${mm}-${dd}`;

  return (
    <div className="flex flex-wrap items-center gap-4 bg-green-200 p-2 rounded relative">
      <div
        className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
        onClick={() =>
          dispatch(
            removeUserInput({
              name: opInput.placeholder,
              operator: operator,
            })
          )
        }
      >
        <IoCloseSharp />
      </div>
      <div>
        <h2 className="pb-1">
          {opInput.placeholder} ({opInput.element})
        </h2>
        <Input
          type="date"
          className="h-[40px]"
          placeholder="default value"
          value={inputDate}
          change={(e) => {
            const [year, month, day] = e.target.value.split("-");
            let date = `${day}-${month}-${year}`;
            dispatch(
              updateDefaultValue({
                name: opInput.placeholder,
                operator: operator,
                value: date,
              })
            );
          }}
          // value={opInput.defaultValue}
        />
      </div>
      <div>
        <h2 className="pb-1">Reset Date</h2>
        <button
          className="w-[100px] h-[40px] bg-cyan-950 text-light-500"
          onClick={() => {
            dispatch(
              updateDefaultValue({
                name: opInput.placeholder,
                operator: operator,
                value: "",
              })
            );
          }}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default EFDate;
