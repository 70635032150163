import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTestDoc } from "../rahulDocx/Functions";
import { rahulDocx } from "../rahulDocx/RahulDocx";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { setSingleID } from "../../../../slices/internalDependencySlice";
import { setDocument } from "../../../../slices/documentSlice";
import { useUpdateDocumentMutation } from "../../../../slices/documentsApiSlice";
import produceClone from "../../../../components/Lodash";

import RunFunctions from "./RunFunctions";
import {
  setDocId,
  setDocs,
  setIndexPath,
  setName,
  setCategory,
  setRunStatus,
} from "../../../../slices/docsSlice";
import { setEntryForm, setFilledEF } from "../../../../slices/entryForm";
import { getForms, setSingleForm } from "../../../../slices/formsSlice";
import { useNavigate } from "react-router-dom";

const RunControls = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { docId, docs, runStatus, indexPath, dev, category, name } =
    useSelector((state) => state.docs);
  const { entryForm, filledEF } = useSelector((state) => state.entryForm);
  // const { document: mainFile } = useSelector((state) => state.document);
  const { internalDependency } = useSelector(
    (state) => state.internalDependency
  );
  const { forms, userForms } = useSelector((state) => state.forms);
  const { userInfo } = useSelector((state) => state.auth);

  const [updateDocument, { isLoading: loadingUpdate }] =
    useUpdateDocumentMutation();

  const generateDocx = (doc, path) => {
    const testDoc = createTestDoc({ doc, path });
    Packer.toBlob(testDoc).then((blob) => {
      saveAs(blob, `test.docx`);
      console.log("Document created successfully");
    });
  };

  let newForms = produceClone(forms);

  const updateDocumentHandler = async () => {
    const filledEF = {};
    Object.keys(entryForm).forEach((operator) => {
      let op = entryForm[operator];
      filledEF[operator] = {};
      Object.keys(op).forEach((item) => {
        const opInput = op[item];
        if (opInput.cluster) {
          filledEF[operator][item] = [[]];
          opInput.row.forEach((cluValue) => {
            if (cluValue.defaultValue) {
              filledEF[operator][item][0].push(cluValue.defaultValue);
            } else {
              filledEF[operator][item][0].push("");
            }
          });
        } else {
          if (opInput.defaultValue) {
            filledEF[operator][item] = opInput.defaultValue;
          } else {
            filledEF[operator][item] = "";
          }
        }
      });
    });

    try {
      const res = await updateDocument({
        docId,
        docs,
        entryForm,
        filledEF,
        indexPath,
      }).unwrap();
      internalDependency.map((doc, i) => {
        if (
          `${res.document.category}$${res.document.name}` ===
          `${doc.category}$${doc.name}`
        ) {
          dispatch(
            setSingleID({
              i,
              doc: res.document,
            })
          );
          dispatch(setFilledEF(res.document.filledEF));
          if (!dev) {
            newForms[`${res.document.category}$${res.document.name}`] =
              res.document;
            newForms[`${res.document.category}$${res.document.name}_EF`] =
              res.document.filledEF;
            dispatch(
              getForms({
                forms: newForms,
                userForms,
              })
            );
          }
        }
      });

      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target.result);

        const setParaProperties = (E) => {
          E.properties = {
            ...E.properties,
            text: {},
          };
        };

        const setCellProperties = (C) => {
          C.properties = {
            ...C.properties,
            para: {},
            text: {},
          };
          C.ele.map((E) => {
            if (E.type === "para") {
              setParaProperties(E);
            } else if (E.type === "table") {
              setTableProperties(E);
            }
          });
        };

        const setRowProperties = (R) => {
          R.properties = {
            ...R.properties,
            cell: {},
            para: {},
            text: {},
          };
          R.ele.map((C) => {
            setCellProperties(C);
          });
        };

        const setTableProperties = (E) => {
          E.properties = {
            ...E.properties,
            row: {},
            cell: {},
            para: {},
            text: {},
          };
          E.ele.map((R, l) => {
            setRowProperties(R);
          });
        };

        jsonData.document.map((D, i) => {
          D.ele.map((S, j) => {
            S.properties = {
              ...S.properties,
              table: {},
              row: {},
              cell: {},
              para: {},
              text: {},
            };
            S.ele.map((E, k) => {
              if (E.type === "para") {
                setParaProperties(E);
              } else if (E.type === "table") {
                setTableProperties(E);
              }
            });
          });
        });
        dispatch(setDocId(jsonData._id));
        dispatch(setDocs(jsonData.document));
        dispatch(setEntryForm(jsonData.entryForm));
        dispatch(setIndexPath(jsonData.indexPath));
        dispatch(setName(jsonData.name));
        dispatch(setCategory(jsonData.category));
        dispatch(setRunStatus(jsonData.runStatus));
        toast.success("Hurrey");
        // navigate(`/manager/${value}/${jsonData.indexPath}`);
        // dispatch(setItems(jsonData.items)); // Adjust based on your JSON structure
      } catch (error) {
        toast.error("Invalid JSON file:" + error);
      }
    };

    reader.onerror = (error) => {
      toast.error("Error reading file:" + error);
    };

    reader.readAsText(file);
  };

  const generateReport = (report, CI, DI) => {
    let doc = produceClone(report);
    doc.ele.map((sec, i1) => {
      sec.ele.forEach((E, i2) => {
        let USER = filledEF.user;
        let MANAGER = filledEF.manager;
        if (E.jsFun) {
          console.log(`${i1}_${i2}`);
          let func = eval("(" + E.jsFun + ")");
          func(E, E.ele, USER, MANAGER, CI);
        }
      });
    });
    generateDocx(doc, `${CI}_${DI}`);
  };

  return (
    <div className="min-h-[50px] border-t w-full text-light-500 px-3 py-2 flex flex-wrap justify-between items-center gap-5">
      <div className="flex gap-4">
        {userInfo.role !== "Operator" ? (
          <>
            <button
              className="bg-info-500 px-2 py-1"
              onClick={() => {
                let doc = forms[`${category}$${name}`];
                var dataStr =
                  "data:text/json;charset=utf-8," +
                  encodeURIComponent(
                    JSON.stringify({
                      ...doc,
                      document: docs,
                      indexPath,
                    })
                  );
                var dlAnchorElem =
                  document.getElementById("downloadAnchorElem");
                dlAnchorElem.setAttribute("href", dataStr);
                dlAnchorElem.setAttribute("download", "CEF.json");
                dlAnchorElem.click();
              }}
            >
              Download JSON
              <a id="downloadAnchorElem"></a>
            </button>
            <div className="relative">
              {/* Hidden file input */}
              <input
                type="file"
                accept=".json"
                onChange={handleFileChange}
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              />
              {/* Custom button */}
              <button className="w-[110px] h-[40px] bg-info-500 text-white focus:outline-none">
                Upload JSON
              </button>
            </div>
          </>
        ) : null}

        {!runStatus ? (
          <button
            className="bg-primary-500 px-2 py-1"
            onClick={() => updateDocumentHandler()}
          >
            Update Doc
          </button>
        ) : null}

        {userInfo.role !== "Operator"
          ? docs.map((doc, di) => {
              if (!doc.bulkFiles) {
                return (
                  <button
                    onClick={() => generateDocx(doc, `${di}`)}
                    className="bg-warning-500 px-2 py-1 text-dark-900"
                  >
                    {doc.name}
                  </button>
                );
              } else if (doc.cluster) {
                return (
                  <div className="flex gap-4">
                    {eval(doc.cluster).map((R, i) => {
                      return (
                        <button
                          className="bg-green-500 px-2 py-1 text-dark-900"
                          onClick={() => {
                            generateReport(doc, i, di);
                            // generateDocx(doc, `${i}_${di}`);
                          }}
                        >
                          {i + 1}
                        </button>
                      );
                    })}{" "}
                  </div>
                );
              }
            })
          : null}
      </div>
      <RunFunctions />
    </div>
  );
};

export default RunControls;
