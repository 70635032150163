import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userForms: [],
  forms: {},
  temp: undefined,
};

const consoleFun = (value) => {
  console.log(JSON.parse(JSON.stringify(value)));
};

const formsSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    resetForms: (state, { payload }) => {
      state.userForms = undefined;
      state.forms = undefined;
    },
    // updateXT: (state, { payload }) => {
    //   const { EF, doc, sec, ele, xt, role, value } = payload;
    //   state.forms[EF][doc].ele[sec].ele[ele][role].XT[xt].name = value;
    // },
    // updateMXT: (state, { payload }) => {
    //   const { EF, doc, sec, ele, row, mxt, role, value } = payload;
    //   state.forms[EF][doc].ele[sec].ele[ele][role].MXT[row][mxt].name = value;
    // },
    // updateCondition: (state, { payload }) => {
    //   const { EF, doc, sec, ele, role, value } = payload;
    //   state.forms[EF][doc].ele[sec].ele[ele][role].condition = value;

    //   let arr = [];
    //   let MXT = state.forms[EF][doc].ele[sec].ele[ele][role].MXT[0];
    //   for (let i = 0; i < value; i++) {
    //     arr.push(MXT);
    //   }
    //   state.forms[EF][doc].ele[sec].ele[ele][role].MXT = arr;
    // },
    updateFNoOfRows: (state, { payload }) => {
      const { EF, operator, cluster, rows } = payload;
      let arr = [];
      let row = state.forms[EF][operator][cluster][0];
      for (let i = 0; i < rows; i++) {
        arr.push(row);
      }
      state.forms[EF][operator][cluster] = arr;
    },
    updateFFEF: (state, { payload }) => {
      const { EF, operator, cluster, name, value, i, j } = payload;
      if (cluster) {
        state.forms[EF][operator][cluster][i][j] = value;
      } else {
        state.forms[EF][operator][name] = value;
      }
    },
    setTemp: (state, { payload }) => {
      state.temp = payload;
    },
    getForms: (state, { payload }) => {
      state.userForms = payload.userForms;
      state.forms = payload.forms;
    },
    setFormsForManager: (state, { payload }) => {
      let newUserForms = state.userForms ? [...state.userForms] : [];
      let newForms = { ...state.forms };
      newUserForms.push({ name: `${payload.category}$${payload.name}` });
      newUserForms.push({ name: `${payload.category}$${payload.name}_EF` });
      newForms[`${payload.category}$${payload.name}`] = payload;
      newForms[`${payload.category}$${payload.name}_EF`] = payload.filledEF;
      payload.externalDependency.map((dep) => {
        newForms[dep.name] = null;
        const exists = newUserForms.some((obj) => obj["name"] === dep.name);
        if (!exists) {
          newUserForms.push({
            name: dep.name,
            role: dep.role,
          });
        }
      });
      state.userForms = newUserForms;
      state.forms = newForms;
    },
    setFormsForUser: (state, { payload }) => {
      let newUserForms = state.userForms ? [...state.userForms] : [];
      let newForms = { ...state.forms };
      newUserForms.push({ name: `${payload.category}$${payload.name}_EF` });
      newForms[`${payload.category}$${payload.name}`] = payload.entryForm;
      newForms[`${payload.category}$${payload.name}_EF`] = payload.filledEF;
      payload.externalDependency.map((dep) => {
        newForms[dep.name] = null;
        const exists = newUserForms.some((obj) => obj["name"] === dep.name);
        if (!exists) {
          newUserForms.push({
            name: dep.name,
            role: dep.role,
          });
        }
      });
      state.userForms = newUserForms;
      state.forms = newForms;
    },
    setFormsIndexPath: (state, { payload }) => {
      state.forms[`${payload.category}$${payload.name}`].indexPath =
        payload.indexPath;
    },
    setSingleUserForm: (state, { payload }) => {
      const newUserForms = [...state.userForms];
      newUserForms.push(payload);
      state.userForms = newUserForms;
    },
    setSingleForm: (state, { payload }) => {
      state.forms[payload.name] = payload.value;
    },
    setFormDocument: (state, { payload }) => {
      state.forms[payload.name].document = payload.value;
    },
    setFormsRunStatus: (state, { payload }) => {
      state.forms[payload.name].runStatus = payload.value;
    },
    updateLvlInput: (state, { payload }) => {
      state.forms.Level[payload.i][payload.j] = payload.value;
    },
  },
});

export const {
  resetForms,
  getForms,
  updateFNoOfRows,
  updateFFEF,
  // updateXT,
  // updateCondition,
  // updateMXT,
  setTemp,
  setFormsForManager,
  setFormsForUser,
  setFormsIndexPath,
  setSingleUserForm,
  setSingleForm,
  setFormDocument,
  setFormsRunStatus,
  // Remove later
  updateLvlInput,
} = formsSlice.actions;

export default formsSlice.reducer;
