import React, { useState } from "react";
import Input from "../../../utils/Input";
import AccountType from "./AccountType";
import AccountValue from "./AccountValue";

const CibilAccount = ({ item, row, obj, i, j }) => {
  const [account, setAccount] = useState(item);
  return (
    <>
      {j === 0 ? (
        <div className="flex flex-wrap gap-4 bg-yellow-200 p-2 rounded">
          {item.map((R, k) => {
            if (k === 0) {
              return (
                <div className="flex flex-wrap gap-4 bg-purple-200 p-2 rounded">
                  {R.map((C, l) => {
                    return (
                      <AccountType C={C} obj={obj} i={i} j={j} k={k} l={l} />
                    );
                  })}
                </div>
              );
            } else {
              return <AccountValue R={R} obj={obj} i={i} j={j} k={k} />;
            }
          })}
        </div>
      ) : (
        <Input
          type="text"
          value={account}
          click={() => {
            let val = `Cibil[${i}].${obj}[${j}]`;
            navigator.clipboard.writeText(val);
          }}
          change={(e) => setAccount(e.target.value)}
        />
      )}
    </>
  );
};

export default CibilAccount;
