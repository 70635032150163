import React, { useState } from "react";
import Input from "../../../utils/Input";
import CibilAccount from "./CibilAccount";
import Remarks from "./Remarks";
import PersonalDetails from "./PersonalDetails";

const CibilStructure = ({ cibil }) => {
  return (
    <div className="mt-2 psm flex flex-col gap-4">
      {cibil.map((row, i) => {
        return (
          <div className="flex flex-wrap gap-4 bg-blue-200 rounded p-2">
            {Object.keys(row).map((obj) => {
              if (obj === "accounts") {
                return (
                  <div className="flex flex-wrap gap-4 bg-green-200 p-2 rounded">
                    {row[obj].map((item, j) => {
                      return (
                        <CibilAccount
                          item={item}
                          row={row}
                          obj={obj}
                          i={i}
                          j={j}
                        />
                      );
                    })}
                  </div>
                );
              } else if (obj === "creditRemarks" || obj === "personalRemarks") {
                return (
                  <div className="flex flex-wrap gap-4 bg-red-200 p-2 rounded">
                    {row[obj].map((item, j) => {
                      return <Remarks item={item} i={i} j={j} obj={obj} />;
                    })}
                  </div>
                );
              } else {
                return (
                  <div>
                    <h2 className="pb-1">{obj.toUpperCase()}</h2>
                    <PersonalDetails row={row} obj={obj} i={i} />
                  </div>
                );
              }
            })}
          </div>
        );
      })}
    </div>
  );
};

export default CibilStructure;
