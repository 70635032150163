import React from "react";
import FInput from "./inputs/input";
import FTextarea from "./inputs/textarea";
import FDropdown from "./inputs/dropdown";
import FDate from "./inputs/date";
import FMulti from "./inputs/multi";
import CFInput from "./inputs/cinput";
import CFTextarea from "./inputs/ctextarea";
import CFDropdown from "./inputs/cdropdown";
import CFDate from "./inputs/cdate";
import CFMulti from "./inputs/cmulti";
import { useSelector } from "react-redux";

const Fields = ({ op, operator }) => {
  // Do not remove forms
  const { forms } = useSelector((state) => state.forms);
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <div>
      {userInfo.role === "Manager" ? (
        <h2 className="p-1 px-10 mb-2 bg-cyan-950 text-light-500 font-bold">
          {operator.toUpperCase()}
        </h2>
      ) : null}
      <div className="flex flex-wrap gap-4 px-9">
        {Object.keys(op).length > 0
          ? Object.keys(op).map((key) => {
              const ele = op[key];
              switch (ele.element) {
                case "input":
                  return <FInput key={key} ele={ele} operator={operator} />;
                case "textarea":
                  return <FTextarea key={key} ele={ele} operator={operator} />;
                case "dropdown":
                  return <FDropdown key={key} ele={ele} operator={operator} />;
                case "date":
                  return <FDate key={key} ele={ele} operator={operator} />;
                case "conditional_input":
                  if (ele.condition && eval(ele.condition)) {
                    return <CFInput key={key} ele={ele} operator={operator} />;
                  }
                  break;
                case "conditional_textarea":
                  if (ele.condition && eval(ele.condition)) {
                    return (
                      <CFTextarea key={key} ele={ele} operator={operator} />
                    );
                  }
                  break;
                case "conditional_dropdown":
                  if (ele.condition && eval(ele.condition)) {
                    return (
                      <CFDropdown key={key} ele={ele} operator={operator} />
                    );
                  }
                  break;
                case "conditional_date":
                  if (ele.condition && eval(ele.condition)) {
                    return <CFDate key={key} ele={ele} operator={operator} />;
                  }
                  break;
                case "multi":
                  return <FMulti key={key} ele={ele} operator={operator} />;
                case "conditional_multi":
                  if (ele.condition && eval(ele.condition)) {
                    return <CFMulti key={key} ele={ele} operator={operator} />;
                  }
                  break;
                default:
                  return null;
              }
            })
          : null}
      </div>
    </div>
  );
};

export default Fields;
