import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSymbolToPara } from "../../../../../../slices/docsSlice";
import Symbols from "../../../../../../utils/Symbols";
import { toast } from "react-toastify";

const AddSymbol = ({ RRIP_P, SIPHF }) => {
  const { symbols } = useSelector((state) => state.options);
  const dispatch = useDispatch();
  const [symbol, setSymbol] = useState();

  return (
    <div className="flex gap-4">
      <div>
        <h2>Select Char</h2>
        <Symbols
          width={150}
          height={40}
          options={symbols}
          value={symbol}
          target="option.char"
          name="char"
          click={(value) => {
            let symText = value.char.codePointAt(0).toString(16).toUpperCase();
            setSymbol(symText);
          }}
        />
      </div>
      <div>
        <h2 className="pb-1">Add Symbol</h2>
        <button
          className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
          onClick={() => {
            if (symbol) {
              dispatch(
                addSymbolToPara({
                  char: symbol,
                  para: RRIP_P,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            } else {
              toast.error("Select a symbol");
            }
          }}
        >
          Symbol
        </button>
      </div>
    </div>
  );
};

export default AddSymbol;
