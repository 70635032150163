import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isValidVariableName } from "../shared/InputCheck";
import { setCategories } from "../../slices/categories";
import { setDocument } from "../../slices/documentSlice";
import {
  setDocs,
  setIndexPath,
  setName,
  setCategory,
  setDocId,
} from "../../slices/docsSlice";
import { setEFandFilledEF } from "../../slices/entryForm";
import { getForms, setFormsForManager } from "../../slices/formsSlice";
import {
  getListIDS,
  setListIDS,
  setInternalDependency,
} from "../../slices/internalDependencySlice";

import Loader from "../../components/Loader";
import Input from "../../utils/Input";
import Dropdown from "../../utils/Dropdown";
import Table from "../../utils/Table/Table";
import TableRow from "../../utils/Table/TableRow";
import TableCell from "../../utils/Table/TableCell";

import {
  useGetDocumentsQuery,
  useCreateDocumentMutation,
  useUpdateDocStatusMutation,
  useDeleteDocumentMutation,
  useGetDocumentMutation,
} from "../../slices/documentsApiSlice";
import {
  useAddCategoryMutation,
  useGetCategoriesQuery,
} from "../../slices/categoryApiSlice";

const CreateDocuments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { keyword, category: paramsCategory, params } = useParams();

  const [createCategory, setCreateCategory] = useState("");
  const [docCategory, setDocCategory] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [docName, setDocName] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);

  const { agency } = useSelector((state) => state.agency);
  const { document } = useSelector((state) => state.document);
  const { forms, userForms } = useSelector((state) => state.forms);
  const { docs, indexPath, category } = useSelector((state) => state.docs);
  const { listIDS, internalDependency } = useSelector(
    (state) => state.internalDependency
  );

  const [createDocument, {}] = useCreateDocumentMutation();
  const [updateDocStatus, {}] = useUpdateDocStatusMutation();
  const [deleteDocument, {}] = useDeleteDocumentMutation();
  const [getDocument, {}] = useGetDocumentMutation();
  const {
    data: documents,
    refetch: refetchDocuments,
    isLoading: documentsLoading,
  } = useGetDocumentsQuery({
    keyword: searchKeyword,
    category: searchCategory,
    params,
  });

  const [addCategory, {}] = useAddCategoryMutation();
  const {
    data: categories,
    refetch: refetchCategories,
    isLoading: categoriesLoading,
  } = useGetCategoriesQuery();

  const addCategoryHandler = async () => {
    if (isValidVariableName(createCategory)) {
      try {
        const res = await addCategory({
          category: createCategory,
          agency,
        }).unwrap();
        toast.success(res.message);
        setCreateCategory("");
        refetchCategories();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    } else {
      toast.error(
        "Invalid variable name. It must start with a letter, $, or _ and can contain letters, numbers, $, or _."
      );
    }
  };

  const createDocumentHandler = async () => {
    if (docName === "" || docCategory === "") {
      toast.error("Category and DocName are needed.");
    } else {
      if (isValidVariableName(docName)) {
        try {
          const res = await createDocument({
            name: docName,
            agency,
            category: docCategory,
          }).unwrap();
          toast.success(res.message);
          setDocName("");
          refetchDocuments();
        } catch (err) {
          toast.error(err?.data?.message || err.error);
        }
      } else {
        toast.error(
          "Invalid variable name. It must start with a letter, $, or _ and can contain letters, numbers, $, or _."
        );
      }
    }
  };

  const docStatusHandler = async (status, docId) => {
    try {
      const res = await updateDocStatus({ status, docId }).unwrap();
      toast.success(res.message);
      refetchDocuments();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const deleteDocumentHandler = async (docId) => {
    try {
      const res = await deleteDocument(docId).unwrap();
      toast.success(res.message);
      refetchDocuments();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  // const getDocumentHandler = async (record) => {
  //   try {
  //     const res = await getDocument({ document: record }).unwrap();
  //     dispatch(
  //       getForms({
  //         userForms: res.userForms,
  //         forms: res.forms,
  //       })
  //     );
  //     dispatch(setDocument(record));
  //     dispatch(setDocId(record._id));
  //     dispatch(setDocs(record.document));
  //     dispatch(setName(record.name));
  //     dispatch(setCategory(record.category));
  //     dispatch(setEFandFilledEF(record.filledEF));
  //     dispatch(setIndexPath(record.indexPath));
  //     dispatch(getListIDS(res.listIDS));
  //     dispatch(setInternalDependency([]));
  //   } catch (err) {
  //     toast.error(err?.data?.message || err.error);
  //   }
  // };

  useEffect(() => {
    if (document && forms && userForms && category) {
      setIsReadyToNavigate(true);
    }
  }, [document, forms, userForms, category]);

  useEffect(() => {
    if (
      document &&
      document.internalDependency &&
      Object.keys(document.internalDependency).length > 0
    ) {
      navigate("/manager/InternalDep");
    }
  }, [document]);

  useEffect(() => {
    if (isReadyToNavigate && document && docs) {
      if (document.indexPath === "") {
        navigate(`/manager/${document.category}/DC`);
      } else {
        console.log(document.indexPath);
        let RRIP = document.indexPath.split("/");
        let lastIndex = RRIP[RRIP.length - 1];
        let SLI = lastIndex.split("_");
        let path = `/manager/${document.category}/${document.indexPath}`;
        let NIP = RRIP.map((index) => index.split("_")[0]).join("_");
        switch (SLI[1]) {
          case "I":
            if (RRIP.length === 4) {
              navigate(`${path}/SP/PP/IP`);
            } else {
              navigate(`${path}/SP/TBP/TR/TC/PP/IP`);
            }
            break;
          case "T":
            if (RRIP.length === 4) {
              navigate(`${path}/SP/PP/TP/0#${NIP}`);
            } else {
              navigate(`${path}/SP/TBP/TR/TC/PP/TP/0#${NIP}`);
            }
            break;
          case "P":
            if (RRIP.length === 3) {
              navigate(`${path}/SP/PP`);
            } else {
              navigate(`${path}/SP/TBP/TR/TC/PP`);
            }
            break;
          case "TC":
            navigate(`${path}/SP/TBP/TR/TC`);
            break;
          case "TR":
            navigate(`${path}/SP/TBP/TR`);
            break;
          case "TB":
            navigate(`${path}/SP/TBP`);
            break;
          case "S":
            navigate(`${path}/SP`);
            break;
          case "Doc":
            navigate(`${path}/DP`);
            break;
        }
      }
    } else {
    }
  }, [isReadyToNavigate, document, docs]);

  useEffect(() => {
    dispatch(setCategories(categories));
  }, [categories]);

  return (
    <div className="psm flex gap-2">
      <div className="wh-100">
        <div className="flex gap-10">
          <div className="p-4 morphous bg-color">
            <h2 className="font-mono pb-2">Add Category</h2>
            <div className="flex gap-4">
              <Input
                id="category"
                value={createCategory}
                placeholder="Enter Category"
                type="text"
                change={(e) => setCreateCategory(e.target.value)}
              />
              <button
                className="px-5 py-3 bg-cyan-950 text-light-500 font-mono"
                onClick={addCategoryHandler}
              >
                Add Category
              </button>
            </div>
          </div>
          {!categoriesLoading ? (
            <div className="p-4 morphous bg-color">
              <h2 className="font-mono pb-2">
                Select Category and enter Doc Name
              </h2>
              <div className="cursor-pointer flex gap-4">
                <Dropdown
                  options={categories}
                  name="Category"
                  value={docCategory}
                  target="option.category"
                  click={(value) => {
                    setDocCategory(value.category);
                  }}
                  style={
                    docCategory !== ""
                      ? { backgroundColor: "#035afc", color: "white" }
                      : null
                  }
                />
                <Input
                  type="text"
                  placeholder="Enter Doc Name"
                  value={docName}
                  change={(e) => setDocName(e.target.value)}
                />
                <button
                  className="px-5 py-3 bg-cyan-950 text-light-500 font-mono"
                  onClick={createDocumentHandler}
                >
                  Create Document
                </button>
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </div>
        {documentsLoading ? (
          <Loader />
        ) : (
          <div className="mt-10 p-4 morphous bg-color">
            <h2 className="font-mono pb-2">Search Document</h2>
            <div className="flex justify-between">
              <div className="flex gap-4">
                <Input
                  type="text"
                  placeholder="Enter Doc Name"
                  value={searchKeyword}
                  change={(e) => setSearchKeyword(e.target.value)}
                />
                <Dropdown
                  options={categories}
                  name="Category"
                  value={searchCategory}
                  target="option.category"
                  click={(value) => {
                    setSearchCategory(value.category);
                  }}
                  style={
                    searchCategory !== ""
                      ? { backgroundColor: "#035afc", color: "white" }
                      : null
                  }
                />
              </div>
              <div>
                <button
                  className="px-5 py-3 bg-cyan-950 text-light-500 font-mono"
                  onClick={refetchDocuments}
                >
                  Refresh
                </button>
              </div>
            </div>
            <h2 className="font-mono pb-2 pt-10">
              Create, Modify and Update Documents
            </h2>
            <Table style={{ minHeight: "1500px" }}>
              <TableRow isHeader={true}>
                <TableCell isHeader={true}>S.No</TableCell>
                <TableCell isHeader={true}>Name</TableCell>
                <TableCell isHeader={true}>Category</TableCell>
                <TableCell isHeader={true}>Status</TableCell>
                <TableCell isHeader={true}>Internal Dependencies</TableCell>
                <TableCell isHeader={true}>External Dependencies</TableCell>
                <TableCell isHeader={true}>AD</TableCell>
                <TableCell isHeader={true}>Edit</TableCell>
                <TableCell isHeader={true} className={`w-[44px] text-center`}>
                  X
                </TableCell>
              </TableRow>
              {documents?.documents?.map((doc, sno) => {
                console.log(doc);
                return (
                  <TableRow
                    key={sno}
                    className={(sno + 1) % 2 === 0 ? "bg-gray-100" : null}
                  >
                    <TableCell>{sno + 1}</TableCell>
                    <TableCell>{doc.name}</TableCell>
                    <TableCell>{doc.category}</TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      {/* {doc.status} */}
                      <Dropdown
                        options={[
                          "Completed",
                          "Partially Completed",
                          "Not Completed",
                        ]}
                        value={doc.status}
                        click={(value) => {
                          docStatusHandler(value, doc._id);
                        }}
                        target="option"
                        style={
                          doc.status === "Completed"
                            ? { backgroundColor: "#28a745" }
                            : doc.status === "Partially Completed"
                            ? { backgroundColor: "#fcba03" }
                            : { border: "none" }
                        }
                        width={150}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      {doc.internalDependency ? (
                        <div className="flex gap-2">
                          {Object.keys(doc.internalDependency).map(
                            (dependency, i) => {
                              return (
                                <div key={i} className="bg-cyan-950 text-white">
                                  <h2 className="font-mono text-center bg-primary-500 p-2">
                                    {dependency}
                                  </h2>
                                  {doc.internalDependency[dependency].map(
                                    (dep, j) => {
                                      return (
                                        <div
                                          key={`${i}_${j}`}
                                          className="px-2 py-1"
                                        >
                                          <p>{dep.name}</p>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : null}
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <div className="flex gap-2">
                        {doc?.externalDependency.map((dependency, i) => {
                          return (
                            <div key={i} className="bg-cyan-950 text-white p-2">
                              <p>{dependency.name}</p>
                              <p>{dependency.role}</p>
                            </div>
                          );
                        })}
                      </div>
                    </TableCell>
                    <TableCell
                      className="text-center bg-warning-500 cursor-pointer"
                      click={() => {
                        dispatch(setDocument(doc));
                        navigate("/manager/AD");
                      }}
                    >
                      AD
                    </TableCell>
                    <TableCell
                      className="bg-primary-500 text-white cursor-pointer text-center"
                      click={() => {
                        if (doc.internalDependency) {
                          dispatch(setDocument(doc));
                          dispatch(setDocs(doc.document));
                          dispatch(
                            setEFandFilledEF({
                              entryForm: doc.entryForm,
                              filledEF: doc.filledEF,
                            })
                          );
                          dispatch(setDocId(doc._id));
                          dispatch(setName(doc.name));
                          dispatch(setCategory(doc.category));
                          dispatch(setIndexPath(doc.indexPath));
                          dispatch(
                            setListIDS({
                              document: `${doc.category}$${doc.name}`,
                              entryForm: `${doc.category}$${doc.name}_EF`,
                              category: doc.category,
                              name: doc.name,
                            })
                          );
                          dispatch(setInternalDependency(doc));
                          dispatch(setFormsForManager(doc));
                          // navigate("/manager/InternalDep");
                        } else {
                          console.log(doc);
                          dispatch(setDocument(doc));
                          dispatch(setDocs(doc.document));
                          dispatch(
                            setEFandFilledEF({
                              entryForm: doc.entryForm,
                              filledEF: doc.filledEF,
                            })
                          );
                          dispatch(setDocId(doc._id));
                          dispatch(setName(doc.name));
                          dispatch(setCategory(doc.category));
                          dispatch(setIndexPath(doc.indexPath));
                          dispatch(setFormsForManager(doc));
                          dispatch(
                            setListIDS({
                              document: `${doc.category}$${doc.name}`,
                              entryForm: `${doc.category}$${doc.name}_EF`,
                              category: doc.category,
                              name: doc.name,
                            })
                          );
                          dispatch(setInternalDependency(doc));
                          // getDocumentHandler(doc);
                          // navigate(`/manager/${doc.category}/DC`);
                        }
                      }}
                    >
                      Edit
                    </TableCell>

                    <TableCell
                      className={`cursor-pointer bg-danger-500 font-bold text-light-500 text-center`}
                      click={() => {
                        if (
                          confirm("Do you really want to delete the document?")
                        ) {
                          deleteDocumentHandler(doc._id);
                        }
                      }}
                    >
                      X
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateDocuments;
