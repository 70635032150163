import React from "react";
import Dropdown from "../../../../../utils/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { updateFEF } from "../../../../../slices/entryForm";
import { updateFFEF } from "../../../../../slices/formsSlice";

const MDROPDOWN = ({ cluster, ele, operator, i, j }) => {
  const dispatch = useDispatch();
  const { filledEF } = useSelector((state) => state.entryForm);
  const { userInfo } = useSelector((state) => state.auth);
  const { dev, category, name } = useSelector((state) => state.docs);

  return (
    <div>
      <h2 className="pb-1">{ele.placeholder}</h2>
      <Dropdown
        style={{ backgroundColor: "#f8f9fa" }}
        height={40}
        name={ele.placeholder}
        value={
          filledEF[operator] && filledEF[operator][cluster]
            ? filledEF[operator][cluster][i][j]
            : ""
        }
        options={ele.options}
        target="option"
        click={(value) => {
          let text = `${category}$${name}_EF['${operator}']['${ele.placeholder}']`;
          navigator.clipboard.writeText(text);

          dispatch(
            updateFEF({
              cluster: cluster,
              i,
              j,
              operator: operator,
              name: ele.placeholder,
              value: value,
            })
          );
          dispatch(
            updateFFEF({
              cluster: cluster,
              i,
              j,
              EF: `${category}$${name}_EF`,
              name: ele.placeholder,
              value: value,
              operator,
            })
          );
        }}
      />
    </div>
  );
};

export default MDROPDOWN;
