import { consoleFun, returnEle, returnHFEle } from "./functions";

const basicProps = {
  setDocId: (state, { payload }) => {
    state.docId = payload;
  },
  setDocs: (state, { payload }) => {
    state.docs = payload;
  },
  setName: (state, { payload }) => {
    state.name = payload;
  },
  setCategory: (state, { payload }) => {
    state.category = payload;
  },
  setRunStatus: (state, { payload }) => {
    state.runStatus = payload;
  },
  setDev: (state, { payload }) => {
    state.dev = !state.dev;
  },
  setMinimal: (state, { payload }) => {
    state.minimal = !state.minimal;
  },
  updateEleName: (state, { payload }) => {
    let ele;
    if (payload.layoutEle) {
      ele = returnHFEle(state, payload.ele, payload.layoutEle);
    } else {
      ele = returnEle(state, payload.ele);
    }
    consoleFun(ele);
    ele.name = payload.value;
  },
};

export default basicProps;
