import { returnEle, returnHFEle, returnEI, consoleFun } from "./functions";

const checkboxProps = {
  updateChecked: (state, { payload }) => {
    let checkbox;
    if (payload.layoutEle) {
      checkbox = returnHFEle(state, payload.checkbox, payload.layoutEle);
    } else {
      checkbox = returnEle(state, payload.checkbox);
    }
    checkbox.properties.checked = !checkbox.properties.checked;
  },
  updateCheckedState: (state, { payload }) => {
    let checkbox;
    if (payload.layoutEle) {
      checkbox = returnHFEle(state, payload.checkbox, payload.layoutEle);
    } else {
      checkbox = returnEle(state, payload.checkbox);
    }
    checkbox.properties.checkedState.value = payload.value;
  },
  updateUncheckedState: (state, { payload }) => {
    let checkbox;
    if (payload.layoutEle) {
      checkbox = returnHFEle(state, payload.checkbox, payload.layoutEle);
    } else {
      checkbox = returnEle(state, payload.checkbox);
    }
    checkbox.properties.uncheckedState.value = payload.value;
  },
};

export default checkboxProps;
