import { returnEle, returnHFEle, returnEI, consoleFun } from "./functions";
import { SECTION } from "./elements";

const documentProps = {
  // Doc Properties
  addSection: (state, { payload }) => {
    const ele = returnEle(state, payload.path);
    const section = structuredClone(SECTION);
    section.name = payload.name ? payload.name : Date.now();
    ele.ele.push(section);
  },
  setNumbering: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    if (doc.properties.numbering) {
      doc.properties.numbering.config = payload.value;
    } else {
      doc.properties["numbering"] = {
        config: payload.value,
      };
    }
  },
  setBulkFiles: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    if (doc.bulkFiles) {
      delete doc.bulkFiles;
      delete doc.jsFun;
      delete doc.cluster;
    } else {
      doc.bulkFiles = true;
      doc.cluster = "";
      doc.jsFun = `function(D) {
  
}`;
    }
  },
  // DocSec Properties

  // DocTab Properties
  setDocTableWidth: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.table.width[payload.loc] = payload.value;
  },
  setDocTableMargins: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.table.margins[payload.loc] = payload.value;
  },
  setDocTableBorders: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.table.borders[payload.direction][payload.loc] =
      payload.value;
  },
  // DocRow Properties
  setDocRowHeight: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    if (doc.properties.row.height) {
      doc.properties.row.height[payload.loc] = payload.value;
    } else {
      doc.properties.row.height = {
        [payload.loc]: payload.value,
      };
    }
  },
  // DocCell Properties
  setDocCellAlignment: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.cell.verticalAlign = payload.value;
  },
  setDocCellMargins: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.cell.margins[payload.loc] = payload.value;
  },
  setDocCellBorders: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.cell.borders[payload.direction][payload.loc] = payload.value;
  },
  // DocPara Properties
  setDocParaAlignment: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.para.alignment = payload.value;
  },
  setDocParaSpacing: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.para.spacing[payload.loc] = payload.value;
  },
  // DocText Properties
  setDocTextFont: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.text.font = payload.value;
  },
  setDocTextFontSize: (state, { payload }) => {
    const doc = returnEle(state, payload.doc);
    doc.properties.text.fontSize = payload.value;
  },
};

export default documentProps;
