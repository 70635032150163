import React, { useEffect } from "react";
import Input from "../../utils/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  setBenchmark,
  addInput,
  removeInput,
  updateInput,
} from "./slices/benchmark";
import {
  useAddBenchmarkMutation,
  useUpdateBenchmarkMutation,
  useGetBenchmarksQuery,
} from "./slices/benchmarkApiSlice";
import { IoCloseSharp } from "react-icons/io5";
import { toast } from "react-toastify";

const Benchmark = () => {
  const dispatch = useDispatch();
  const { benchmark } = useSelector((state) => state.benchmark);

  const { data, refetch, isLoading } = useGetBenchmarksQuery();
  const [addBenchmark, {}] = useAddBenchmarkMutation();
  const [updateBenchmark, {}] = useUpdateBenchmarkMutation();

  useEffect(() => {
    if (data) {
      dispatch(setBenchmark(data[0]?.benchmark));
    }
  }, [data]);

  console.log(data);

  const addBenchmarkHandler = async () => {
    try {
      const res = await addBenchmark(benchmark).unwrap();
      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const updateBenchmarkHandler = async () => {
    try {
      const res = await updateBenchmark(benchmark).unwrap();
      refetch();
      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="psm">
      <div className="benchmark-home">
        <div className="flex justify-center">
          <button
            className="bg-cyan-950 text-light-500 px-2 py-1"
            onClick={() => {
              let val = prompt("Enter Input Name:");
              dispatch(addInput(val));
            }}
          >
            Add Input
          </button>
        </div>
        <div className="flex gap-4">
          {benchmark
            ? Object.keys(benchmark).map((C) => {
                return (
                  <div className="flex flex-col gap-2">
                    <h2 className="t2 txt1">{C}</h2>
                    <div className="relative">
                      <div
                        className={`absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
                        onClick={() => dispatch(removeInput(C))}
                      >
                        <IoCloseSharp />
                      </div>
                      <Input
                        value={benchmark[C]}
                        type="text"
                        placeholder={C}
                        change={(e) => {
                          dispatch(
                            updateInput({
                              loc: C,
                              value: e.target.value,
                            })
                          );
                        }}
                        click={() => {
                          let val = `Benchmark['${C}']`;
                          navigator.clipboard.writeText(val);
                        }}
                      />
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <div className="flex gap-4 justify-center mt-2">
          <button
            className="bg-cyan-950 text-light-500 px-2 py-1"
            onClick={addBenchmarkHandler}
          >
            Add Benchmark
          </button>
          <button
            className="bg-cyan-950 text-light-500 px-2 py-1"
            onClick={updateBenchmarkHandler}
          >
            update Benchmark
          </button>
        </div>
      </div>
    </div>
  );
};

export default Benchmark;
