import { returnEle, returnHFEle, returnEI, consoleFun } from "./functions";

const imageProps = {
  updateImageTransformation: (state, { payload }) => {
    let image;
    if (payload.layoutEle) {
      image = returnHFEle(state, payload.image, payload.layoutEle);
    } else {
      image = returnEle(state, payload.image);
    }
    image.properties.transformation[payload.dimension] = payload.value;
  },
};

export default imageProps;
