import React, { useState } from "react";
import { getCheckboxStyle } from "./Styles/checkboxStyle";
import { useDispatch, useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import { setIndexPath, removeEle } from "../../../../slices/docsSlice";
import { useNavigate } from "react-router-dom";

const CreateCheckbox = ({
  checkbox,
  index,
  loc,
  para,
  cell,
  row,
  table,
  sec,
  doc,
  baseURL,
  SIPHF,
}) => {
  const { symbols } = useSelector((state) => state.options);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { RRIP_CB, NIP_CB, dev } = useSelector((state) => state.docs);
  const [show, setShow] = useState(false);

  const toggleDropdown = (index) => {
    setShow((prevShowOptions) => {
      return {
        ...prevShowOptions,
        [index]: !prevShowOptions[index],
      };
    });
  };

  let splitLoc = loc.split("/");
  let splitIndex = index.split("_");
  let lastIndex = splitIndex[splitIndex.length - 1];

  const checkboxStyle = getCheckboxStyle({
    checkboxProps: checkbox?.properties,
    paraProps: para?.properties,
    cellProps: cell?.properties,
    rowProps: row?.properties,
    tableProps: table?.properties,
    sectionProps: sec?.properties,
    docProps: doc?.properties,
  });

  const charToHex = (char) => {
    const codePoint = char.codePointAt(0); // Get Unicode code point
    return codePoint.toString(16).toUpperCase(); // Convert to hex and make uppercase
  };

  let icon;
  if (checkbox) {
    let character;
    if (checkbox.properties.checked) {
      character = checkbox.properties.checkedState.value;
    } else {
      character = checkbox.properties.uncheckedState.value;
    }
    icon = symbols.find((item) => {
      let itemChar = charToHex(item.char);
      return itemChar === character;
    });
  }

  return (
    <div className="relative flex h-auto" key={`${index}`}>
      <span
        id={`${index}`}
        name={`${loc}`}
        style={checkboxStyle}
        className={`relative cursor-pointer whitespace-pre-wrap`}
        onClick={(e) => {
          if (dev) {
            toggleDropdown(index);
          }
          e.preventDefault();
          e.stopPropagation();

          // Bug here
          if (!dev) {
            dispatch(setIndexPath(`${loc}`));
            if (SIPHF === "0_H" || SIPHF === "0_F") {
              navigate(
                `${baseURL}/${loc}${
                  // check length for HF
                  splitLoc.length > 4
                    ? `/SP/HFP/TBP/TR/TC/PP/CBP/${lastIndex}`
                    : `/SP/HFP/PP/CBP/${lastIndex}`
                }`
              );
            } else {
              navigate(
                `${baseURL}/${loc}${
                  splitLoc.length > 4
                    ? `/SP/TBP/TR/TC/PP/CBP/${lastIndex}`
                    : `/SP/PP/CBP/${lastIndex}`
                }`
              );
            }
          }
        }}
      >
        {NIP_CB === `${index}` && (SIPHF !== "0_H" || SIPHF !== "0_F") ? (
          <div
            className={`${
              NIP_CB.startsWith(`${index}`) ? "flex" : "hidden"
            } absolute left-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50 z-30 text-xs`}
            onClick={(e) => {
              e.preventDefault();
              dispatch(removeEle(RRIP_CB));
              let newRRIP = [...RRIP_CB].slice(0, -1);
              if (newRRIP.length === 3) {
                navigate(`${baseURL}/${newRRIP.join("/")}/SP/PP`);
              } else {
                navigate(`${baseURL}/${newRRIP.join("/")}/SP/TBP/TR/TC/PP`);
              }
            }}
          >
            <IoCloseSharp />
          </div>
        ) : null}

        {icon ? <icon.icon /> : "CB"}
      </span>
    </div>
  );
};

export default CreateCheckbox;
