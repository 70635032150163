import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../../utils/Input";
import Dropdown from "../../../../../../utils/Dropdown";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  updateText,
  updateTextFont,
  updateTextSize,
  updateTextCasing,
  updateTextBold,
  updateTextItalics,
  updateTextUnderline,
  updateTextColor,
  setIndexPath,
} from "../../../../../../slices/docsSlice";
import RetractableDiv from "../../../../../../components/RetractableDiv";
import { returnPropEle } from "../../CommFunctions";
import Button from "../../../../../../utils/Button";

const TextProperties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    docs,
    indexPath,
    SIP,
    RRIP_I,
    RRIP_SY,
    RRIP_T,
    RRIP_P,
    RRIP_TB,
    RRIP_DOC,
    NIP_I,
    NIP_SY,
    NIP_T,
    NIP_P,
    NIP_S,
    NIP_DOC,
  } = useSelector((state) => state.docs);
  const { fonts, fontSizes, isTrueOrFalse } = useSelector(
    (state) => state.options
  );
  const { textName } = useSelector((state) => state.element);
  // const { productInfo } = useSelector((state) => state.product);
  const [font, setFont] = useState();
  const [fontSize, setFontSize] = useState();
  const [allCaps, setAllCaps] = useState();
  const [bold, setBold] = useState();
  const [italics, setItalics] = useState();
  const [underline, setUnderline] = useState();
  const [color, setColor] = useState();

  // useEffect(() => {
  //   const input = document.querySelectorAll(`[name="${NIP_T}"]`);
  //   if (input.length > 0) {
  //     input[0].select();
  //     window.scrollTo({
  //       top: input[0].offsetTop,
  //       behavior: "smooth",
  //     });
  //   }
  // }, [NIP_T]);

  const { pathname } = useLocation();
  let splitLoc = pathname.split("/");
  const currentPath = splitLoc[3];
  if (splitLoc.length >= 4) {
    let length = splitLoc.length - 3;
    splitLoc.splice(3, length);
  }
  const baseURL = splitLoc.join("/");

  let NIP_T_Arr = NIP_T.split("_");
  NIP_T_Arr.pop();

  let NIP_I_Arr = NIP_I.split("_");
  NIP_I_Arr.pop();

  let NIP_SY_Arr = NIP_SY.split("_");
  NIP_SY_Arr.pop();

  let dynName = "docs";
  NIP_T.split("_").forEach((item, i) => {
    dynName =
      dynName +
      `['${item}'].${i === NIP_T.split("_").length - 1 ? "t" : "ele"}`;
  });

  // let SIPHF = SIP[SIP.length - 3];
  let SIPHF = SIP[2];
  let dynPath = `docs`;
  if (SIPHF === "0_H" || SIPHF === "0_F") {
    let concatedString = NIP_P.replace(`${NIP_S}_0_`, "");
    const concatenatedNIP = concatedString.split("_");
    NIP_S.split("_").forEach((item, i) => {
      if (i === NIP_S.split("_").length - 1) {
        dynPath =
          dynPath +
          (SIPHF === "0_H"
            ? `['${item}'].headers.ele`
            : `['${item}'].footers.ele`);
      } else {
        dynPath = dynPath + `['${item}'].ele`;
      }
    });
    concatenatedNIP.forEach((item) => {
      dynPath = dynPath + `['${item}'].ele`;
    });
  } else {
    NIP_P.split("_").forEach((item) => {
      dynPath = dynPath + `['${item}'].ele`;
    });
  }

  useEffect(() => {
    const text = RRIP_T ? returnPropEle(docs, RRIP_T) : undefined;
    const para = returnPropEle(docs, RRIP_P);
    const table = RRIP_TB ? returnPropEle(docs, RRIP_TB) : undefined;
    const doc = returnPropEle(docs, RRIP_DOC);

    if (text) {
      if (text.properties.font) {
        setFont(text.properties.font);
      } else if (table !== undefined && table.properties.font) {
        setFont(table.properties.text.font);
      } else {
        setFont(doc.properties.text.font);
      }

      if (text.properties.fontSize) {
        setFontSize(text.properties.fontSize);
      } else if (table !== undefined && table.properties.fontSize) {
        setFontSize(table.properties.text.fontSize);
      } else {
        setFontSize(doc.properties.text.fontSize);
      }

      if (text.properties.allCaps) {
        setAllCaps(text.properties.allCaps);
      } else {
        setAllCaps();
      }
      if (text.properties.bold) {
        setBold(text.properties.bold);
      } else {
        setBold();
      }
      if (text.properties.italics) {
        setItalics(text.properties.italics);
      } else {
        setItalics();
      }
      if (text.properties.underline) {
        setUnderline(text.properties.underline);
      } else {
        setUnderline();
      }
      if (text.properties.color) {
        setColor(`#${text.properties.color.toLowerCase()}`);
      } else {
        setColor();
      }
    }
  }, [docs, NIP_T, NIP_P]);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="psm morphous bg-gray-100">
      <h1 className="font-mono text-center pb-4 line-clamp-1">
        Text Properties [{textName ? truncateText(textName, 20) : ""}]
      </h1>
      {/* <div className="flex gap-4 pb-4 bg-">
        <button className="bg-cyan-950 text-light-500 h-[40px] w-[100px]">
          Text Props
        </button>
      </div> */}
      <div className="flex flex-col gap-4 pb-4">
        {dynPath
          ? eval(dynPath)?.map((t, i) => {
              if (t.type === "text") {
                return (
                  <>
                    <Input
                      name={`${NIP_T_Arr.join("_")}_${i}`}
                      id={`${NIP_T_Arr.join("_")}_${i}`}
                      type="text"
                      className="h-[40px] w-[100%]"
                      // value={t.t === "" ? "R" : t.t}
                      value={t.t}
                      click={(e) => {
                        dispatch(setIndexPath(`${RRIP_P.join("/")}/${i}_T`));
                        navigate(
                          `${baseURL}/${RRIP_P.join("/")}/${i}_T/${
                            SIP.length > 4 ? "SP/TBP/TR/TC/PP/TP" : "SP/PP/TP"
                          }/${i}`
                        );
                      }}
                      change={(e) => {
                        dispatch(
                          updateText({
                            text: RRIP_T,
                            value: e.target.value,
                            layoutEle:
                              SIPHF === "0_H" || SIPHF === "0_F"
                                ? SIPHF
                                : undefined,
                          })
                        );
                      }}
                      focus={false}
                    />
                  </>
                );
              } else if (t.type === "image") {
                return (
                  <a href={`#${NIP_I_Arr.join("_")}_${i}`}>
                    <Input
                      name={`${NIP_I_Arr.join("_")}_${i}`}
                      id={`${NIP_I_Arr.join("_")}_${i}`}
                      type="text"
                      className="h-[40px] w-[100%]"
                      value={t.name}
                      click={(e) => {
                        dispatch(setIndexPath(`${RRIP_P.join("/")}/${i}_I`));
                        navigate(
                          `${baseURL}/${RRIP_P.join("/")}/${i}_I/${
                            SIP.length === 3 ? "PP/IP" : "TBP/TR/TC/PP/IP"
                          }/${i}`
                        );
                        e.target.select();
                      }}
                      disabled={true}
                    />
                  </a>
                );
              } else {
                return (
                  <a href={`#${NIP_SY_Arr.join("_")}_${i}`}>
                    <Input
                      name={`${NIP_SY_Arr.join("_")}_${i}`}
                      id={`${NIP_SY_Arr.join("_")}_${i}`}
                      type="text"
                      className="h-[40px] w-[100%]"
                      value={t.char}
                      // click={(e) => {
                      //   dispatch(setIndexPath(`${RRIP_P.join("/")}/${i}_SY`));
                      //   navigate(
                      //     `${baseURL}/${RRIP_P.join("/")}/${i}_SY/${
                      //       SIP.length === 3 ? "PP/IP" : "TBP/TR/TC/PP/SYP"
                      //     }/${i}`
                      //   );
                      //   e.target.select();
                      // }}
                      disabled={true}
                    />
                  </a>
                );
              }
            })
          : null}
      </div>
      <div className="flex items-end flex-wrap gap-4 pb-4">
        <div>
          <h2 className="pb-1">Text Font</h2>
          <Dropdown
            className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
            value={font}
            options={fonts}
            target="option"
            width={200}
            height={40}
            click={(value) => {
              dispatch(
                updateTextFont({
                  text: RRIP_T,
                  value,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
          />
        </div>
        <div>
          <h2 className="pb-1">Text Size</h2>
          <Input
            className="h-[40px] w-[100px]"
            value={fontSize}
            type="text"
            blur={(e) =>
              dispatch(
                updateTextSize({
                  text: RRIP_T,
                  value: e.target.value,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              )
            }
            change={(e) => setFontSize(e.target.value)}
          />
        </div>
        <div>
          <h2 className="pb-1">Color</h2>
          <div className="flex items-center gap-2">
            <Input
              className="h-[40px] w-[100px] p-0 shadow-none"
              value={color}
              blur={(e) =>
                dispatch(
                  updateTextColor({
                    text: RRIP_T,
                    value: e.target.value,
                    layoutEle:
                      SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                  })
                )
              }
              change={(e) => setColor(e.target.value)}
              type="color"
            />
            {/* <div
              className={`text-2xl h-[35px] w-[35px] flex items-center justify-center bg-success-500 cursor-pointer rounded`}
              onClick={() => {
                navigate(`${baseURL}/${indexPath}/PP/TP/SC/textColor`);
              }}
            >
              <GiCardPickup />
            </div> */}
          </div>
        </div>
        <div>
          {/* <h2 className="pb-1">All Caps</h2> */}
          <Button
            name="Capital"
            value={allCaps}
            click={() => {
              dispatch(
                updateTextCasing({
                  text: RRIP_T,
                  value: allCaps,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
          />
        </div>
        <div>
          {/* <h2 className="pb-1">Bold</h2> */}
          <Button
            name="B"
            value={bold}
            click={() => {
              dispatch(
                updateTextBold({
                  text: RRIP_T,
                  value: bold,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
          />
        </div>
        <div>
          {/* <h2 className="pb-1">Italics</h2> */}
          <Button
            name="I"
            value={italics}
            click={() => {
              dispatch(
                updateTextItalics({
                  text: RRIP_T,
                  value: italics,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
          />
        </div>
        <div>
          {/* <h2 className="pb-1">Underline</h2> */}
          <Button
            name="U"
            value={underline}
            click={() => {
              dispatch(
                updateTextUnderline({
                  text: RRIP_T,
                  value: underline,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
          />
        </div>
      </div>
      <div className="mb-2">
        <Outlet />
      </div>
    </div>
  );
};

export default TextProperties;
