import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import Editor from "../../../../../components/Editor";
import {
  addJSFunc,
  removeJSFunc,
  updateInc,
} from "../../../../../slices/docsSlice";
import { FaInfo } from "react-icons/fa";

const JsFunControls = ({ path, dynEle }) => {
  const dispatch = useDispatch();
  const { docs, indexPath, RRIP_P, RRIP_TB } = useSelector(
    (state) => state.docs
  );
  const { userForms, forms } = useSelector((state) => state.forms);

  // Local state to track the evaluated element
  const [ele, setEle] = useState(null);

  // Evaluate dynEle on every change
  useEffect(() => {
    try {
      setEle(eval(dynEle));
    } catch (e) {
      console.error("Error evaluating dynEle", e);
    }
  }, [dynEle, docs]);

  return (
    <div className="editor relative w-full h-[450px] 2xl:h-auto overflow-auto">
      {!ele?.jsFun ? (
        <button
          className="h-[40px] px-2 bg-cyan-950 text-light-500"
          onClick={() => {
            dispatch(
              addJSFunc({
                path: path,
                value: `// You Have Access to the following Internal and External Dependencies.\n//${userForms.map(
                  (dep) => ` ${dep.name}`
                )}\nfunction(E, ${
                  ele?.type === "para" ? "P" : "T"
                }, user, manager, CI) {\n  \n}`,
              })
            );
          }}
        >
          Add Function
        </button>
      ) : null}

      {ele?.jsFun ? (
        <>
          <div
            style={{ width: "16px" }}
            className={`absolute right-[6px] top-[6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50 z-50`}
            onClick={() =>
              dispatch(
                removeJSFunc({
                  path: path,
                })
              )
            }
          >
            <IoCloseSharp />
          </div>
          <div
            style={{ minWidth: "26px", height: "26px" }}
            className={`absolute right-[40px] top-[6px] cursor-pointer ${
              ele.inc
                ? "bg-cyan-700 text-light-500"
                : "bg-light-500 text-cyan-950"
            } rounded text-center shadow z-50 text-sm flex justify-center items-center font-mono font-bold px-2`}
            onClick={() =>
              dispatch(
                updateInc({
                  path: path,
                })
              )
            }
          >
            Inc
          </div>
          <Editor
            path={path}
            value={
              ele?.jsFun
                ? ele.jsFun
                : `function(CES, C, XT, MXT, XTS) {
  console.log(CES, C, XT, MXT, XTS);
}`
            }
          />
        </>
      ) : null}
    </div>
  );
};

export default JsFunControls;
