import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isValidVariableName } from "../shared/InputCheck";

import Dropdown from "../../utils/Dropdown";
import Input from "../../utils/Input";
import Table from "../../utils/Table/Table";
import TableRow from "../../utils/Table/TableRow";
import TableCell from "../../utils/Table/TableCell";

import {
  useAddExternalDependencyMutation,
  useGetExternalDependenciesQuery,
} from "../../slices/externalDependencyApiSlice";

const AddExternalDependency = () => {
  const [agency, setAgency] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const { agencies } = useSelector((state) => state.agencies);

  const [addExternalDependency, {}] = useAddExternalDependencyMutation();
  const {
    data: externalDependencies,
    refetch: refetchExternalDependencies,
    isLoading: externalDependenciesLoading,
  } = useGetExternalDependenciesQuery();

  const createDependencyHandler = async () => {
    if (agency === "" || name === "" || role === "") {
      toast.error("Name, Agency and Role are needed.");
    } else {
      if (isValidVariableName(name)) {
        try {
          const res = await addExternalDependency({
            name,
            agency,
            role,
          }).unwrap();
          toast.success(res.message);
          setName("");
          setAgency("");
          setRole("");
          refetchExternalDependencies();
        } catch (err) {
          toast.error(err?.data?.message || err.error);
        }
      } else {
        toast.error(
          "Invalid variable name. It must start with a letter, $, or _ and can contain letters, numbers, $, or _."
        );
      }
    }
  };

  return (
    <div className="psm gap-2">
      <div className="wh-80 flex flex-col gap-10">
        <div className="morphous p-4">
          <h2 className="font-mono pb-2">Add External Dependency</h2>
          <div className="flex gap-4">
            <Input
              type="text"
              value={name}
              placeholder="Enter Dependency Name"
              change={(e) => {
                if (window[e.target.value]) {
                  alert(`${e.target.value} exists change the name.`);
                }
                setName(e.target.value);
              }}
            />
            <Dropdown
              options={agencies}
              value={agency}
              target="option.agency"
              name="Agency"
              click={(value) => setAgency(value.agency)}
              style={
                agency ? { backgroundColor: "#035afc", color: "white" } : null
              }
            />
            <Dropdown
              options={["Manager", "User", "Both"]}
              value={role}
              target="option"
              name="Role"
              click={(value) => setRole(value)}
              style={
                role ? { backgroundColor: "#035afc", color: "white" } : null
              }
            />
            <button
              className="px-5 py-3 bg-cyan-950 text-light-500 font-mono"
              onClick={createDependencyHandler}
            >
              Create Dependency
            </button>
          </div>
        </div>
        <div className="morphous p-4">
          <h2 className="font-mono pb-2">External Dependencies</h2>
          <Table>
            <TableRow isHeader={true}>
              <TableCell isHeader={true}>S.No</TableCell>
              <TableCell isHeader={true}>Name</TableCell>
              <TableCell isHeader={true}>Agency</TableCell>
              <TableCell isHeader={true}>Role</TableCell>
            </TableRow>
            {externalDependencies?.map((dependency, sno) => {
              return (
                <TableRow key={sno}>
                  <TableCell>{sno + 1}</TableCell>
                  <TableCell>{dependency.name}</TableCell>
                  <TableCell>{dependency.agency}</TableCell>
                  <TableCell>{dependency.role}</TableCell>
                </TableRow>
              );
            })}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default AddExternalDependency;
