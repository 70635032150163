import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../../utils/Button";
import { returnPropEle } from "../../CommFunctions";
import Symbols from "../../../../../../utils/Symbols";
import {
  updateChecked,
  updateCheckedState,
  updateUncheckedState,
} from "../../../../../../slices/docsSlice";

const CheckboxProperties = () => {
  const dispatch = useDispatch();
  const { SIP, RRIP_CB, NIP_CB, docs } = useSelector((state) => state.docs);
  const { symbols } = useSelector((state) => state.options);
  const [checked, setChecked] = useState();
  const [checkedState, setCheckedState] = useState();
  const [uncheckedState, setUncheckedState] = useState();

  useEffect(() => {
    const checkbox = RRIP_CB ? returnPropEle(docs, RRIP_CB) : undefined;
    if (checkbox) {
      setChecked(checkbox.properties.checked);
      setCheckedState(checkbox.properties.checkedState.value);
      setUncheckedState(checkbox.properties.uncheckedState.value);
    }
  }, [docs, NIP_CB]);

  let SIPHF = SIP[2];

  return (
    <div className="psm morphous bg-gray-100">
      <h1 className="font-mono text-center pb-4 line-clamp-1">
        Checkbox Properties
      </h1>
      <div className="flex items-end flex-wrap gap-4 pb-4">
        <div>
          <h2 className="pb-1">Checked Status</h2>
          <Button
            name="Checked"
            value={checked}
            click={() => {
              dispatch(
                updateChecked({
                  checkbox: RRIP_CB,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
          />
        </div>
        <div>
          <h2>Checked State</h2>
          <Symbols
            width={150}
            height={40}
            options={symbols}
            value={checkedState}
            target="option.char"
            name="char"
            click={(value) => {
              let symText = value.char
                .codePointAt(0)
                .toString(16)
                .toUpperCase();
              dispatch(
                updateCheckedState({
                  checkbox: RRIP_CB,
                  value: symText,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
          />
        </div>
        <div>
          <h2>Unchecked State</h2>
          <Symbols
            width={150}
            height={40}
            options={symbols}
            value={uncheckedState}
            target="option.char"
            name="char"
            click={(value) => {
              let symText = value.char
                .codePointAt(0)
                .toString(16)
                .toUpperCase();
              dispatch(
                updateUncheckedState({
                  checkbox: RRIP_CB,
                  value: symText,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CheckboxProperties;
