import { convertToPx } from "../../UnitConversion";
export const getSymbolStyle = ({
  symbolProps,
  paraProps,
  cellProps,
  rowProps,
  tableProps,
  docProps,
}) => {
  const style = {};
  // style.fontFamily = docProps?.text?.font;
  // style.fontSize = docProps?.text?.fontSize;
  // style.minHeight = convertToPx(docProps.text?.fontSize);
  // if (tableProps !== undefined) {
  //   if (tableProps.text?.font) {
  //     style.fontFamily = tableProps.text.font;
  //   }
  //   if (tableProps.text?.fontSize) {
  //     style.fontSize = tableProps.text.fontSize;
  //     style.minHeight = convertToPx(tableProps.text.fontSize);
  //   } else {
  //     style.fontSize = docProps.text?.fontSize;
  //     style.minHeight = convertToPx(docProps.text?.fontSize);
  //   }
  // }
  // if (Object.keys(textProps).length !== 0) {
  //   if (textProps.font) {
  //     style.fontFamily = textProps.font;
  //   }
  //   if (textProps.fontSize) {
  //     style.fontSize = textProps.fontSize;
  //     style.minHeight = convertToPx(textProps.fontSize);
  //   }
  //   if (textProps.allCaps) {
  //     style.textTransform = "uppercase";
  //   }
  //   if (textProps.bold) {
  //     style.fontWeight = "bold";
  //   }
  //   if (textProps.italics) {
  //     style.fontStyle = "italic";
  //   }
  //   if (textProps.underline) {
  //     style.textDecoration = "underline";
  //   }
  //   if (textProps.color) {
  //     style.color = `#${textProps.color}`;
  //   }
  // }
  // style.display = "inline-block";
  return style;
};
