import { returnEle, returnHFEle, returnEI, consoleFun } from "./functions";

const textProps = {
  updateText: (state, { payload }) => {
    let text;
    if (payload.layoutEle) {
      text = returnHFEle(state, payload.text, payload.layoutEle);
    } else {
      text = returnEle(state, payload.text);
    }
    text.t = payload.value;
  },
  updateTextFont: (state, { payload }) => {
    let text;
    if (payload.layoutEle) {
      text = returnHFEle(state, payload.text, payload.layoutEle);
    } else {
      text = returnEle(state, payload.text);
    }
    text.properties.font = payload.value;
  },
  updateTextSize: (state, { payload }) => {
    let text;
    if (payload.layoutEle) {
      text = returnHFEle(state, payload.text, payload.layoutEle);
    } else {
      text = returnEle(state, payload.text);
    }
    text.properties.fontSize = payload.value;
  },
  updateTextCasing: (state, { payload }) => {
    let text;
    if (payload.layoutEle) {
      text = returnHFEle(state, payload.text, payload.layoutEle);
    } else {
      text = returnEle(state, payload.text);
    }
    if (!payload.value) {
      text.properties.allCaps = true;
    } else {
      delete text.properties.allCaps;
    }
  },
  updateTextBold: (state, { payload }) => {
    let text;
    if (payload.layoutEle) {
      text = returnHFEle(state, payload.text, payload.layoutEle);
    } else {
      text = returnEle(state, payload.text);
    }
    if (!payload.value) {
      text.properties.bold = true;
    } else {
      delete text.properties.bold;
    }
  },
  updateTextItalics: (state, { payload }) => {
    let text;
    if (payload.layoutEle) {
      text = returnHFEle(state, payload.text, payload.layoutEle);
    } else {
      text = returnEle(state, payload.text);
    }
    if (!payload.value) {
      text.properties.italics = true;
    } else {
      delete text.properties.italics;
    }
  },
  updateTextUnderline: (state, { payload }) => {
    let text;
    if (payload.layoutEle) {
      text = returnHFEle(state, payload.text, payload.layoutEle);
    } else {
      text = returnEle(state, payload.text);
    }
    if (!payload.value) {
      text.properties.underline = true;
    } else {
      delete text.properties.underline;
    }
  },
  updateTextColor: (state, { payload }) => {
    let text;
    if (payload.layoutEle) {
      text = returnHFEle(state, payload.text, payload.layoutEle);
    } else {
      text = returnEle(state, payload.text);
    }
    text.properties.color = payload.value.replace("#", "").toUpperCase();
  },
};

export default textProps;
