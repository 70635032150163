import React, { useState } from "react";
import Input from "../../../utils/Input";

const PersonalDetails = ({ row, obj, i }) => {
  const [detail, setDetail] = useState(row[obj]);
  return (
    <Input
      type="text"
      value={detail}
      click={() => {
        let val = `Cibil[${i}].${obj}`;
        navigator.clipboard.writeText(val);
      }}
      change={(e) => {
        setDetail(e.target.value);
      }}
    />
  );
};

export default PersonalDetails;
