import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useGetAgencyMutation } from "../../slices/agencyApiSlice";
import { setAgency } from "../../slices/agency";
import {
  useGetAssignedDocsMutation,
  useGetDocumentMutation,
} from "../../slices/documentsApiSlice";
import { setDocument } from "../../slices/documentSlice";
import {
  getForms,
  setFormsForUser,
  setSingleForm,
  setSingleUserForm,
  setFormsForManager,
} from "../../slices/formsSlice";
import {
  getListIDS,
  setListIDS,
  setInternalDependency,
} from "../../slices/internalDependencySlice";
import {
  setDocs,
  setIndexPath,
  setName,
  setCategory,
  setDocId,
} from "../../slices/docsSlice";
import { setFilledEF } from "../../slices/entryForm";

import Loader from "../../components/Loader";

import Table from "../../utils/Table/Table";
import TableRow from "../../utils/Table/TableRow";
import TableCell from "../../utils/Table/TableCell";
import Dropdown from "../../utils/Dropdown";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const { document } = useSelector((state) => state.document);
  const { forms, userForms } = useSelector((state) => state.forms);
  const { docs, indexPath, category } = useSelector((state) => state.docs);
  const { listIDS } = useSelector((state) => state.internalDependency);

  const [documents, setDocuments] = useState([]);
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);

  const [getAgency, { isLoading: loadingAgency }] = useGetAgencyMutation();
  const [getAssignedDocs, { isLoading: loadingDocs }] =
    useGetAssignedDocsMutation();
  const [getDocument, {}] = useGetDocumentMutation();
  const { pathname } = useLocation();

  useEffect(() => {
    if (userInfo) {
      const fetchAgency = async () => {
        const agency = await getAgency({
          agencyid: userInfo.agency,
        }).unwrap();
        dispatch(setAgency(agency.agency));
      };

      const fetchDocuments = async () => {
        const docs = await getAssignedDocs({
          assignedDocs: userInfo.assignedDocs,
        }).unwrap();
        setDocuments(docs);
      };

      fetchAgency();
      fetchDocuments();
    }
  }, [userInfo, getAgency, getAssignedDocs, dispatch]);

  useEffect(() => {
    if (document && forms && userForms && category) {
      setIsReadyToNavigate(true);
    }
  }, [document, forms, userForms, category]);

  useEffect(() => {
    if (document && document.internalDependency) {
      navigate("/op/InternalDep");
    }
  }, [document]);

  useEffect(() => {
    if (isReadyToNavigate && document && docs) {
      if (document.indexPath === "") {
        navigate(`/op/${document.category}/DC`);
      } else {
        let RRIP = document.indexPath.split("/");
        let lastIndex = RRIP[RRIP.length - 1];
        let SLI = lastIndex.split("_");
        let path = `/op/${document.category}/${document.indexPath}`;
        let NIP = RRIP.map((index) => index.split("_")[0]).join("_");
        switch (SLI[1]) {
          case "I":
            if (RRIP.length === 4) {
              navigate(`${path}/SP/PP/IP`);
            } else {
              navigate(`${path}/SP/TBP/TR/TC/PP/IP`);
            }
            break;
          case "T":
            if (RRIP.length === 4) {
              navigate(`${path}/SP/PP/TP/0#${NIP}`);
            } else {
              navigate(`${path}/SP/TBP/TR/TC/PP/TP/0#${NIP}`);
            }
            break;
          case "P":
            if (RRIP.length === 3) {
              navigate(`${path}/SP/PP`);
            } else {
              navigate(`${path}/SP/TBP/TR/TC/PP`);
            }
            break;
          case "TC":
            navigate(`${path}/SP/TBP/TR/TC`);
            break;
          case "TR":
            navigate(`${path}/SP/TBP/TR`);
            break;
          case "TB":
            navigate(`${path}/SP/TBP`);
            break;
          case "S":
            navigate(`${path}/SP`);
            break;
          case "Doc":
            navigate(`${path}/DP`);
            break;
        }
      }
    } else {
    }
  }, [isReadyToNavigate, document, docs]);

  return (
    <div className="psm flex gap-2">
      <div className="wh-100">
        {documents.length === 0 ? (
          <Loader />
        ) : (
          <div className="p-4 morphous bg-color">
            <h2 className="font-mono pb-2">Select Document</h2>
            <Table>
              <TableRow isHeader={true}>
                <TableCell isHeader={true}>S.No</TableCell>
                <TableCell isHeader={true}>Name</TableCell>
                <TableCell isHeader={true}>Category</TableCell>
                <TableCell isHeader={true}>Select</TableCell>
              </TableRow>
              {documents?.map((doc, sno) => {
                return (
                  <TableRow
                    key={sno}
                    className={(sno + 1) % 2 === 0 ? "bg-gray-100" : null}
                  >
                    <TableCell>{sno + 1}</TableCell>
                    <TableCell>{doc.name}</TableCell>
                    <TableCell>{doc.category}</TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <button
                        className="bg-cyan-950 text-light-500 h-[48px] px-2 text-center min-w-[100px]"
                        onClick={() => {
                          if (doc.internalDependency) {
                            dispatch(setDocument(doc));
                            dispatch(setDocs(doc.document));
                            dispatch(setFilledEF(doc.filledEF));
                            dispatch(setDocId(doc._id));
                            dispatch(setName(doc.name));
                            dispatch(setCategory(doc.category));
                            dispatch(setIndexPath(doc.indexPath));
                            dispatch(
                              setListIDS({
                                document: `${doc.category}$${doc.name}`,
                                entryForm: `${doc.category}$${doc.name}_EF`,
                                category: doc.category,
                                name: doc.name,
                              })
                            );
                            dispatch(setInternalDependency(doc));
                            dispatch(setFormsForManager(doc));
                            // navigate("/manager/InternalDep");
                          } else {
                            dispatch(setDocument(doc));
                            dispatch(setDocs(doc.document));
                            dispatch(setFilledEF(doc.filledEF));
                            dispatch(setDocId(doc._id));
                            dispatch(setName(doc.name));
                            dispatch(setCategory(doc.category));
                            dispatch(setIndexPath(doc.indexPath));
                            dispatch(setFormsForManager(doc));
                            dispatch(
                              setListIDS({
                                document: `${doc.category}$${doc.name}`,
                                entryForm: `${doc.category}$${doc.name}_EF`,
                                category: doc.category,
                                name: doc.name,
                              })
                            );
                            dispatch(setInternalDependency(doc));
                            // getDocumentHandler(doc);
                            // navigate(`/manager/${doc.category}/DC`);
                          }
                        }}
                      >
                        Select
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
