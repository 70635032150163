import React from "react";
import Input from "../../../../../utils/Input";
import { useSelector, useDispatch } from "react-redux";
import { updateFEF } from "../../../../../slices/entryForm";
import { updateFFEF } from "../../../../../slices/formsSlice";

const MDATE = ({ cluster, ele, operator, i, j }) => {
  const dispatch = useDispatch();
  const { filledEF } = useSelector((state) => state.entryForm);
  const { userInfo } = useSelector((state) => state.auth);
  const { dev, category, name } = useSelector((state) => state.docs);

  const [dd, mm, yy] =
    filledEF[operator] &&
    filledEF[operator][cluster] &&
    filledEF[operator][cluster][i][j]
      ? filledEF[operator][cluster][i][j]?.split("-")
      : "";
  let inputDate = `${yy}-${mm}-${dd}`;

  return (
    <div>
      <h2 className="pb-1">{ele.placeholder}</h2>
      <Input
        type="date"
        value={inputDate}
        change={(e) => {
          const [year, month, day] = e.target.value.split("-");
          let date = `${day}-${month}-${year}`;
          dispatch(
            updateFEF({
              cluster: cluster,
              i,
              j,
              operator: operator,
              name: ele.placeholder,
              value: date,
            })
          );

          dispatch(
            updateFFEF({
              cluster: cluster,
              i,
              j,
              EF: `${category}$${name}_EF`,
              name: ele.placeholder,
              value: date,
              operator,
            })
          );
        }}
        click={() => {
          if (dev) {
            let text = `${category}$${name}_EF['${operator}']['${ele.placeholder}']`;
            navigator.clipboard.writeText(text);
          }
        }}
      />
    </div>
  );
};

export default MDATE;
