import React, { useState, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";
import Input from "../../../../utils/Input";
import Dropdown from "../../../../utils/Dropdown";
import { useDispatch } from "react-redux";
import {
  removeUserInput,
  updateUserCondition,
  updateInputType,
  updateInputLength,
  updateDefaultValue,
} from "../../../../slices/entryForm";

const CInput = ({ opInput, operator }) => {
  const [dv, setDv] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setDv(opInput.condition);
  }, [opInput]);

  return (
    <div className="flex flex-col gap-2 bg-red-300 p-2 rounded w-full relative">
      <div
        className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
        onClick={() =>
          dispatch(
            removeUserInput({
              name: opInput.placeholder,
              operator: operator,
            })
          )
        }
      >
        <IoCloseSharp />
      </div>
      <div>
        <h2 className="pb-1">Condition</h2>
        <Input
          type="text"
          className="w-full"
          value={dv}
          change={(e) => setDv(e.target.value)}
          blur={(e) => {
            dispatch(
              updateUserCondition({
                operator: operator,
                name: opInput.placeholder,
                condition: e.target.value,
              })
            );
          }}
        />
      </div>
      <div className="flex flex-wrap items-center gap-4">
        <div>
          <h2 className="pb-1">
            {opInput.placeholder} ({opInput.element})
          </h2>
          <Input
            type="text"
            placeholder="default value"
            value={opInput.defaultValue}
            change={(e) => {
              dispatch(
                updateDefaultValue({
                  name: opInput.placeholder,
                  operator: operator,
                  value: e.target.value,
                })
              );
            }}
          />
        </div>
        <div>
          <h2 className="pb-1">Input Type</h2>
          <Dropdown
            name="Input Type"
            width={200}
            height={40}
            value={opInput.type}
            options={["text", "number"]}
            target="option"
            click={(value) => {
              dispatch(
                updateInputType({
                  name: opInput.placeholder,
                  operator: operator,
                  value: value,
                })
              );
            }}
          />
        </div>
        <div>
          <h2 className="pb-1">Input Length</h2>
          <Input
            className="w-[100px]"
            type="number"
            value={opInput.maxLength ? opInput.maxLength : ""}
            change={(e) => {
              dispatch(
                updateInputLength({
                  name: opInput.placeholder,
                  operator: operator,
                  value: e.target.value,
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CInput;
