import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../../utils/Input";
import Dropdown from "../../../../../../utils/Dropdown";
import Button from "../../../../../../utils/Button";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  updateSymbolFont,
  updateSymbolSize,
  updateSymbolBold,
  updateSymbolItalics,
} from "../../../../../../slices/docsSlice";
import { returnPropEle } from "../../CommFunctions";

const SymbolProperties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { SIP, RRIP_SY, RRIP_P, RRIP_TB, RRIP_DOC, NIP_SY, NIP_P, docs } =
    useSelector((state) => state.docs);
  const { fonts } = useSelector((state) => state.options);

  const [font, setFont] = useState();
  const [fontSize, setFontSize] = useState();
  const [bold, setBold] = useState();
  const [italics, setItalics] = useState();

  useEffect(() => {
    const symbol = RRIP_SY ? returnPropEle(docs, RRIP_SY) : undefined;
    const para = returnPropEle(docs, RRIP_P);
    const table = RRIP_TB ? returnPropEle(docs, RRIP_TB) : undefined;
    const doc = returnPropEle(docs, RRIP_DOC);

    if (symbol) {
      if (symbol.properties.font) {
        setFont(symbol.properties.font);
      } else if (table !== undefined && table.properties.font) {
        setFont(table.properties.symbol.font);
      } else {
        setFont(doc.properties.text.font);
      }

      if (symbol.properties.fontSize) {
        setFontSize(symbol.properties.fontSize);
      } else if (table !== undefined && table.properties.fontSize) {
        setFontSize(table.properties.symbol.fontSize);
      } else {
        setFontSize(doc.properties.text.fontSize);
      }

      if (symbol.properties.bold) {
        setBold(symbol.properties.bold);
      } else {
        setBold();
      }
      if (symbol.properties.italics) {
        setItalics(symbol.properties.italics);
      } else {
        setItalics();
      }
    }
  }, [docs, NIP_SY, NIP_P]);

  let SIPHF = SIP[2];

  return (
    <div className="psm morphous bg-gray-100">
      <h1 className="font-mono text-center pb-4 line-clamp-1">
        Symbol Properties
      </h1>
      <div className="flex items-end flex-wrap gap-4 pb-4">
        <div>
          <h2 className="pb-1">Symbol Font</h2>
          <Dropdown
            className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
            value={font}
            options={fonts}
            target="option"
            width={200}
            height={40}
            click={(value) => {
              dispatch(
                updateSymbolFont({
                  symbol: RRIP_SY,
                  value,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
          />
        </div>
        <div>
          <h2 className="pb-1">Symbol Size</h2>
          <Input
            className="h-[40px] w-[100px]"
            value={fontSize}
            type="text"
            blur={(e) =>
              dispatch(
                updateSymbolSize({
                  symbol: RRIP_SY,
                  value: e.target.value,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              )
            }
            change={(e) => setFontSize(e.target.value)}
          />
        </div>
        <div>
          <Button
            name="B"
            value={bold}
            click={() => {
              dispatch(
                updateSymbolBold({
                  symbol: RRIP_SY,
                  value: bold,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
          />
        </div>
        <div>
          <Button
            name="I"
            value={italics}
            click={() => {
              dispatch(
                updateSymbolItalics({
                  symbol: RRIP_SY,
                  value: italics,
                  layoutEle:
                    SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                })
              );
            }}
          />
        </div>
      </div>
      <div className="mb-2">
        <Outlet />
      </div>
    </div>
  );
};

export default SymbolProperties;
