import React from "react";
// import { debounce } from "lodash";

const TextArea = React.memo(
  ({
    placeholder,
    name,
    type,
    change,
    blur,
    value,
    click,
    id,
    disabled,
    maxLength,
    keyDown,
    className,
    style,
    focus,
  }) => {
    return (
      <textarea
        id={id}
        className={
          className
            ? `${className} form_input font-inconsolata`
            : "form_input font-courier"
        }
        style={style}
        placeholder={placeholder}
        name={name}
        type={type ? type : "text"}
        value={value}
        onFocus={focus === false ? null : (e) => e.target.select()}
        onClick={click}
        onChange={change}
        onBlur={blur}
        autoComplete="off"
        // aria-autocomplete={fal}
        disabled={disabled}
        maxLength={maxLength}
        onKeyDown={keyDown}
      />
    );
  }
);

export default TextArea;
