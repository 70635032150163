import { returnEle, returnHFEle, returnEI, consoleFun } from "./functions";
import { TEXT, PARA, CELL, ROW, TABLE, SECTION } from "./elements";

const sectionProps = {
  addPara: (state, { payload }) => {
    let ele;
    if (payload.layoutEle) {
      ele = returnHFEle(state, payload.path, payload.layoutEle);
    } else {
      ele = returnEle(state, payload.path);
    }
    let text = structuredClone(TEXT);
    text.t = "R";
    let para = structuredClone(PARA);
    para.ele.push(text);
    para.name = payload.name ? payload.name : ele.ele.length;
    if (payload.loc) {
      if (payload.loc === "after") {
        ele.ele.splice(payload.index + 1, 0, { ...para });
      } else {
        ele.ele.splice(payload.index, 0, { ...para });
      }
    } else {
      ele.ele.push({
        ...para,
      });
    }
  },
  addTable: (state, { payload }) => {
    let ele;
    if (payload.layoutEle) {
      ele = returnHFEle(state, payload.path, payload.layoutEle);
    } else {
      ele = returnEle(state, payload.path);
    }
    let Rows = [];
    for (let i = 0; i < payload.rows; i++) {
      let Cols = [];
      for (let j = 0; j < payload.cols; j++) {
        let text = structuredClone(TEXT);
        let para = structuredClone(PARA);
        let cell = structuredClone(CELL);
        para.name = cell.ele.length;
        para.ele.push(text);
        cell.ele.push(para);
        Cols.push({
          ...cell,
          name: j,
        });
      }
      const row = structuredClone(ROW);
      Rows.push({
        ...row,
        ele: Cols,
        name: i,
      });
    }
    const table = structuredClone(TABLE);
    if (payload.loc) {
      if (payload.loc === "after") {
        ele.ele.splice(payload.index + 1, 0, {
          ...table,
          ele: Rows,
          name: payload.name ? payload.name : ele.ele.length,
        });
      } else {
        ele.ele.splice(payload.index, 0, {
          ...table,
          ele: Rows,
          name: payload.name ? payload.name : ele.ele.length,
        });
      }
    } else {
      ele.ele.push({
        ...table,
        ele: Rows,
        name: payload.name ? payload.name : ele.ele.length,
      });
    }

    if (payload.path.length === 2) {
      let text = structuredClone(TEXT);
      text.t = "";
      let para = structuredClone(PARA);
      para.ele.push(text);
      para.name = "gap";
      if (payload.loc) {
        if (payload.loc === "after") {
          ele.ele.splice(payload.index + 1, 0, { ...para });
        } else {
          ele.ele.splice(payload.index + 1, 0, { ...para });
        }
      } else {
        ele.ele.push({
          ...para,
        });
      }
    }
  },
  setSecOrientation: (state, { payload }) => {
    const section = returnEle(state, payload.section);
    section.properties.page.size.orientation = payload.value;
  },
  setSecBorderStyle: (state, { payload }) => {
    const section = returnEle(state, payload.section);
    if (section.properties.page.borders) {
      section.properties.page.borders[payload.loc] = {
        ...section.properties.page.borders[payload.loc],
        style: payload.value,
      };
    } else {
      section.properties.page.borders = {
        [payload.loc]: {
          style: payload.value,
        },
      };
    }
  },
  setSecBorderColor: (state, { payload }) => {
    const section = returnEle(state, payload.section);
    if (section.properties.page.borders) {
      section.properties.page.borders[payload.loc] = {
        ...section.properties.page.borders[payload.loc],
        color: payload.value.replace("#", "").toUpperCase(),
      };
    } else {
      section.properties.page.borders = {
        [payload.loc]: {
          color: payload.value.replace("#", "").toUpperCase(),
        },
      };
    }
  },

  setSecBorderSize: (state, { payload }) => {
    const section = returnEle(state, payload.section);
    if (section.properties.page.borders) {
      section.properties.page.borders[payload.loc] = {
        ...section.properties.page.borders[payload.loc],
        size: parseFloat(payload.value),
      };
    } else {
      section.properties.page.borders = {
        [payload.loc]: {
          size: parseFloat(payload.value),
        },
      };
    }
  },

  setSecBorderSpace: (state, { payload }) => {
    const section = returnEle(state, payload.section);
    if (section.properties.page.borders) {
      section.properties.page.borders[payload.loc] = {
        ...section.properties.page.borders[payload.loc],
        space: parseFloat(payload.value),
      };
    } else {
      section.properties.page.borders = {
        [payload.loc]: {
          space: parseFloat(payload.value),
        },
      };
    }
  },

  setSecMargin: (state, { payload }) => {
    const section = returnEle(state, payload.section);
    section.properties.page.margin[payload.loc] = payload.value;
  },
  setSecType: (state, { payload }) => {
    const section = returnEle(state, payload.section);
    section.properties.type = payload.value;
  },
  setSecHeaderType: (state, { payload }) => {
    const section = returnEle(state, payload.section);
    section.headers = {
      type: payload.type,
      ele: [],
    };
  },
  addHeaderPara: (state, { payload }) => {
    const section = returnEle(state, payload.section);
    let text = structuredClone(TEXT);
    text.t = "R";
    let para = structuredClone(PARA);
    para.ele.push(text);
    para.name = payload.name ? payload.name : section.headers.ele.length;
    section.headers.ele.push({ ...para });
  },
  addHeaderTable: (state, { payload }) => {
    const section = returnEle(state, payload.section);
    let Rows = [];
    for (let i = 0; i < payload.rows; i++) {
      let Cols = [];
      for (let j = 0; j < payload.cols; j++) {
        let cell = structuredClone(CELL);
        Cols.push({
          ...cell,
          name: j,
        });
      }
      const row = structuredClone(ROW);
      Rows.push({
        ...row,
        ele: Cols,
        name: i,
      });
    }
    const table = structuredClone(TABLE);
    if (payload.name) {
      table.user = {};
      table.manager = {};
    }
    section.headers.ele.push({
      ...table,
      ele: Rows,
      name: payload.name ? payload.name : section.headers.ele.length,
    });
  },
  setSecFooterType: (state, { payload }) => {
    const section = returnEle(state, payload.section);
    section.footers = {
      type: payload.type,
      ele: [],
    };
  },
  addFooterPara: (state, { payload }) => {
    const section = returnEle(state, payload.section);
    let text = structuredClone(TEXT);
    text.t = "R";
    let para = structuredClone(PARA);
    para.ele.push(text);
    para.name = payload.name ? payload.name : section.footers.ele.length;
    section.footers.ele.push({ ...para });
  },
  addFooterTable: (state, { payload }) => {
    const section = returnEle(state, payload.section);
    let Rows = [];
    for (let i = 0; i < payload.rows; i++) {
      let Cols = [];
      for (let j = 0; j < payload.cols; j++) {
        let cell = structuredClone(CELL);
        Cols.push({
          ...cell,
          name: j,
        });
      }
      const row = structuredClone(ROW);
      Rows.push({
        ...row,
        ele: Cols,
        name: i,
      });
    }
    const table = structuredClone(TABLE);
    if (payload.name) {
      table.user = {};
      table.manager = {};
    }
    section.footers.ele.push({
      ...table,
      ele: Rows,
      name: payload.name ? payload.name : section.footers.ele.length,
    });
  },
};

export default sectionProps;
