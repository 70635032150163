import React from "react";
import TextArea from "../../../../utils/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { updateFEF } from "../../../../slices/entryForm";
import { updateFFEF } from "../../../../slices/formsSlice";

const CFTextarea = ({ ele, operator }) => {
  const dispatch = useDispatch();
  const { dev, category, name } = useSelector((state) => state.docs);
  const { filledEF } = useSelector((state) => state.entryForm);
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <div>
      <h2 className="pb-1">{ele.placeholder}</h2>
      <TextArea
        className="h-[100px]"
        placeholder={ele.placeholder}
        value={filledEF[operator][ele.placeholder]}
        change={(e) => {
          dispatch(
            updateFEF({
              operator: operator,
              name: ele.placeholder,
              value: e.target.value,
            })
          );

          dispatch(
            updateFFEF({
              EF: `${category}$${name}_EF`,
              name: ele.placeholder,
              value: e.target.value,
              operator,
            })
          );
        }}
        click={() => {}}
      />
    </div>
  );
};

export default CFTextarea;
