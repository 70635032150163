import React from "react";
import Button from "../../../../../../utils/Button";
import { addPageBreak } from "../../../../../../slices/docsSlice";
import { useDispatch } from "react-redux";

const PageBreak = ({ pageBreak, RRIP_P, SIPHF }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <h2 className="pb-1">Page Break</h2>
      <Button
        name="Page Break"
        value={pageBreak}
        click={() => {
          dispatch(
            addPageBreak({
              para: RRIP_P,
              value: pageBreak,
              layoutEle: SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
            })
          );
        }}
      />
    </div>
  );
};

export default PageBreak;
