import React, { useState } from "react";
import Input from "../../../utils/Input";

const Remarks = ({ item, i, j, obj }) => {
  const [remark, setRemark] = useState(item);
  return (
    <Input
      type="text"
      value={remark}
      click={() => {
        let val = `Cibil[${i}].${obj}[${j}]`;
        navigator.clipboard.writeText(val);
      }}
      change={(e) => setRemark(e.target.value)}
    />
  );
};

export default Remarks;
