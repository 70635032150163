import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import Input from "../../../../utils/Input";
import Dropdown from "../../../../utils/Dropdown";
import { useDispatch } from "react-redux";
import {
  removeUserInput,
  updateDefaultValue,
} from "../../../../slices/entryForm";

const EFDropDown = ({ opInput, operator }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-wrap items-center gap-4 bg-yellow-200 p-2 rounded relative">
      <div
        className={`flex absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
        onClick={() =>
          dispatch(
            removeUserInput({
              name: opInput.placeholder,
              operator: operator,
            })
          )
        }
      >
        <IoCloseSharp />
      </div>
      <div>
        <h2 className="pb-1">
          {opInput.placeholder} ({opInput.element})
        </h2>
        <Input
          type="text"
          placeholder="default value"
          value={opInput.defaultValue}
          disabled
        />
      </div>
      <div>
        <h2 className="pb-1">Change Default Value</h2>
        <Dropdown
          name="Default Value"
          width={200}
          height={40}
          value={opInput.defaultValue}
          options={opInput.options}
          target="option"
          click={(value) => {
            dispatch(
              updateDefaultValue({
                name: opInput.placeholder,
                operator: operator,
                value: value,
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export default EFDropDown;
