import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetEntryFormsQuery,
  useDeleteEntryFormMutation,
} from "../../slices/entryFormApiSlice";
import { useGetRecordMutation } from "../../slices/documentsApiSlice";
import { toast } from "react-toastify";
import { getForms, setSingleUserForm } from "../../slices/formsSlice";
import { setDocument } from "../../slices/documentSlice";
import {
  setDocs,
  setCategory,
  setIndexPath,
  setDocId,
  setRunStatus,
  setName,
} from "../../slices/docsSlice";
import { setEntryForm, setFilledEF } from "../../slices/entryForm";
import {
  getIntenalDependency,
  getListIDS,
  setInternalDependency,
} from "../../slices/internalDependencySlice";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import Table from "../../utils/Table/Table";
import TableRow from "../../utils/Table/TableRow";
import TableCell from "../../utils/Table/TableCell";
import Dropdown from "../../utils/Dropdown";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const { document } = useSelector((state) => state.document);
  const { forms, userForms } = useSelector((state) => state.forms);
  const { docs, name, category, indexPath } = useSelector(
    (state) => state.docs
  );
  const { listIDS, internalDependency } = useSelector(
    (state) => state.internalDependency
  );

  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);
  const [record, setRecord] = useState();

  const [getRecord, {}] = useGetRecordMutation();
  const [deleteEntryForm, {}] = useDeleteEntryFormMutation();
  const { data: entryForms, isLoading, refetch } = useGetEntryFormsQuery();

  const getRecordsHandler = async (form) => {
    try {
      const res = await getRecord({ record: form }).unwrap();
      console.log(res);
      dispatch(
        getForms({
          userForms: res.userForms,
          forms: res.forms,
        })
      );
      dispatch(setDocument(res.forms[`${res.category}$${res.docName}`]));
      dispatch(setDocId(res.forms[`${res.category}$${res.docName}`]._id));
      dispatch(setDocs(res.forms[`${res.category}$${res.docName}`].document));
      dispatch(
        setEntryForm(res.forms[`${res.category}$${res.docName}`].entryForm)
      );
      dispatch(setFilledEF(res.forms[`${res.category}$${res.docName}_EF`]));
      dispatch(setName(res.docName));
      dispatch(setCategory(res.category));
      dispatch(
        setRunStatus(res.forms[`${res.category}$${res.docName}`].runStatus)
      );
      dispatch(
        setIndexPath(res.forms[`${res.category}$${res.docName}`].indexPath)
      );
      dispatch(getListIDS(res.listIDS));
      dispatch(getIntenalDependency(res.internalDependency));
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const deleteEntryFormHandler = async (entryFormId) => {
    try {
      const res = await deleteEntryForm(entryFormId).unwrap();
      toast.success(res.message);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (
      document &&
      forms &&
      userForms &&
      docs &&
      indexPath &&
      name &&
      category
    ) {
      if (indexPath === "") {
        navigate(`/manager/${category}$${name}/DC`);
      } else {
        let RRIP = indexPath.split("/");
        let lastIndex = RRIP[RRIP.length - 1];
        let SLI = lastIndex.split("_");
        let NIP = RRIP.map((index) => index.split("_")[0]).join("_");
        let splitNIP = NIP.split("_");
        let lastNIP = splitNIP[splitNIP.length - 1];
        let path = `/manager/${category}$${name}/${indexPath}`;
        switch (SLI[1]) {
          case "I":
            if (RRIP.length === 4) {
              navigate(`${path}/SP/PP/IP`);
            } else {
              navigate(`${path}/SP/TBP/TR/TC/PP/IP`);
            }
            break;
          case "CB":
            if (RRIP.length === 4) {
              navigate(`${path}/SP/PP/CBP/${lastNIP}#${NIP}`);
            } else {
              navigate(`${path}/SP/TBP/TR/TC/PP/CBP/${lastNIP}#${NIP}`);
            }
            break;
          case "SY":
            if (RRIP.length === 4) {
              navigate(`${path}/SP/PP/SYP/${lastNIP}#${NIP}`);
            } else {
              navigate(`${path}/SP/TBP/TR/TC/PP/SYP/${lastNIP}#${NIP}`);
            }
            break;
          case "T":
            if (RRIP.length === 4) {
              navigate(`${path}/SP/PP/TP/${lastNIP}#${NIP}`);
            } else {
              navigate(`${path}/SP/TBP/TR/TC/PP/TP/${lastNIP}#${NIP}`);
            }
            break;
          case "P":
            if (RRIP.length === 3) {
              navigate(`${path}/SP/PP`);
            } else {
              navigate(`${path}/SP/TBP/TR/TC/PP`);
            }
            break;
          case "TC":
            navigate(`${path}/SP/TBP/TR/TC`);
            break;
          case "TR":
            navigate(`${path}/SP/TBP/TR`);
            break;
          case "TB":
            navigate(`${path}/SP/TBP`);
            break;
          case "S":
            navigate(`${path}/SP`);
            break;
          case "Doc":
            navigate(`${path}/DP`);
            break;
        }
      }
    }
  }, [document, forms, userForms, docs, indexPath, category, name]);

  useEffect(() => {
    refetch();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <div className="psm">
      <h2 className="font-mono pb-2">Records</h2>
      {isLoading ? (
        <Loader />
      ) : (
        <Table style={{ paddingBottom: "200px" }}>
          <TableRow isHeader={true}>
            <TableCell isHeader={true}>S.No</TableCell>
            <TableCell isHeader={true}>Name</TableCell>
            <TableCell isHeader={true}>Category</TableCell>
            <TableCell isHeader={true}>User</TableCell>
            <TableCell isHeader={true}>Email</TableCell>
            <TableCell isHeader={true}>Status</TableCell>
            <TableCell isHeader={true}>IDS</TableCell>
            <TableCell isHeader={true} style={{ width: "200px" }}>
              Select
            </TableCell>
            <TableCell isHeader={true}>Date</TableCell>
            <TableCell isHeader={true}>X</TableCell>
          </TableRow>
          {entryForms?.map((form, sno) => {
            return (
              <TableRow
                key={sno}
                className={(sno + 1) % 2 === 0 ? "bg-gray-100" : null}
              >
                <TableCell>{sno + 1}</TableCell>
                <TableCell>{form.docName}</TableCell>
                <TableCell>{form.category}</TableCell>
                <TableCell>{form.name}</TableCell>
                <TableCell>{form.email}</TableCell>
                <TableCell style={{ padding: "0px" }}>
                  <Dropdown
                    options={[
                      "Work under process",
                      "Payment pending",
                      "Completed",
                      "Terminated",
                    ]}
                    target="option"
                    value={form.status}
                    click={(value) => {}}
                  />
                </TableCell>
                <TableCell style={{ padding: "0px" }}>
                  <div className="flex gap-2">
                    {form?.listIDS?.map((ID, i) => (
                      <p key={i}>
                        {ID.name}
                        {i !== form.listIDS.length - 1 ? "," : ""}
                      </p>
                    ))}
                  </div>
                </TableCell>
                <TableCell
                  style={{
                    padding: "0px",
                    cursor: "pointer",
                    backgroundColor: "#083344",
                    color: "#f8f9fa",
                    textAlign: "center",
                  }}
                  click={() => {
                    getRecordsHandler(form);
                  }}
                >
                  Select
                </TableCell>
                <TableCell>{formatDate(form.createdAt)}</TableCell>
                <TableCell
                  className={`cursor-pointer bg-danger-500 font-bold text-light-500 text-center`}
                  click={() => deleteEntryFormHandler(form._id)}
                >
                  X
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      )}
    </div>
  );
};

export default Dashboard;
