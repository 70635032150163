import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  benchmark: {},
};

const benchmarkSlice = createSlice({
  name: "benchmark",
  initialState,
  reducers: {
    setBenchmark: (state, { payload }) => {
      state.benchmark = payload;
    },
    addInput: (state, { payload }) => {
      state.benchmark[payload] = "";
    },
    removeInput: (state, { payload }) => {
      const benchmark = state.benchmark;
      delete benchmark[payload];
      state.benchmark = benchmark;
    },
    updateInput: (state, { payload }) => {
      state.benchmark[payload.loc] = payload.value;
    },
  },
});

export default benchmarkSlice.reducer;
export const { setBenchmark, addInput, removeInput, updateInput } =
  benchmarkSlice.actions;
